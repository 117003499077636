import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AngleMode } from '@models/ncx/angle';

@Component({
  selector: 'app-linked-angles-view',
  templateUrl: './linked-angles-view.component.html',
  styleUrls: ['./linked-angles-view.component.scss']
})
export class LinkedAnglesViewComponent implements OnChanges {

  @Input() relatedAngles: any = [];

  hasPrivateAngle: boolean = false;

  constructor() { }

  redirectTo(angleId) {

    //const reportUrl = this.reRoute.createUrlTree(['ncx/landing-angle/:' + angleId, {data:"Data to pass"}]).toString();
    const url = window.location.origin + '/ncx/landing-angle/:' + angleId;

    window.open(url, '_self');

  }

  ngOnChanges(changes: SimpleChanges) {

    if ('relatedAngles' in changes) {

      this.hasPrivateAngle = false;
      this.relatedAngles?.forEach((angle) => {

        if (angle.angleAccess === AngleMode.Private) {

          this.hasPrivateAngle = true;
        
        }
      
      });
    
    }
  
  }

}
