<div
   class="nav-header"
   [attr.data-component]="'app/components/sidenavigation'">
   <a href="/">
      <img
         src="/assets/icons/ncx-logo.svg"
         alt="NBC NewsConnect" />
      <h1 [ngClass]="{ collapsed: isCollapsed }">NewsConnect</h1>
   </a>
</div>

<div class="nav-menu">
   <!-- <perfect-scrollbar [config]=config> -->
   <ul
      nz-menu
      [nzInlineCollapsed]="isCollapsed"
      nzTheme="dark"
      nzMode="inline">
      <ng-container *ngFor="let menu of menuList; let i = index">
         <li
            nz-menu-item
            routerLink="{{ menu.route }}"
            (click)="closeDrawer()"
            routerLinkActive="ant-menu-item-selected"
            *ngIf="!menu.hasOwnProperty('subMenu')">
            <i
               nz-icon
               nzType="{{ menu.iconName }}"></i>
            <span>{{ menu.displayName }}</span>
         </li>
         <li
            [nzOpen]="menu.displayName === 'Activity'"
            nz-submenu
            nzTitle="{{ menu.displayName }}"
            nzIcon="{{ menu.iconName }}"
            *ngIf="menu.hasOwnProperty('subMenu')">
            <ul class="menu-item-override">
               <ng-container *ngFor="let subMenu of menu.subMenu; let i = index">
                  <li
                     nz-menu-item
                     *ngIf="
                        !subMenu.hasOwnProperty('innerSubMenu') &&
                        (i !== 1 || subMenu.displayName !== 'Need Help?') &&
                        subMenu.route !== '' &&
                        subMenu.displayName !== 'Admin' &&
                        subMenu.displayName !== 'News Group Workflows & Tools Training Guide' &&
                        subMenu.displayName !== 'NewsConnect Guide' &&
                        subMenu.displayName !== 'Training Signup' &&
                        subMenu.displayName !== 'DEI Words Guide'
                     "
                     routerLink="{{ subMenu.route }}"
                     routerLinkActive="ant-menu-item-selected"
                     (click)="closeDrawer()">
                     {{ subMenu.displayName }}
                  </li>
                  <li
                     nz-menu-item
                     *ngIf="
                        !subMenu.hasOwnProperty('innerSubMenu') &&
                        subMenu.displayName === 'News Group Workflows & Tools Training Guide'
                     "
                     routerLinkActive="ant-menu-item-selected">
                     <a
                        href="{{ subMenu.route }}"
                        target="_blank">
                        <span>
                           News Group Workflows
                           <br />
                           & Tools Training Guide
                        </span>
                     </a>
                  </li>
                  <li
                     nz-menu-item
                     *ngIf="!subMenu.hasOwnProperty('innerSubMenu') && subMenu.displayName === 'NewsConnect Guide'"
                     routerLinkActive="ant-menu-item-selected">
                     <a
                        href="{{ subMenu.route }}"
                        target="_blank">
                        <span>NewsConnect Guide</span>
                     </a>
                  </li>
                  <li
                     nz-menu-item
                     *ngIf="!subMenu.hasOwnProperty('innerSubMenu') && subMenu.displayName === 'Training Signup'"
                     routerLinkActive="ant-menu-item-selected">
                     <a
                        href="{{ subMenu.route }}"
                        target="_blank">
                        <span>Training Signup</span>
                     </a>
                  </li>
                  <li
                     nz-menu-item
                     *ngIf="!subMenu.hasOwnProperty('innerSubMenu') && subMenu.displayName === 'DEI Words Guide'"
                     routerLinkActive="ant-menu-item-selected">
                     <a
                        href="{{ subMenu.route }}"
                        target="_blank">
                        <span>DEI Words Guide</span>
                     </a>
                  </li>
                  <a
                     *ngIf="i === 1 && subMenu.displayName === 'Need Help?'"
                     routerLinkActive="ant-menu-item-selected"
                     nz-menu-item
                     href="{{ emailTo }}"
                     target="_blank">
                     {{ subMenu.displayName }}
                  </a>
                  <li
                     nz-menu-item
                     *ngIf="subMenu.displayName === 'Admin' && roleCheck"
                     routerLink="{{ subMenu.route }}"
                     routerLinkActive="ant-menu-item-selected">
                     {{ subMenu.displayName }}
                  </li>
               </ng-container>
            </ul>
         </li>
      </ng-container>
      <li
         nz-menu-item
         *ngIf="isLocal"
         routerLink="ncx-dev"
         routerLinkActive="ant-menu-item-selected">
         <i
            nz-icon
            nzType="codepen"
            nzTheme="outline"></i>
         <span>Developers</span>
      </li>
   </ul>
   <!-- </perfect-scrollbar> -->
</div>
