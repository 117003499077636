<div
   class="story-status with-text"
   [ngStyle]="style"
   *ngIf="showStatusText">
   <!-- only show dot if there is a status -->
   <span
      class="dot"
      [ngClass]="{
         dot: !!status,
         submitted: status === 'SUBMITTED',
         working: status === 'WORKING',
         ready: status === 'READY',
      }"></span>

   {{ status }}
</div>

<span
   class="story-status"
   [ngStyle]="style"
   *ngIf="!showStatusText">
   <!-- only show dot if there is a status -->
   <span
      class="dot"
      [ngClass]="{
         dot: !!status,
         submitted: status === 'SUBMITTED',
         working: status === 'WORKING',
         ready: status === 'READY',
      }"></span>
</span>
