<div
   class="global-search-bar"
   data-id="global-search">
   <input
      type="text"
      nz-input
      data-id="global-search-input"
      #searchInputField
      [(ngModel)]="searchInputValue"
      (ngModelChange)="onModelChange($event)"
      (keyup.enter)="viewSearchPage()"
      placeholder="Search all of NewsConnect" />

   <button
      nz-button
      nzType="primary"
      nzSize="small"
      nzGhost="true"
      nzShape="circle"
      class="icon"
      (click)="staticSearch ? closeSearch() : performSearch()">
      <span
         nz-icon
         nzTheme="outline"
         [nzType]="staticSearch ? 'close' : 'search'"></span>
   </button>

   <!-- SEARCH RESULTS -->

   <div
      [ngClass]="{ 'search-results-component': true, 'narrow': !isNavClosed }"
      data-id="global-search-results"
      *ngIf="showSearchResults"
      #scrollElement>
      <app-scroll-container [maxHeight]="scrollHeight">
         <ng-template bodyTemplate>
            <section class="scrollable">
               <div class="column">
                  <h3>Posts</h3>
                  <div class="list">
                     <nz-skeleton
                        *ngIf="!isLoaded"
                        [nzActive]="true"></nz-skeleton>
                     <p *ngIf="isLoaded && !searchResult?.contentSearchResult?.length">No Results</p>

                     <a
                        class="with-icon"
                        *ngFor="let post of searchResult.contentSearchResult"
                        [href]="post.targetURL"
                        (click)="viewPost(post)">
                        <i
                           nz-icon
                           nzType="file-text"
                           nzTheme="outline"></i>
                        <span
                           class="separate-line"
                           [innerHtml]="post.snippet?.title?.trim() || post.title | truncate: 15 | safeHtml"></span>
                        <span
                           *ngIf="post.createUser?.displayName"
                           [ngClass]="{ 'separate-line': true, 'createdBy': true }">
                           by {{ formatName(post.createUser) }}
                        </span>
                     </a>
                  </div>
               </div>

               <div class="column split-line">
                  <h3>Stories/Groups</h3>
                  <div class="list">
                     <nz-skeleton
                        *ngIf="!isLoaded"
                        [nzActive]="true"></nz-skeleton>
                     <p
                        *ngIf="
                           isLoaded &&
                           !searchResult?.groupSearchResult?.length &&
                           !searchResult?.storySearchResult?.length
                        ">
                        No Results
                     </p>
                     <a
                        *ngFor="let story of searchResult?.storySearchResult"
                        [href]="story.storyUrl"
                        (click)="viewStory(story)">
                        <span
                           [innerHTML]="
                              story.snippet?.title?.trim() || story.storyTitle | truncate: 15 | safeHtml
                           "></span>
                     </a>
                     <a
                        class="item"
                        *ngFor="let group of searchResult?.groupSearchResult"
                        [href]="group.storyUrl"
                        (click)="viewGroup(group)">
                        <span
                           [innerHTML]="
                              group.snippet?.title?.trim() || group.storyTitle | truncate: 15 | safeHtml
                           "></span>
                     </a>
                  </div>
               </div>

               <div class="column split-line">
                  <h3>
                     Angles
                     <app-beta
                        [search]="true"
                        [showBInMobile]="true"></app-beta>
                  </h3>
                  <div class="list">
                     <nz-skeleton
                        *ngIf="!isLoaded"
                        [nzActive]="true"></nz-skeleton>
                     <p *ngIf="isLoaded && !searchResult?.angleSearchResult?.length">No Results</p>
                     <a
                        *ngFor="let angle of searchResult.angleSearchResult"
                        [href]="angle.angleURL"
                        (click)="viewAngle(angle)">
                        <div class="angle-icon-title-flex">
                           <div class="angle-icon-title-split">
                              <span
                                 class="eye-invisible-icon"
                                 *ngIf="angle.angleAccess === 'private'">
                                 <span
                                    nz-icon
                                    nzType="eye-invisible"
                                    nzTheme="outline"></span>
                              </span>
                              <span
                                 class="eye-invisible-hidden-icon"
                                 *ngIf="angle.angleAccess !== 'private' && hasPrivateAngle">
                                 <span
                                    nz-icon
                                    nzType="eye-invisible"
                                    nzTheme="outline"></span>
                              </span>
                           </div>
                           <div class="angle-icon-title-split">
                              <span
                                 [innerHtml]="
                                    angle.angleTitleSnippet || angle.angleTitle | truncate: 15 | safeHtml
                                 "></span>
                           </div>
                        </div>

                        <div class="angle-icon-title-flex">
                           <div class="angle-icon-title-split">
                              <span
                                 class="eye-invisible-hidden-icon"
                                 *ngIf="hasPrivateAngle">
                                 <span
                                    nz-icon
                                    nzType="eye-invisible"
                                    nzTheme="outline"></span>
                              </span>
                           </div>
                           <div class="angle-icon-title-split">
                              <span
                                 *ngIf="angle.createdUser?.displayName"
                                 [ngClass]="{ createdBy: true }">
                                 by {{ formatName(angle.createdUser) }}
                              </span>
                           </div>
                        </div>
                     </a>
                  </div>
               </div>

               <div class="column split-line">
                  <h3>People</h3>
                  <div class="list">
                     <nz-skeleton
                        *ngIf="!isLoaded"
                        [nzActive]="true"></nz-skeleton>
                     <p *ngIf="isLoaded && !searchResult?.profileResult?.length">No Results</p>
                     <button
                        *ngFor="let profile of searchResult?.profileResult"
                        (click)="viewProfile(profile)">
                        <nz-avatar
                           *ngIf="!profile.thumbnailUrl"
                           nzIcon="user"></nz-avatar>
                        <nz-avatar
                           *ngIf="profile.thumbnailUrl"
                           [nzSrc]="avatarUrl(profile.thumbnailUrl)"></nz-avatar>

                        <span [innerHTML]="profile.snippet?.title?.trim() || formatName(profile) | safeHtml"></span>
                     </button>
                  </div>
               </div>
            </section>

            <section
               class="footer"
               *ngIf="hasResults">
               <button
                  nz-button
                  class="results-btn"
                  (click)="viewAllResults()"
                  nzSize="small"
                  nzType="primary">
                  See All Results
               </button>
            </section>
         </ng-template>
      </app-scroll-container>
   </div>
</div>
