import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisabledUserComponent } from '@ncx/disabled-user/disabled-user.component';
import { HomeComponent } from '@ncx/home/home.component';
import { InvalidUserComponent } from '@ncx/invalid-user/invalid-user.component';
import { PageNotFoundComponent } from '@ncx/page-not-found/page-not-found.component';
import { AuthGuardService } from '@services/auth/app.auth.guard';
import { AuthManager } from '@services/auth/app.auth.manager';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./ncx/ncx.module').then(m => m.NcxModule)
  },
  {
    path: 'ncx',
    loadChildren: () => import('./ncx/ncx.module').then(m => m.NcxModule)
  },
  {
    path: 'ncx-admin',
    loadChildren: () => import('./ncx-admin/ncx-admin.module').then(m => m.NcxAdminModule)
  },
  {
    path: 'ncx-dev',
    loadChildren: () => import('./ncx-dev/ncx-dev.module').then(m => m.NcxDevModule)
  },
  {
    path: 'access_token',
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
        resolve: { AuthManager }
      }
    ]
  },
  { path: 'assets', component: HomeComponent, resolve: { AuthManager } },
  { path: 'error', component: InvalidUserComponent, canActivate: [AuthGuardService] },
  { path: 'disabled-user', component: DisabledUserComponent, canActivate: [AuthGuardService] },
  { path: '**', component: PageNotFoundComponent, resolve: { AuthManager } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {}
