import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-limited-license-new',
  templateUrl: './limited-license-new.component.html',
  styleUrls: ['./limited-license-new.component.scss']
})
export class LimitedLicenseNewComponent implements OnInit {

  initObj = { additionalNotes: '' };

  @Input() limitLicenseContent;

  subject: Subject<string> = new Subject();

  isValid: boolean;

  @Output() limitedLicenseDataUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() postEdited: EventEmitter<any> = new EventEmitter<any>();

  constructor() {

    this.subject.pipe(
      debounceTime(700)
    ).subscribe(() => {

      this.limitedLicenseDataUpdated.emit(this.limitLicenseContent);

    });

  }

  ngOnInit() {

    if (this.limitLicenseContent === undefined || this.limitLicenseContent === null) {

      this.limitLicenseContent = this.initObj;

    }

  }

  onChange(value, type) {
    this.postEdited.emit({});

    if (type === 'additionalNotes' && value !== '') {
      this.isValid = true;

    } else if (type === 'additionalNotes' && value === '') {

      this.isValid = false;

    }
    setTimeout(() => {

      this.subject.next(value);

    }, 100);

  }

}
