<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout [attr.data-component]="'ncx/feeds/feeds.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <nz-page-header-title>
         <button
            *ngIf="isMobile"
            nz-button
            (click)="toggleSider()">
            <i
               nz-icon
               nzType="filter"
               nzTheme="outline"></i>
         </button>
         <nz-button-group *ngIf="!isMobile">
            <button
               class="headerButtons"
               nz-button>
               Story State
            </button>
            <button
               nz-button
               nz-dropdown
               [nzDropdownMenu]="menu1">
               {{ feedTypeName }}
               <i
                  nz-icon
                  nzType="down"></i>
            </button>
            <nz-dropdown-menu #menu1="nzDropdownMenu">
               <ul nz-menu>
                  <li
                     *ngFor="let index of feedType"
                     (click)="changeFeedType(index)"
                     nz-menu-item>
                     {{ index.label }}
                  </li>
               </ul>
            </nz-dropdown-menu>
         </nz-button-group>
         <nz-button-group *ngIf="!isMobile">
            <button
               class="headerButtons"
               nz-button>
               <i
                  nz-icon
                  nzType="sort-ascending"
                  nzTheme="outline"></i>
               <!-- <i nz-icon nzType="sort-descending" nzTheme="outline"></i> -->
               Order
            </button>
            <button
               nz-button
               nz-dropdown
               [nzDropdownMenu]="menu">
               {{ orderedBy }}
               <i
                  nz-icon
                  nzType="down"></i>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
               <ul nz-menu>
                  <li
                     *ngFor="let index of feedOrder"
                     nz-menu-item
                     (click)="changeFeedOrder(index)">
                     {{ index.label }}
                  </li>
               </ul>
            </nz-dropdown-menu>
         </nz-button-group>
      </nz-page-header-title>
   </nz-page-header>
   <nz-layout>
      <div
         class="feedLayout"
         infiniteScroll
         [infiniteScrollDistance]="2"
         (scrolled)="onScrollDown()"
         [scrollWindow]="false"
         [ngStyle]="checkScreen()">
         <!-- NO RESULTS -->
         <div
            class="notFoundCard"
            *ngIf="isLoaded && !feedList.length">
            <nz-empty [nzNotFoundContent]="contentTpl">
               <ng-template #contentTpl>
                  <span>No Results Found</span>
               </ng-template>
            </nz-empty>
         </div>

         <!-- LOOP OVER LIST -->
         <div
            class="feedSection"
            *ngFor="let feed of feedList">
            <app-story-status [status]="feed.storyState"></app-story-status>

            <div class="storyTitle">
               <a href="/ncx/landing-story/:{{ feed.storyId }}">
                  {{ feed.storyTitle }}
               </a>
            </div>

            <nz-divider></nz-divider>

            <div class="postTitle">
               <a href="/ncx/view-post/:{{ feed.storyPosts[0]?.postId }}">
                  {{ feed.storyPosts[0]?.postTitle }}
               </a>
            </div>

            <div class="footerSection">
               <app-follow-story
                  *ngIf="feed?.storyId"
                  type="text"
                  [storyId]="feed.storyId"
                  [isFollowing]="feed.isUserFollowing"
                  [followers]="feed.storyFollowerCount"
                  [showFollowCount]="true"
                  [showFollowStatus]="false"
                  (updatedFollower)="updateFollower($event)"></app-follow-story>
               <span>
                  <i
                     nz-icon
                     nzType="wechat"
                     nzTheme="outline"></i>
                  <span>
                     {{
                        feed && feed.storyPosts[0] && feed.storyPosts[0]?.repliesCount
                           ? feed.storyPosts[0].repliesCount
                           : 0
                     }}
                  </span>
               </span>
            </div>
         </div>
      </div>

      <nz-drawer
         *ngIf="isMobile"
         [nzClosable]="false"
         [nzVisible]="!isCollapsed"
         [nzMaskClosable]="true"
         [nzWrapClassName]="'no-padding'"
         nzPlacement="right"
         [nzWidth]="320"
         (nzOnClose)="closeSlider()">
         <ng-container *nzDrawerContent>
            <app-feeds-filter
               [isDrawer]="true"
               [feedType]="feedType"
               [orderedBy]="feedOrder"
               (closeFilter)="closeSlider()"
               (emitFeedTypeFilter)="changeFeedType($event)"
               (emitOrderFilter)="changeFeedOrder($event)"
               (clearFilter)="clearFilter($event)"></app-feeds-filter>
         </ng-container>
      </nz-drawer>
   </nz-layout>
</nz-layout>
