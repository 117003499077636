import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { Announcement } from '@models/ncx/announcement';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { WebSocketService } from '@services/websocket.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { SubSink } from 'subsink';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit, OnDestroy {

  banners = {
    hot: {
      show: false
    },
    announcement: {
      show: false,
      details: false
    }
  };

  isLoaded: boolean = true;

  isCollapsed: boolean = true;

  isAdmin: boolean = false;

  //To verify the alert every minute and remove it from view if it expires.
  private intervalToVerifyAndRemoveAlerts = 60000;

  //To clear the interval
  intervalId: any;

  // All announcements
  announcementList: Announcement[] = [];

  // All Hot Notes
  hotList = [];

  // Active announcement
  announcementDetails: Announcement = {} as Announcement;

  // AntDesign Carousel containers
  @ViewChild('carouselHot') carouselHot: NzCarouselComponent;

  @ViewChild('carouselAnnouncement') carouselAnnouncement: NzCarouselComponent;

  private subs = new SubSink();

  constructor(
    private cS: CommonService,
    private tS: ToastService,
    private route: Router,
    private tI: TransferInformationComponent,
    private wS: WebSocketService,
    private urlRedirectService: UrlRedirectService
  ) { }

  ngOnInit() {

    this.initBanners();

    // When new banner updates come in from web socket
    this.subs.sink = this.wS.doBannerSocketData$.subscribe(data => {

      this.updateBanner(data);

    });

    // See the navigationService for the window.dispatchEvent(new Event('resize')) event
    // that causes the banner to 'repaint' when the navigation drawer opens and closes

    this.isAdmin = this.tI.userRole && !!this.tI.userRole.match(/admin/i);

    this.isCollapsed = this.cS.isNavDrawerClosed.value;

    // Listen for banner updates from the WebSocket service
    //this.wS.connect('GENERIC');
    this.wS.connect('STORY');

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.handleSleepMode();
      } else if (document.visibilityState === 'visible') {
        this.handleWakeMode();
      }
    });

  }

  handleSleepMode() {
    console.log(`System is going to sleep or tab is hidden on `, { Date: this.getCurrentTimeDetails() });
  }

  handleWakeMode() {
    console.log(`System is awake or tab is visible again on `, { Date: this.getCurrentTimeDetails() });

    //calling this event to reactivate the interval incase it was deactivated in sleep mode and not currently active
    this.initializeInterval();

    //calling this event to verify the remaining time incase the setInterval was dealyed in sleep mode and not called on consistent interval. Known issue of setIntervals in browsers which are inactive.
    this.removeBanner();
  }

  /**
   * Initialize banners
   *
   */
  initBanners() {

    const url = environment.bannerUrl;

    const queryStr = '?isActive=true&includeFuture=false';

    this.cS.serviceRequestCommon('get', url, queryStr).subscribe((res: any) => {

      this.announcementList = res.filter(banner => banner.bannerType === 'ANNOUNCEMENT');
      this.hotList = res.filter(banner => banner.bannerType === 'HOT_BANNER');

      console.log('Initialized Banners', { hot: this.hotList, announcements: this.announcementList });

      this.announcementDetails = this.announcementList.length ? this.announcementList[0] : {} as Announcement;

      this.initializeInterval();

      this.updateBannerState();

    }, (error: any) => {

      console.error(error);
      //this.tS.createMessage('error', 'Error retrieving banners. Please try again.');

    });

  }

  /**
   * Update banner from web socket
   *
   */
  updateBanner(data) {

    console.log('Update Banner', { data });

    if (data.generic?.bannerType === 'ANNOUNCEMENT') {

      const index = this.announcementList.findIndex((obj: Announcement) => +obj.announcementId === +data.generic.announcementId);

      console.log(`Announcement Banner ${data.generic.announcementId}`, {
        data,
        existingBanners: JSON.parse(JSON.stringify(this.announcementList)),
        exists: index !== -1
      });

      if (index !== -1) {

        this.announcementList.splice(index, 1);

      }

      // The WebSocket call does not contain a plainDescription version of the announcement
      // so we need to create it here
      if (!data.generic.plainDescription) {

        data.generic.plainDescription = Common.stripHTML(data.generic.description);

      }

      // If the announcement is not deleted, add to the list and show it in the banner
      if (!data.generic.isDeleted) {

        this.announcementList.unshift(data.generic);
        this.announcementDetails = this.announcementList[0];
        if (this.carouselAnnouncement) {

          this.carouselAnnouncement.goTo(0);

        }

      }
      this.initializeInterval();

      this.updateBannerState();

    }

    if (data.generic?.bannerType === 'HOT_BANNER') {

      const index = this.hotList.findIndex((obj: Announcement) => +obj.postId === +data.generic.postId);

      console.log(`Hot Banner ${data.generic.postId}`, {
        data,
        existingBanners: JSON.parse(JSON.stringify(this.hotList)),
        exists: index !== -1
      });

      if (index !== -1) {

        this.hotList.splice(index, 1);

      }

      // If the hot note is not deleted, add to the list and show it in the banner
      if (!data.generic.isDeleted) {

        this.hotList.unshift(data.generic);
        if (this.carouselHot) {

          this.carouselHot.goTo(0);

        }

      }
      this.initializeInterval();

      this.updateBannerState();

    }

  }

  /**
   * The Announcement or Hot banner should remain active for a specific duration from its creation time.
   * The RemainingDuration field in the banner indicates the duration for which the banner can stay active.
   * The validity of the RemainingDuration is verified every minute, and any banners that have expired are removed.
   */
  private removeBanner() {

    console.log(`EDT/EST time is three/four hours later than the time in the banner object. Time in the banner object is Greenwich Time - UTC(0). EDT/EST is UTC(3)/UTC(4)`);

    console.log(`Inside removeBanner() event to check whether any of the Hot banner must be removed`);

    for (let i = this.announcementList.length - 1; i >= 0; i--) {
      console.log(`Announcement details : `, { AnnouncementDetails: this.announcementList[i] });

      const { activeForMins, canRemove } = this.isCurrentDateTimeGreaterThanEndDateTimeOfBanner(this.announcementList[i].startsOn, this.announcementList[i].endsOn);

      console.log(`Announcement is active for `, { AnnouncementDetails: activeForMins });

      if (canRemove) {
        console.log(`Following Announcement removed on `, { AnnouncementDetails: this.announcementList[i], Date: this.getCurrentTimeDetails() });
        this.announcementList.splice(i, 1);
      }
    }

    for (let i = this.hotList.length - 1; i >= 0; i--) {
      console.log(`Hot Banner details : `, { HotBannerDetails: this.hotList[i] });

      const { activeForMins, canRemove } = this.isCurrentDateTimeGreaterThanEndDateTimeOfBanner(this.hotList[i].startsOn, this.hotList[i].endsOn);

      console.log(`Hot Banner is active for `, { HotBannerActiveFor: activeForMins });

      if (canRemove) {
        console.log(`Following Hot banner removed on `, { HotBannerDetails: this.hotList[i], Date: this.getCurrentTimeDetails() });
        this.hotList.splice(i, 1);
      }
    }


    this.updateBannerState();

  }

  /**
   * Tell the Common service the state of the banners
   *
   */
  updateBannerState() {

    this.banners.announcement.show = this.announcementList.length > 0;

    this.banners.hot.show = this.hotList.length > 0;

    if (this.banners.announcement.show && this.banners.hot.show) {

      this.cS.showBanner.next('both');

    } else if (this.banners.announcement.show && !this.banners.hot.show) {

      this.cS.showBanner.next('Announcement');

    } else if (!this.banners.announcement.show && this.banners.hot.show) {

      this.cS.showBanner.next('Hot');

    }

  }

  /**
   * Go to next banner
   *
   */
  next(type: string) {

    switch (type) {

      case 'announcement': {

        this.carouselAnnouncement.next();
        const index = this.carouselAnnouncement.activeIndex;

        this.announcementDetails = this.announcementList[index];

      }
        break;

      case 'hot':
        this.carouselHot.next();
        break;

    }

  }

  /**
   * Go to previous banner
   *
   */
  prev(type: string) {

    switch (type) {

      case 'announcement': {

        this.carouselAnnouncement.pre();
        const index = this.carouselAnnouncement.activeIndex;

        this.announcementDetails = this.announcementList[index];

      }
        break;

      case 'hot':
        this.carouselHot.pre();
        break;

    }

  }

  /**
   * Hide/Show details of announcement
   *
   */
  toggleAnnounceDetails() {

    const index = this.carouselAnnouncement.activeIndex;

    this.announcementDetails = this.announcementList[index];
    this.banners.announcement.details = !this.banners.announcement.details;

  }

  /**
   * Edit announcement
   *
   */
  editAnnouncement(announcementId: number) {

    this.route.navigate(['ncx-admin/create-announcement/:' + announcementId]);

  }

  /**
   * Delete announcement
   *
   */
  deleteAnnouncement(announcementId: number) {

    this.isLoaded = false;

    const url = environment.announcementUrl + '/' + announcementId;

    this.cS.serviceRequestCommon('delete', url).subscribe(() => {

      const index = this.announcementList.findIndex(list => list.announcementId === announcementId);

      if (index !== -1) {

        this.announcementList.splice(index, 1);

        if (this.announcementList.length) {

          this.announcementDetails = this.announcementList[0];
          if (this.carouselAnnouncement) {

            this.carouselAnnouncement.goTo(0);

          }

        }

      }

    }, (error: any) => {

      console.error(error);

      this.tS.createMessage('error', 'Could not delete announcement. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Convert UTC to dates
   *
   */
  utcToLocal(apiDate, type) {

    if (!apiDate) {

      return;

    }
    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  /**
   * Date of announcement
   *
   */
  get date(): string {

    const { createDateTime } = this.announcementDetails;

    const date = this.utcToLocal(createDateTime, 'DATE');

    const time = this.utcToLocal(createDateTime, 'TIME');

    return `${date} ${time}`;

  }

  /**
   * Person who created the announcement
   *
   */
  get name(): string {

    const { createUserId } = this.announcementDetails;

    return Common.formatName(createUserId, true);

  }

  /**
   * To redirect the url to current env
   *
   */
  redirectURL(url: string, newTab?: boolean) {

    window.open(this.urlRedirectService.getRedirectURL(url), newTab ? '_blank' : '_self');

  }

  initializeInterval() {

    console.log(`Checking the activeness of the interval on `, { Date: this.getCurrentTimeDetails() });

    // Check if the interval is not already active
    if (!this.intervalId) {

      console.log(`Interval was inactive. Interval active from `, { Date: this.getCurrentTimeDetails() });

      // Set the interval only if it's not already ac
      this.intervalId = setInterval(() => {

        console.log(`Calling RemoveBanner() event on `, { Date: this.getCurrentTimeDetails() });

        this.removeBanner();

      }, this.intervalToVerifyAndRemoveAlerts);

    }

  }

  isCurrentDateTimeGreaterThanEndDateTimeOfBanner(utcStartDateTimeString: string, utcEndDateTimeString: string): any {

    // Convert the UTC date to local date
    const utcToLocalStartDate = Time.convertingUtcToLocalDate(utcStartDateTimeString);

    const utcToLocalStartTime = Time.convertingUtcToLocalTimeOfhhmmss(utcStartDateTimeString);

    const utcToLocalStartDateTime = `${utcToLocalStartDate} ${utcToLocalStartTime}`;

    const startsOn = this.convertToDate(utcToLocalStartDateTime);

    // Convert the UTC date to local date
    const utcToLocalDate = Time.convertingUtcToLocalDate(utcEndDateTimeString);

    const utcToLocalTime = Time.convertingUtcToLocalTimeOfhhmmss(utcEndDateTimeString);

    const utcToLocalDateTime = `${utcToLocalDate} ${utcToLocalTime}`;

    const endsOn = this.convertToDate(utcToLocalDateTime);

    // Get the current local time
    const currentDateTime = new Date();

    // Calculate the difference in milliseconds
    const diffInMillis = currentDateTime.getTime() - startsOn.getTime();

    // Convert the difference to minutes
    const diffInMinutes = diffInMillis / (1000 * 60);

    // Check if the current datetime is greater than the end datetime of banner
    return { activeForMins: diffInMinutes, canRemove: currentDateTime > endsOn ? true : false }

  }


  getCurrentTimeDetails(): string {
    const currentDate = new Date();

    return this.getTimeDetails(currentDate);
  }


  getTimeDetails(date: Date): string {

    const { year, month, day, hours, minutes, seconds } = this.splitDate(date);

    const formattedDate = `${year}-${month}-${day} at ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  convertToDate(dateTimeString: string) {
    const [datePart, timePart] = dateTimeString.split(' '); // Split the string into date and time parts
    const [month, day, year] = datePart.split('/').map(Number); // Extract month, day, year
    const [hours, minutes, seconds] = timePart.split(':').map(Number); // Extract hours, minutes, seconds

    // Create the Date object
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }


  splitDate(date: Date): any {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return { year: year, month: month, day: day, hours: hours, minutes: minutes, seconds: seconds }
  }


  ngOnDestroy() {

    this.subs.unsubscribe();

    // Clear the interval only if it's active
    if (this.intervalId) {

      clearInterval(this.intervalId);
      this.intervalId = null; // Reset the interval ID

    }

  }

}
