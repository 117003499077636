<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<!-- *** Size : {{screenSize}} Width : {{windowWidth}}

**** isMobile : {{isMobile}} -->

<ng-container>
   <div #pageHeader>
      <app-standard-header
         title="Back"
         (clickBack)="goBackToCreate()"></app-standard-header>
   </div>

   <nz-content>
      <div class="angle-details-flex">
         <div class="angle-split">
            <section
               class="angle-details-body-section"
               #angleBody>
               <ng-container *ngTemplateOutlet="storyPostAngleTemplate"></ng-container>

               <ng-container *ngTemplateOutlet="angleDetailsTemplate"></ng-container>
            </section>
         </div>
      </div>
   </nz-content>

   <div class="footer-section">
      <nz-footer class="footer-flex">
         <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </nz-footer>
   </div>

   <app-google-docs
      [isVisible]="isVisible"
      (docLinkUpdated)="getDocLink($event)"></app-google-docs>
</ng-container>

<ng-template #storyPostAngleTemplate>
   <nz-radio-group
      class="story-post-angle-btns"
      [(ngModel)]="radioValue"
      [nzButtonStyle]="'solid'"
      (ngModelChange)="toggleStoryPostAngle($event)">
      <span *ngIf="!angleId">
         <label
            class="story-border"
            [nzDisabled]="disableStory"
            nz-radio-button
            nzValue="Story">
            Story
         </label>
         <label
            nz-radio-button
            nzValue="Post">
            Post
         </label>
         <label
            class="angle-border"
            nz-radio-button
            nzValue="Angle">
            Angle
            <app-beta
               [showBInMobile]="true"
               [isRadioSelected]="true"></app-beta>
         </label>
      </span>
      <span *ngIf="angleId">
         <label
            class="angle-label"
            nz-radio-button
            nzValue="Angle">
            Angle
            <app-beta
               [showBInMobile]="true"
               [isRadioSelected]="true"></app-beta>
         </label>
      </span>
   </nz-radio-group>
</ng-template>

<ng-template #angleDetailsTemplate>
   <div class="angle-body">
      <div class="angle-title-content">
         <div class="angle-title-section">
            <div class="angle-title">
               <input
                  required
                  #title="ngModel"
                  #angleTitleText
                  placeholder="Give your angle a title"
                  class="angle-title-text"
                  nz-input
                  [(ngModel)]="angleTitle"
                  [nzAutocomplete]="auto"
                  (ngModelChange)="titleChange()" />
               <nz-autocomplete
                  nzBackfill
                  #auto></nz-autocomplete>
               <div
                  *ngIf="title.invalid && (title.dirty || title.touched)"
                  class="alert alert-danger">
                  <div
                     class="error-message"
                     *ngIf="title.errors.required">
                     <span>Angle Title is required</span>
                  </div>
               </div>
            </div>

            <!--Dummy span to have a click event - Google Doc and Embedly Link works after click -->
            <span
               (click)="dummyGoogleDocEmbedyLinkClick()"
               id="dummy-Google-Doc-Embedy-Link"></span>
            <div class="rt-editor">
               <app-rteditor
                  #appRtEditor
                  [rteHeight]="212"
                  [input]="'angleDesc'"
                  [isEmitted]="isEmitted"
                  [rteValue]="angleContent"
                  (rteUpdated)="getRTEAngleData($event)"
                  (rtePasteContent)="getRTEAngleData($event)"
                  (rteContentChanged)="saveDraft($event)"
                  [googleDocs]="googleDocs"
                  (gFlag)="checkFlag($event)"></app-rteditor>
            </div>
         </div>
      </div>

      <div class="linked-stories-section">
         <div class="story-title">Collaborate</div>

         <app-linked-story-team
            [team]="collaboratorsDetails"
            [angleHasPrivateStory]="angleHasPrivateStory"
            [access]="angleAccess"
            [disableAccess]="angleId && !anglePrivacyMode ? true : false"
            [createdUser]="angleCreator"
            (updateInTeam)="updateInTeam($event)"
            (loggedInUser)="loggedInUser($event)"
            (updateInAccess)="updateInAccess($event)"></app-linked-story-team>
      </div>

      <div class="linked-stories-section">
         <div class="story-title">Story Details</div>

         <app-linked-stories
            [postRelatedStories]="relatedStories"
            [isPinnedOnTop]="true"
            (addedStories)="attachStoryToAngle($event)"
            [showCrown]="false"
            [showPin]="false"
            [disableSearch]="disableSearch"
            [showStoryChangeInfo]="false"
            [tooltip]="storyDetailsTooltip"
            [showMandatoryAsterisk]="true"
            [highlightMandatoryText]="highlightMandatoryStory"
            [mandatoryMsg]="'Story is required'"></app-linked-stories>
      </div>
   </div>
</ng-template>

<ng-template #footerTemplate>
   <button
      nz-button
      nzType="default"
      [nzShape]="isMobile ? 'circle' : ''"
      nzDanger
      class="footer-btn"
      [ngClass]="isMobile ? 'left-btn' : ''"
      (click)="redirectTo()">
      <span
         nz-icon
         nzType="close"
         nzTheme="outline"></span>
      <span *ngIf="!isMobile">Cancel</span>
   </button>

   <button
      nz-button
      nzType="primary"
      class="footer-btn publish-btn"
      (click)="publishAngle()">
      <span
         nz-icon
         nzType="send"
         nzTheme="outline"></span>
      Publish
   </button>
</ng-template>
