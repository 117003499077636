import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConstants } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { Discussion } from '@models/ncx/groups-discussions';
import { User } from '@models/users';
import { AuthManager } from '@services/auth/app.auth.manager';
import { AuthService } from '@services/auth/auth.service';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { BreakpointService } from '@services/breakpoint.service';
import { WebSocketService } from '@services/websocket.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-discussion-details',
  templateUrl: './discussion-details.component.html',
  styleUrls: ['./discussion-details.component.scss']
})
export class DiscussionDetailsComponent implements OnInit, OnDestroy {

  discussionApiURL: string;

  discussionDetails: Discussion;

  isLoaded = true;

  discussionId: any;

  groupId: any;

  discussionText: any;

  //discussionContent: any;
  isPinnedToTop: boolean;

  groupApiURL: string;

  groupName: any;

  isVisible = false;

  getApiURL: string;

  archiveUrl: string = '';

  originalDiscussionDetails = {} as Discussion;

  prevUrl: string;

  urlCheckFlag = false;

  loggedInUserId: number;

  customPopOver: boolean;

  profileInDescription: User = {} as User;

  discussionDetailsIsCollapsed = true;

  ddCollapsed = true;

  isMovePostDiscussion = false;

  @ViewChild('discussionContent') discussionContent: ElementRef;

  private subs = new SubSink();

  constructor(
    private router: ActivatedRoute,
    private authService: AuthService,
    private cService: CommonService,
    private toastService: ToastService,
    private reRoute: Router,
    private http: HttpClient,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private breakpointService: BreakpointService,
    private svc: AuthManager,
    private el: ElementRef,
    private titleService: Title,
    private wS: WebSocketService
  ) { }

  ngOnInit() {

    this.discussionApiURL = environment.getDiscussionURL;
    this.groupApiURL = environment.getGroupsURL;
    this.discussionDetails = {} as Discussion;
    this.router.queryParams.subscribe(
      (params: Params) => {

        this.setDiscussionId(params);

      });

    // tslint:disable-next-line:radix
    this.loggedInUserId = parseInt(localStorage.getItem('userId'));
    this.discussionDetailsIsCollapsed = this.breakpointService.isMobile.value;

    // Close the right-hand drawer if the browser is minimized to handset and the drawer is open
    this.subs.sink = this.breakpointService.isMobile.subscribe((isMobile: boolean) => {

      if (isMobile && !this.discussionDetailsIsCollapsed) {

        this.discussionDetailsIsCollapsed = true;

      }

    });

    this.subs.sink = this.wS.doSocketData$.subscribe(data => {
      if(data.group?.groupDiscussions[0]?.discussionId && this.discussionDetails.liveDiscussionId===data.group?.groupDiscussions[0]?.discussionId)
      {
      this.getDiscussionDetails(data.group?.groupDiscussions[0]?.discussionId);
      }
    });
  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }


  setDiscussionId(params) {

    if (params && params.discussionId && params.groupId) {

      this.discussionId = params.discussionId;
      this.groupId = params.groupId;
      this.getDiscussionDetails(this.discussionId);
      this.getGroupDetails(this.groupId);

    }

  }

  createDiscussionObject(discussionObj) {

    this.discussionDetails.discussionId = (discussionObj && discussionObj.discussionId) ? discussionObj.discussionId : null;
    this.discussionDetails.liveDiscussionId = (discussionObj && discussionObj.liveDiscussionId) ? discussionObj.liveDiscussionId : null;
    this.discussionId = (discussionObj && discussionObj.discussionId) ? discussionObj.discussionId : null;
    this.discussionDetails.groupId = (discussionObj && discussionObj.groupId) ? discussionObj.groupId : null;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionSubject = (discussionObj && discussionObj.discussionSubject) ? discussionObj.discussionSubject : null;
    this.discussionText = (discussionObj && discussionObj.discussionSubject) ? discussionObj.discussionSubject : null;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionContent = (discussionObj && discussionObj.discussionContent) ? discussionObj.discussionContent : null;
    this.addS3TokenToDiscussionDetailsContentHTML();

    //this.discussionContent = (discussionObj.discussionContent) ? discussionObj.discussionContent : '';
    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionAttachments = (discussionObj && discussionObj.discussionAttachments)
      ? discussionObj.discussionAttachments
      : null;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionVersionGroupId = (discussionObj && discussionObj.discussionVersionGroupId)
      ? discussionObj.discussionVersionGroupId
      : null;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionVersionState = (discussionObj && discussionObj.discussionVersionState)
      ? discussionObj.discussionVersionState
      : 'DRAFT';

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.isDiscussionPublished = (discussionObj && discussionObj.isDiscussionPublished)
      ? discussionObj.isDiscussionPublished
      : 'PUBLIC';
    this.discussionDetails.isPinnedOnTop = (discussionObj && discussionObj.isPinnedOnTop) ? discussionObj.isPinnedOnTop : false;
    this.isPinnedToTop = (this.discussionDetails && this.discussionDetails.isPinnedOnTop);
    this.discussionDetails.createDateTime = (discussionObj && discussionObj.createDateTime) ? discussionObj.createDateTime : null;
    this.discussionDetails.createUser = (discussionObj && discussionObj.createUser) ? discussionObj.createUser : null;

    // tslint:disable-next-line:max-line-length
    this.discussionDetails.discussionCommentCount = (discussionObj && discussionObj.discussionCommentCount)
      ? discussionObj.discussionCommentCount
      : 0;

  }

  /**
   * Build the Discussion Content HTML
   * JWT Token has to be added to the img S3 link to download the file from S3
   *
   */
  addS3TokenToDiscussionDetailsContentHTML() {

    if (this.discussionDetails?.discussionContent) {

      setTimeout(() => {

        // JWT Token has to be added to the img S3 link to download the file from S3
        this.discussionDetails.discussionContent = this.authService.addJWTTokenToLink(this.discussionDetails?.discussionContent, 'img');

        /**
         * .fr-deletable class added to the description if a profile is added to the froala editor - @username
         */
        const part = this.discussionContent.nativeElement.querySelectorAll('.fr-deletable');

        part.forEach((anchor: HTMLAnchorElement) => {

          anchor.addEventListener('mouseenter', this.handleAnchorClick.bind(this));
          anchor.addEventListener('click', this.handleAnchorClick.bind(this));

        });

      }, 500);

    }

  }

  toggleSider(): void {

    this.discussionDetailsIsCollapsed = !this.discussionDetailsIsCollapsed;

  }

  closeSlider() {

    this.discussionDetailsIsCollapsed = true;

  }

  openDiscussionMovePost() {

    this.isMovePostDiscussion = true;

  }

  closeMovePost() {

    this.isMovePostDiscussion = false;

  }

  shareContent() {

    this.isVisible = true;

  }

  handleCancel() {

    this.isVisible = false;

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  utcToLocal1(apiDate) {

    return apiDate.toString().split(' ')[0];

  }

  openGroupVersions() {

    this.reRoute.navigate(['/group-versions/:' + this.discussionDetails.discussionVersionGroupId]);

  }

  back() {

    this.reRoute.navigate(['/group-details/:' + this.groupId]);

  }

  deleteDiscussion(data) {

    if (data) {

      this.deleteDiscussionDetails();

    }

  }

  updateGroupDetails(event) {

    if (event && event.sucess && event.groupId) {

      this.groupId = event.groupId;
      this.getGroupDetails(event.groupId);

    }

  }

  unlockPost(event: any) {

    if (event === 'true') {

      this.isLoaded = false;
      const queryStr = `/${this.discussionDetails.discussionVersionGroupId}/lock/delete`; // need to pass postversiongroupid

      this.cService.serviceRequestCommon('post', this.discussionApiURL, queryStr).subscribe(() => {


        this.toastService.createMessage('success', 'The post has been successfully unlocked');
        this.reRoute.navigateByUrl('/', { skipLocationChange: true }).then(() => {

          this.reRoute.navigate(['/discussion-details'],
            { queryParams: { groupId: this.groupId, discussionId: this.discussionDetails.liveDiscussionId } });

        });

      }, () => {

        this.toastService.createMessage('error', 'Error While unlocking Post please try again');

      }).add(() => {

        this.isLoaded = true;

      });

    }

  }

  downloadDiscPDF(event) {

    if (event === true) {

      const ncxjwttoken = localStorage.getItem('ncxjwttoken');

      const messageId = this.commonFunctionsHelper.generateUUID();

      const headers = new HttpHeaders({
        messageId,
        ncxjwttoken,
        Accept: 'application/pdf',

        // // responseType: 'blob' as 'pdf',
        'Content-Type': 'application/json'
      });

      this.isLoaded = false;
      const queryStr = `/${this.discussionDetails.discussionId}/viewAsPdf/testing.pdf?serverTimeZone=${Time.localTimeZone()}`; // need to pass

      // postversiongroupid
      const url = this.cService.getAPIEndpoint() + `/ncx-gateway${this.discussionApiURL}` + queryStr;

      this.http.get(url, { headers, responseType: 'blob' as const })

        // this.cService.serviceRequestCommon('get', this.getApiURL, queryStr)
        .subscribe((val) => {

          const blob = new Blob([val], {
            type: 'application/pdf'
          });

          const file = new Blob([blob], { type: 'application/pdf' });

          const fileURL = URL.createObjectURL(file);

          const browser = Common.getBrowser();

          if (browser.name === 'safari') {

            const link = document.createElement('a');

            link.href = fileURL;
            link.target = '_blank';
            link.download = `${this.discussionDetails.discussionSubject}.pdf`;
            link.click();
            window.open(`${this.discussionDetails.discussionSubject}.pdf`, '_target');
            window.URL.revokeObjectURL(link.href);

          } else {

            window.open(fileURL, '_target');

          }

        }, () => {

          this.toastService.createMessage('error', 'Error While Downloading please try again');

        }).add(() => {

          this.isLoaded = true;

        });

    }

  }

  getDiscussionDetails(discussionId) {

    this.prevUrl = this.svc.getPreviousUrl();
    const queryStr = `/${discussionId}`;

    this.isLoaded = false;

    this.cService.serviceRequestCommon('get', this.discussionApiURL, queryStr).subscribe((res: any) => {

      if (res.isDeleted === true) {

        this.toastService.createMessage('warning', 'Discussion does not Exist.');
        this.reRoute.navigate(['/create-group']); // need to change redirection link upon confirmation

      } else {

        this.createDiscussionObject(res);
        this.originalDiscussionDetails = JSON.parse(JSON.stringify(res));

      }
      this.discussionDetails = res;
      this.titleService.setTitle(res.discussionSubject);
      if (this.discussionDetails.discussionVersionState === 'Archived') {

        this.archiveUrl = 'ncx/discussion-details?groupId=' + this.groupId + '&discussionId=' + this.discussionDetails.liveDiscussionId;
        if (this.prevUrl.startsWith(AppConstants.local.Url_groupVersions)) {

          this.urlCheckFlag = true;

        } else {

          this.urlCheckFlag = false;
          this.goToLive(this.discussionDetails.liveDiscussionId);

        }

      }

    },
      (err) => {

        if (err === 'DISCUSSION-003') {

          this.toastService.createMessage('error', 'You Do Not Have Access to the Discussion');
          setTimeout(() => {

            this.reRoute.navigate(['ncx/group-landing']);

          }, 500);

        } else {

          this.toastService.createMessage('error', err);

        }

      }).add(() => {

        this.isLoaded = true;

      });

  }

  goToLive(liveId) {

    this.reRoute.navigate(['/discussion-details'], { queryParams: { groupId: this.groupId, discussionId: liveId } });

  }

  createNewVersion() {

    const queryStr = '';

    const dataToSave = this.originalDiscussionDetails;

    dataToSave.discussionId = dataToSave.discussionId;
    if (this.groupId !== null && this.groupId !== undefined) {
      if (this.originalDiscussionDetails.groupId !== this.groupId) {
        dataToSave.groupId = this.groupId;
      }
    }
    dataToSave.discussionVersionState = 'Draft';
    dataToSave.isDiscussionPublished = false;
    dataToSave.createUserId = this.originalDiscussionDetails.createUser.userId;
    dataToSave.createUser = this.originalDiscussionDetails.createUser;

    // tslint:disable-next-line:max-line-length
    dataToSave.discussionContent = (this.originalDiscussionDetails.discussionContent) ? this.originalDiscussionDetails.discussionContent : ''; // this.disContent.nativeElement.innerHTML;
    this.cService.serviceRequestCommon('post', this.discussionApiURL, queryStr, dataToSave).subscribe((res: any) => {

      if (res && res.discussionId) {

        this.reRoute.navigate(['/ncx/create-discussion'], { queryParams: { groupId: this.groupId, discussionId: res.discussionId } });

      }

    }, () => {

      this.toastService.createMessage('error', 'Error In Loading Data. Please Try Again');

    });

  }

  editDiscussion(_val) {

    if (this.discussionDetails && this.discussionDetails.discussionVersionState &&
      this.discussionDetails.discussionVersionState === 'Archived') {

      this.toastService.createMessage('warning', 'You cannot edit a Archived Discussion');
      return;

    } else if (this.discussionDetails.discussionVersionState === 'Live') {

      this.createNewVersion();

    } else {

      this.reRoute.navigate(['/ncx/create-discussion'], { queryParams: { groupId: this.groupId, discussionId: this.discussionId } });

    }

  }

  handleAnchorClick1() {

    this.customPopOver = false;

  }

  handleAnchorClick(event) {

    this.customPopOver = false;
    if (event && event.srcElement && event.srcElement.id && event.srcElement.id.startsWith('user-')) {

      const userId = event.srcElement.id.split('-')[1];

      this.profileInDescription.userId = userId;

    } else {

      return;

    }
    setTimeout(() => {

      const element: HTMLElement = document.getElementById('triggerClick') as HTMLElement;

      element.style.left = (event.offsetX) + 'px';
      element.style.top = (event.offsetY) + 'px';
      this.customPopOver = true;

      element.click();

    });

  }

  getGroupDetails(groupId) {

    const queryStr = `/${groupId}`;

    this.isLoaded = false;
    this.cService.serviceRequestCommon('get', this.groupApiURL, queryStr).subscribe((res: any) => {

      if (res.isDeleted === true) {

        this.toastService.createMessage('warning', 'Group does not Exist.');
        this.reRoute.navigate(['/group-landing']); // need to change redirection link upon confirmation

      } else {

        this.groupName = res.groupName;

      }
      this.titleService.setTitle(res.groupName);

    },
      (err) => {

        if (err !== 'GROUP-003') {

          this.toastService.createMessage('error', err);

        }

      }).add(() => {

        this.isLoaded = true;

      });

  }

  deleteDiscussionDetails() {

    const queryStr = `/${this.discussionDetails.discussionVersionGroupId}`;

    this.isLoaded = false;
    this.cService.serviceRequestCommon('delete', this.discussionApiURL, queryStr).subscribe(() => {

      this.toastService.createMessage('warning', 'Discussion has been successfully deleted.');
      this.reRoute.navigate(['/group-details/:' + this.groupId]);

    },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });

  }

  discussionLocked(discussion) {

    this.discussionDetails = discussion;

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

}
