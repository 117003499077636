export const AppConstants = {
  // imagetype: 'image/png,image/jpeg,image/gif,image/bmp',
  imagetype:
    'image/png,image/jpeg,image/gif,image/bmp,image/tiff,image/pipeg,image/ief,image/svg+xml,image/x-icon',
  imageactionType: 'http://localhost:8083/api/v1/ncx/user/1/avatar',
  googlePattern:
    '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
  local: {
    Url_profile: '/ncx/profile',
    Url_profile_header: 'Profile',
    Url_profileHistory: '/ncx/history',
    Url_profileHistory_header: 'History',
    Url_settings: '/ncx/settings',
    Url_settings_header: 'Settings',
    Url_following: '/ncx/following',
    Url_following_header: 'Following',
    Url_followedStories: '/ncx/followed-stories',
    Url_followedStories_header: 'Followed Stories',

    Url_createGroup: '/create-group',
    Url_createGroup_header: 'Create Group',
    Url_editGroup: '/create-group/',
    Url_editGroup_header: 'Edit Group',
    Url_groupDetails: '/group-details',
    Url_groupDetails_header: 'Group Details',
    Url_groupLanding: '/ncx/group-landing',
    Url_groupLanding_header: 'Group',

    Url_manageMembers: '/manage-members',
    Url_manageMembers_header: 'Manage Members',
    Url_createDiscussion: '/create-discussion',
    Url_createDiscussion_header: 'Create Discussion',
    Url_editDiscussion: '/create-discussion?groupId',
    Url_editDiscussion_header: 'Edit Discussion',
    Url_discussionDetails: '/discussion-details',
    Url_discussionDetails_header: 'Discussion Details',
    Url_groupFollowers: '/group-followers',
    Url_groupFollowers_header: 'Group Followers',
    Url_groupVersions: '/group-versions',
    Url_groupVersions_header: 'Group Versions',

    Url_connections: '/ncx/connections',
    Url_connections_header: 'Connections',
    Url_drafts: '/ncx/drafts',
    Url_drafts_header: 'Drafts',
    Url_activity: '/ncx/activity/:All',
    Url_activity_header: 'Activity',
    Url_activityHot: '/ncx/activity/:Hot',
    Url_activityHot_header: 'Hot/Important',
    Url_activityStandards: '/ncx/activity/:Standards',
    Url_activityStandards_header: 'Standards',
    Url_alerts: '/ncx/alerts',
    Url_alerts_header: 'Alerts',
    Url_otherTools: '/ncx/other-tools',
    Url_otherTools_header: 'Other Tools',
    Url_admin: '/ncx-admin/landing',
    Url_admin_header: 'Admin',
    Url_trackSendToMediaCentral: '/ncx-admin/track-sendToMediaCentral',
    Url_trackSendToMediaCentral_header: 'Track Send to MediaCentral',
    Url_feed: '/ncx/feeds',
    Url_feed_header: 'Feed',
    Url_ImageAsset: 'assets/images',
    Url_topics: '/ncx/topics',
    Url_topics_header: 'Topics',

    Url_development: '/ncx-dev',
    Url_development_header: '/ncx-dev',
    Url_createContent: 'Create Content',
    Url_NewsConnect: 'NewsConnect',

    Url_storiesDashboard: '/ncx/stories-dashboard',
    Url_storiesDashboard_header: 'Stories',

    Url_createStory: '/ncx/create-story',
    Url_createStory_header: 'Create Story',
    Url_viewStory: '/ncx/view-story',
    Url_viewStory_header: 'Story Details',
    Url_editStory: '/ncx/create-story/:',
    Url_editStory_header: 'Edit Story',
    Url_storyLanding: '/ncx/landing-story',
    Url_storyLanding_header: 'Story',

    Url_createPost: '/ncx/post',
    Url_createPost_header: 'Create Post',
    Url_viewPost: '/ncx/view-post',
    Url_viewPost_header: 'Post Details',
    Url_editPost: '/ncx/post/:',
    Url_editPost_header: 'Edit Post',
    Url_postVersions: '/ncx/post-versions',
    Url_postVersions_header: 'Managed Versions',

    Url_createAngle: '/ncx/angle',
    Url_createAngle_header: 'Create Angle',
    Url_editAngle: '/ncx/angle/:',
    Url_editAngle_header: 'Edit Angle',
    Url_angleLanding: '/ncx/landing-story',
    Url_angleLanding_header: 'Angle',
  },
  domain: {
    local_url: 'http://localhost:4200',

    prior_dev1_url: 'https://dev1-ncx.nbcuni.com',
    prior_dev2_url: 'https://dev2-ncx.nbcuni.com',
    prior_qa1_url: 'https://qa1-ncx.nbcuni.com',
    prior_qa2_url: 'https://qa2-ncx.nbcuni.com',
    prior_stg1_url: 'https://stg-ncx.nbcuni.com',
    prior_stg2_url: 'https://stg1-ncx.nbcuni.com',

    rts_dev_ui_url: 'https://devui.newsconnect.nbcuni.com',
    rts_qa_ui_url: 'https://qaui.newsconnect.nbcuni.com',
    rts_stg_ui_url: 'https://stgui.newsconnect.nbcuni.com',

    rts_dev_api_url: 'https://devapi.newsconnect.nbcuni.com',
    rts_qa_api_url: 'https://qaapi.newsconnect.nbcuni.com',
    rts_stg_api_url: 'https://stgapi.newsconnect.nbcuni.com',

    rts_dev_ws_url: 'https://devwss.newsconnect.nbcuni.com',
    rts_qa_ws_url: 'https://qawss.newsconnect.nbcuni.com',
    rts_stg_ws_url: 'https://stgwss.newsconnect.nbcuni.com',

    capx_dev_ui_url: 'https://dev.newsconnect.nbcuni.com',
    capx_dev_api_url: 'https://dev-api.newsconnect.nbcuni.com',
    capx_dev_ws_url: 'https://dev-wss.newsconnect.nbcuni.com',

    capx_qa_ui_url: 'https://qa.newsconnect.nbcuni.com',
    capx_qa_api_url: 'https://qa-api.newsconnect.nbcuni.com',
    capx_qa_ws_url: 'https://qa-wss.newsconnect.nbcuni.com',

    capx_stg_ui_url: 'https://stg.newsconnect.nbcuni.com',
    capx_stg_api_url: 'https://stg-api.newsconnect.nbcuni.com',
    capx_stg_ws_url: 'https://stg-wss.newsconnect.nbcuni.com',

    prod_ui_url: 'https://newsconnect.nbcuni.com',
    prod_api_url: 'https://api.newsconnect.nbcuni.com',
    prod_ws_url: 'https://wss.newsconnect.nbcuni.com',
  },
  apiUrls: {
    profile: 'api/v1/ncx/user',
  },
  journalistRole: 'journalist',
  userRoleCheck: 'journalist',
  editorRole: 'editor',

  // tslint:disable-next-line:max-line-length
  videoExtensions: [
    'x-msvideo',
    'avi',
    'mp4',
    '3gp',
    'm4v',
    'mpeg',
    'x-ms-wmv',
    'quicktime',
    'x-matroska',
    'x-mpeg',
    'x-project',
    'x-shockwave-flash',
    'mov',
  ],

  // tslint:disable-next-line:max-line-length
  audioExtensions: [
    'wav',
    'basic',
    'midi',
    'x-mid',
    'x-midi',
    'mpeg',
    'x-mpeg',
    'vnd.wav',
    'x-mpeg',
    'mid',
    'mp3',
    'x-aiff',
    'x-mpegurl',
    'vnd.rn-realaudio',
    'ogg',
    'vorbis',
  ],
};
