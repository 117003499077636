<div
   class="topicSection"
   *ngIf="!viewMode">
   <nz-input-group
      [nzSuffix]="suffixIconSearch"
      class="input-group">
      <nz-select
         [nzShowSearch]="true"
         nzMode="default"
         class="topicSelect"
         nzPlaceHolder="Select Topic"
         [(ngModel)]="selectedValue"
         [nzShowArrow]="false"
         [nzShowSearch]="true"
         nzAllowClear
         (ngModelChange)="selectedTopics($event)"
         (input)="TopicSearch($event)"
         #topic="ngModel"
         nzAutoClearSearchValue="true">
         <nz-option
            *ngFor="let item of listOfOption"
            [nzLabel]="item.topicName"
            [nzValue]="item"></nz-option>
      </nz-select>
      <ng-template #suffixIconSearch>
         <span
            nz-icon
            nzType="search"
            class="search-topic-icon"></span>
      </ng-template>
   </nz-input-group>
</div>

<div
   class="show-topics"
   *ngIf="!viewMode">
   <ng-container *ngFor="let topic of listOfSelectedValue; let i = index">
      <div class="topic">
         <div class="topic-text">{{ topic.topicName }}</div>
         <div
            nz-icon
            nzType="close"
            nzTheme="outline"
            (click)="handleClose(topic)"
            class="topic-close"></div>
      </div>
   </ng-container>
</div>

<div
   class="viewTopics"
   *ngIf="viewMode">
   <ng-Container *ngFor="let item of listOfSelectedValue">
      <div class="topicLabel">
         {{ item.topicName }}
      </div>
   </ng-Container>
</div>
