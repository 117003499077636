import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';

@Injectable()
export class AuthGuardService {
  currentUrl = '';
  constructor(
    private router: Router,
    private userInfo: TransferInformationComponent
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        // Redirection for the Old URL with '/#/'
        if(this.currentUrl.includes('/#/')) {
          this.currentUrl = this.currentUrl.replace('/#/', '/')
          this.router.navigate([this.currentUrl]);
        }
      }

    });
  }

  canActivate(): boolean {
    
    console.log('App.auth.gard.ts (canActivate): ', this.userInfo.linkLoggedInErrSource.value);

    return !!this.userInfo.linkLoggedInErrSource.value.errorStatus;

  }

}
