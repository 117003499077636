import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Common } from '@utilities/common';
import { Discussion } from '@models/ncx/dashboard';
import * as moment from 'moment/moment';
import { Time } from '@utilities/time';
import { Router } from '@angular/router';

@Component({
  selector: 'ncx-group-discussion',
  templateUrl: './group-discussion.component.html',
  styleUrls: ['../../cards-common.scss', './group-discussion.component.scss']
})
export class GroupDiscussionComponent {

  @Input() discussion: Discussion = {} as Discussion;

  // The location of the card determines its layout
  @Input() location: 'dashboard' | '' = '';

  // Callback after Discussion is Shared
  @Output() afterContentShared: EventEmitter<Discussion> = new EventEmitter<Discussion>();

  showShareModal: boolean = false;

  public readonly Common = Common;

  constructor(private router: Router) {}

  /**
   * Calculate date difference (for time ago)
   *
   */
  calculateDiff(date): string {

    return Time.calculateDiff(date);

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  /**
   * Open Share modal
   *
   */
  onShare() {

    this.showShareModal = true;

  }

  /**
   * Close share Modal
   *
   */
  closeShareModal() {

    this.showShareModal = false;

  }

  /**
   * After content is shared
   *
   */
  contentShared() {

    this.afterContentShared.emit(this.discussion);

  }

  /**
   * Check if the discussion was updated within the past 24 hours
   *
   */
  get isDiscussionUpdated(): boolean {

    try {

      const { updateDateTime } = this.discussion;

      if (!updateDateTime) {

        return false;

      }

      const hours = moment().diff(moment.utc(updateDateTime), 'hours');

      return hours < 24;

    } catch (error) {

      return false;

    }

  }

  get shareMessage(): string {

    return this.discussion?.discussionSubject || '';

  }

  /**
   * Does the Discussion have content
   *
   */
  get hasDiscussionContent(): boolean {

    return !!(this.discussion?.discussionPlainContent || '').trim();

  }

  openDiscussion() {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/ncx/discussion-details`], {queryParams: {groupId: this.discussion.groupId, discussionId: this.discussion.discussionId}}));
      window.open(url, '_blank');
  }

}
