export const MATERIAL_DIVISIONS = [
  { id: 1, name: 'Acorn' },
  { id: 2, name: 'CNBC' },
  { id: 3, name: 'CNBC Digital' },
  { id: 4, name: 'Corporate' },
  { id: 5, name: 'Engineering' },
  { id: 6, name: 'Entertainment' },
  { id: 7, name: 'ERG Events' },
  { id: 8, name: 'Facilities' },
  { id: 9, name: 'MSNBC' },
  { id: 10, name: 'MSNBC Digital' },
  { id: 11, name: 'NBC News' },
  { id: 12, name: 'NBC News Digital' },
  { id: 13, name: 'NBC News Productions/Peacock Productions' },
  { id: 14, name: 'NBC News Studios' },
  { id: 15, name: 'NBC NewsChannel' },
  { id: 16, name: 'NBC Sports' },
  { id: 17, name: 'Olympics' },
  { id: 18, name: 'Operations' },
  { id: 19, name: 'Peacock Streaming' },
  { id: 20, name: 'Telemundo' },
  { id: 21, name: 'Universal Kids' },
];

export const MATERIAL_SHOWS = [
  { id: 1, name: 'A&E' },
  { id: 1, name: 'AM Joy' },
  { id: 1, name: 'Abrams Report' },
  { id: 1, name: 'Advisory' },
  { id: 1, name: 'Agreements' },
  { id: 1, name: 'Alex Wagner Tonight' },
  { id: 1, name: 'All In with Chris Hayes' },
  { id: 1, name: 'All NBC News Shows (Network Affiliate Cable)' },
  { id: 1, name: 'American Greed' },
  { id: 1, name: 'American Greed: The Fugitives' },
  { id: 1, name: 'American Originals' },
  { id: 1, name: 'Andrea Mitchell Reports' },
  { id: 1, name: 'Animal Planet' },
  { id: 1, name: 'BET' },
  { id: 1, name: 'CNBC Business News' },
  { id: 1, name: 'CNBC Digital' },
  { id: 1, name: 'CNBC Longform' },
  { id: 1, name: 'CNBC On Assignment' },
  { id: 1, name: 'CNBC Reports' },
  { id: 1, name: 'CNBC Specials' },
  { id: 1, name: 'CNBC Titans' },
  { id: 1, name: 'Caught On Camera' },
  { id: 1, name: 'Channel Five' },
  { id: 1, name: 'City In Fear' },
  { id: 1, name: 'Cloo' },
  { id: 1, name: 'Closing Bell' },
  { id: 1, name: 'Countdown with Keith Olbermann' },
  { id: 1, name: 'Court TV' },
  { id: 1, name: 'DIY Network' },
  { id: 1, name: 'DVD' },
  { id: 1, name: 'Daily Connection' },
  { id: 1, name: 'Daily Programs' },
  { id: 1, name: 'Dateline Conversion' },
  { id: 1, name: 'Dateline NBC' },
  { id: 1, name: 'Dateline NBC/ MSNBC Longform' },
  { id: 1, name: 'Dateline NBC/ Web' },
  { id: 1, name: 'Dayside' },
  { id: 1, name: 'Digital Video Features' },
  { id: 1, name: 'Discovery' },
  { id: 1, name: 'Dylan Ratigan Show' },
  { id: 1, name: 'E! Entertainment' },
  { id: 1, name: 'ESPN' },
  { id: 1, name: 'Early Look' },
  { id: 1, name: 'Early Today Show' },
  { id: 1, name: 'Ed Show' },
  { id: 1, name: 'Fast Money' },
  { id: 1, name: 'Fast Money Half Time Report' },
  { id: 1, name: 'Fireside History' },
  { id: 1, name: 'Hardball with Chris Mathews' },
  { id: 1, name: 'Headliners' },
  { id: 1, name: 'Headliners & Legends' },
  { id: 1, name: 'Headliners & Legends with Lester Holt' },
  { id: 1, name: 'History Channel' },
  { id: 1, name: 'How I Made My Millions' },
  { id: 1, name: 'LIVE Specials' },
  { id: 1, name: 'Last Call' },
  { id: 1, name: 'Left Field' },
  { id: 1, name: 'Licensing And Syndication' },
  { id: 1, name: 'Lifetime' },
  { id: 1, name: 'Lock Up' },
  { id: 1, name: 'MSNBC' },
  { id: 1, name: 'MSNBC Catastrophe' },
  { id: 1, name: 'MSNBC Crime & Punishment' },
  { id: 1, name: 'MSNBC Dateline Conversion' },
  { id: 1, name: 'MSNBC Films' },
  { id: 1, name: 'MSNBC Investigates' },
  { id: 1, name: 'MSNBC Live (Dayside)' },
  { id: 1, name: 'MSNBC Longform' },
  { id: 1, name: 'MSNBC Originals' },
  { id: 1, name: 'MSNBC Reports' },
  { id: 1, name: 'MSNBC Specials' },
  { id: 1, name: 'MSNBC.com' },
  { id: 1, name: 'MTP Daily' },
  { id: 1, name: 'Martin Bashir Show' },
  { id: 1, name: 'Maximum Drama' },
  { id: 1, name: 'Meet the Press' },
  { id: 1, name: 'Melissa Harris Perry (MHP)' },
  { id: 1, name: 'Military Channel' },
  { id: 1, name: 'Miscellaneous' },
  { id: 1, name: 'Miscellaneous(CNBC Cable)' },
  { id: 1, name: 'Miscellaneous(MSNBC Cable)' },
  { id: 1, name: 'Miscellaneous(NBC News Production/ Peacock Prod' },
  { id: 1, name: 'Miscellaneous(NBC News)' },
  { id: 1, name: 'Miscellaneous(NBC Newschannel)' },
  { id: 1, name: 'Morning Joe' },
  { id: 1, name: 'NBC Learn' },
  { id: 1, name: 'NBC News' },
  { id: 1, name: 'NBC News Digital Miscellaneous' },
  { id: 1, name: 'NBC News Specials' },
  { id: 1, name: 'NBC Nightly News' },
  { id: 1, name: 'NBC Out' },
  { id: 1, name: 'NBC Publishing' },
  { id: 1, name: 'NBCBLK.com' },
  { id: 1, name: 'NBCNEWS.COM WEB REPORT' },
  { id: 1, name: 'National Geographic' },
  { id: 1, name: 'News Nation' },
  { id: 1, name: 'News Now' },
  { id: 1, name: 'News Now Top Story with Tom Llamas' },
  { id: 1, name: 'News with Shepard Smith' },
  { id: 1, name: 'Nightly News Films' },
  { id: 1, name: 'Nightly News Kids' },
  { id: 1, name: 'Nightly News Web' },
  { id: 1, name: 'On Assignment with Richard Engel' },
  { id: 1, name: 'Oxygen' },
  { id: 1, name: 'PBS' },
  { id: 1, name: 'Peacock Streaming' },
  { id: 1, name: 'Peacock TV' },
  { id: 1, name: 'Podcasts' },
  { id: 1, name: 'Politcs Nation' },
  { id: 1, name: 'Power Lunch' },
  { id: 1, name: 'Quibi' },
  { id: 1, name: 'REELZ' },
  { id: 1, name: 'RLTV' },
  { id: 1, name: 'Race to the White House' },
  { id: 1, name: 'Rachel Maddow Show' },
  { id: 1, name: 'Rock Center With Brian Williams' },
  { id: 1, name: 'SCI FI' },
  { id: 1, name: 'Scarborough Country' },
  { id: 1, name: 'Secret Lives of the Super Rich' },
  { id: 1, name: 'Specials' },
  { id: 1, name: 'Squawk on the Street' },
  { id: 1, name: 'Stay Tuned (on Snapch at Discover)' },
  { id: 1, name: 'Style Network' },
  { id: 1, name: 'Sundance Channel' },
  { id: 1, name: 'Sunday Night with Megyn Kelly' },
  { id: 1, name: 'Sunday Today with Willie Geist' },
  { id: 1, name: 'TLC' },
  { id: 1, name: 'TODAY All Day' },
  { id: 1, name: 'Telemundo' },
  { id: 1, name: 'The 11th Hour' },
  { id: 1, name: 'The Choice' },
  { id: 1, name: 'The Daily Rundown' },
  { id: 1, name: 'The Grio' },
  { id: 1, name: 'The Last Word With Lawrence ODonnell' },
  { id: 1, name: 'The Mehdi Hasan Show' },
  { id: 1, name: 'The Profit' },
  { id: 1, name: 'The ReidOut with Joy Reid' },
  { id: 1, name: 'The Situation with Tucker Carlson' },
  { id: 1, name: 'The Swamp' },
  { id: 1, name: 'Time & Again' },
  { id: 1, name: 'Today' },
  { id: 1, name: 'Today Digital' },
  { id: 1, name: 'Today Show/ Web Segment' },
  { id: 1, name: 'Trace International' },
  { id: 1, name: 'Travel Channel' },
  { id: 1, name: 'TruTV' },
  { id: 1, name: 'USA Network' },
  { id: 1, name: 'Up with...' },
  { id: 1, name: 'VH1' },
  { id: 1, name: 'Verdict' },
  { id: 1, name: 'Way Too Early' },
  { id: 1, name: 'Weather Channel' },
  { id: 1, name: 'Weekend Nightly News' },
  { id: 1, name: 'Weekend Today' },
  { id: 1, name: 'West Texas Investment Club' },
  { id: 1, name: 'Your Business' },
  { id: 1, name: 'Your Total Health' },
];

export const MATERIAL_CLEARED_FOR = [
  { text: 'All Partners & Platforms', value: 'yes' },
  { text: 'Select Divisions/Shows', value: 'no' },
];
