import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MATERIAL_CLEARED_FOR, MATERIAL_DIVISIONS, MATERIAL_SHOWS } from '@models/ncx/const';
import { AttachmentDetails, Division, Show } from '@models/ncx/post';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { BreakpointService } from '@services/breakpoint.service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-attachments-new',
  templateUrl: './attachments-new.component.html',
  styleUrls: ['./attachments-new.component.scss']
})
export class AttachmentsNewComponent implements OnInit, OnChanges {

  attachmentTitle = '';

  selectedValue = 'Lucy';

  //drop = true;
  fileList: NzUploadFile[] = [];

  toastFlag = false;

  subject: Subject<string> = new Subject();

  @Input() attachmentDetails = {} as AttachmentDetails;

  @Input() attachments = [];

  @Input() rteValue;

  @Input() postTitle;

  @Input() isAttachmentDisabled;

  @Input() savedpostId;

  @Input() saveDiscussionId;

  @Input() hideElements;

  @Input() screenType;

  @Output() attachmentsUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output() attachmentDetailsUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output() materialsUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output() isAttachmentDeleted = new EventEmitter<any>();
  @Output() eventUpdater = new EventEmitter<any>();
  @ViewChild('creditName') public creditName: ElementRef<any>;

  @Output() postEdited: EventEmitter<any> = new EventEmitter<any>();

  isNewPost = true;

  mandatory;

  postId: any = 0;

  isAttachmentsBinded = false;

  disbaleUpload = true;

  header: HttpHeaders;

  postAttachmentAPIURL: any;

  removedAttachmentIds: any = [];

  materialsClearedFor: any = JSON.parse(JSON.stringify(MATERIAL_CLEARED_FOR));

  availableDivisions = [];

  defaultDivisions: Division[] = JSON.parse(JSON.stringify(MATERIAL_DIVISIONS));

  availableShows = [];

  defaultShows: Show[] = JSON.parse(JSON.stringify(MATERIAL_SHOWS));

  selectedDivisions = [];

  selectedShows = [];

  isXSToLarge: boolean = false;

  isXtraLarge: boolean = false;

  constructor(
    private msg: NzMessageService,
    private http: HttpClient,
    private router: ActivatedRoute,
    private toastService: ToastService,
    private commonSer: CommonService,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private breakpointService: BreakpointService,
    private urlRedirectService: UrlRedirectService
  ) {

    this.subject.pipe(
      debounceTime(700)
    ).subscribe(() => {

      this.updateElementDetails();

    });

  }

  ngOnInit() {

    this.postAttachmentAPIURL = environment.getPostApi;
    this.removedAttachmentIds = [];
    this.router.params.subscribe(
      (params: Params) => {

        this.setParams(params);

      });

    this.breakpointService.isXSToLarge$.subscribe(res => {

      this.isXSToLarge = res;

    });

    this.breakpointService.breakpoints$.subscribe((res: any) => {

      this.isXtraLarge = res.xlarge ? true : false;

    });

  }



  ngOnChanges(changes: SimpleChanges) {

    this.attachmentTitle = this.hideElements ? 'Attachments' : 'Attachments/Elements';

    if (changes.attachmentDetails) {

      if (this.attachmentDetails == null || undefined) {

        this.attachmentDetails = new AttachmentDetails();

      }

      this.availableDivisions = [];
      this.defaultDivisions.forEach(x => this.availableDivisions.push(x.name));

      this.selectedDivisions = [];
      this.attachmentDetails.divisions?.forEach(r => {

        this.selectedDivisions.push(r.name);

      });

      this.availableShows = [];
      this.defaultShows.forEach(x => this.availableShows.push(x.name));

      this.selectedShows = [];
      this.attachmentDetails.shows?.forEach(r => {

        this.selectedShows.push(r.name);

      });

    }

    if (changes.isAttachmentDisabled) {

      this.disbaleUpload = (this.isAttachmentDisabled || this.isAttachmentDisabled === undefined ||
        this.isAttachmentDisabled === null) ? true : false;
      if (this.isAttachmentDisabled === undefined) {

        this.disbaleUpload = true;

      }

    }
    if (changes.attachments) {

      this.bindAttachments();

    }

  }

  setParams(params) {

    if (params && params.postId) {

      const id = params.postId;

      this.postId = id.substr(1);
      this.isNewPost = false;

    } else {

      this.isNewPost = true;
      this.isAttachmentsBinded = true;
      // this.fileList = [];
    }

  }

  bindAttachments() {

    const dummy = [];

    if (this.attachments && this.attachments.length) {

      this.fileList = [];
      this.attachments.forEach(element => {

        if (element.attachmentId !== '') {

          dummy.push({
            uid: element.attachmentId,
            name: element.attachmentName,
            filename: element.attachmentName,
            url: element.attachmentPath && this.urlRedirectService.getRedirectAPIURL(element.attachmentPath) + `?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`, // element.attachmentBinaries
            status: element.status,
            size: element.size,
          });
          this.isAttachmentsBinded = true;

        }

      });
      this.fileList = [...dummy];

    }

  }

  updateList(item, event, index) {
    item.status = event.status;
    item.percent = event.percent;
    this.fileList[index] = item;
    this.fileList = [...this.fileList];
  }

  showElements() {

    //this.drop = !this.drop;
  }

  handleChange(event) {

    if (this.attachments === null || this.attachments === undefined) {

      this.attachments = [];

    }
    if (event.type === 'removed' && event.file.uid) {

      let index = -1;

      // Created
      if (event.file.response) {

        index = this.attachments.findIndex((attachment) => attachment.attachmentId === event.file.response.attachmentId);

      } else {

        // Edited
        index = this.attachments.findIndex((attachment) => attachment.attachmentId === event.file.uid);

      }

      if (index >= 0) {

        this.attachments.splice(index, 1);
        this.attachmentsUpdated.emit({ attachments: this.attachments, isDeleted: true });

      }

    }

  }

  calculateUploadSize(currentAttachmetSize) {

    let totalSize = 0;

    this.fileList.forEach((file, _index) => {

      if (file.size && file.status !== 'error') {

        totalSize += file.size;

      }

    });
    if (((totalSize + currentAttachmetSize) / 1000000) > 307.2 && !this.toastFlag) { // 307.2

      this.toastFlag = true;
      return false;

    } else {

      return true;

    }

  }

  calculateFileCount() {

    let totalCount = 0;

    this.fileList.forEach((file) => {

      if (file.size && file.status !== 'error') {

        totalCount++;

      }

    });
    if (totalCount >= 25) { // 25 file count

      this.toastFlag = true;
      return false;

    } else {

      return true;

    }

  }

  beforeUpload = (file: NzUploadFile): boolean => {

    if (!this.calculateUploadSize(file.size)) {

      setTimeout(() => {

        if (this.toastFlag) {

          this.toastService.createMessage('error', 'Maximum File Size Limit!');
          this.toastFlag = false;

        }

      }, 500);

    }
    if (!this.calculateFileCount()) {

      setTimeout(() => {

        if (this.toastFlag) {

          this.toastService.createMessage('error', 'Maximum File Upload Limit is 25!');
          this.toastFlag = false;

        }

      }, 500);

    }
    return (this.fileList.length < 25 && !this.toastFlag) ? true : false;

  };

  setMediaUploadHeaders = (_file: NzUploadFile) => {

    return {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    };

  };

  customReq = (item: NzUploadXHRArgs) => {
    this.eventUpdater.emit({ uploadItem: item });
  };

  onChange(_event, _from?) {

    this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });
    this.postEdited.emit({});
  }

  onCreditChange(value) {

    if (value === 'yes') {

      if (this.attachmentDetails.creditName === '' || this.attachmentDetails.creditName === null) {

        this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: false });
        return;

      }

    } else if (value === 'no') {

      this.attachmentDetails.creditName = '';
      this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

    } else {

      this.attachmentDetails.isMandatoryCredit = '';
      this.attachmentDetails.creditName = '';
      this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

    }

  }

  onCreditNameChange(creditName) {

    this.subject.next(creditName);
    this.attachmentDetails.creditName = creditName;
    this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

  }

  updateElementDetails() {

    if (this.attachmentDetails.isMandatoryCredit === 'yes') {

      if (this.attachmentDetails.creditName === '' || this.attachmentDetails.creditName === null) {

        this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: false });
        return;

      }

    } else if (this.attachmentDetails.isMandatoryCredit === 'no') {

      this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

    } else {

      this.attachmentDetailsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

    }

  }

  showIcons = {
    showPreviewIcon: true,
    showDownloadIcon: false,
    showRemoveIcon: true
  };

  materialsClearedForChange(materialsClearedFor) {

    this.attachmentDetails.clearanceStatus = materialsClearedFor;

    this.selectedDivisions = [];
    this.selectedShows = [];

    if (materialsClearedFor != '') {

      if (materialsClearedFor == 'yes') {

        this.materialsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: true });

      } else {

        this.attachmentDetails.divisions = [];
        this.attachmentDetails.shows = [];
        this.materialsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: false });

      }

    }

  }

  isDivisionNotSelected(value: any): boolean {

    return this.selectedDivisions.indexOf(value) === -1;

  }

  isShowNotSelected(value: any): boolean {

    return this.selectedShows.indexOf(value) === -1;

  }

  filterAvailableDivisions() {

    this.selectedDivisions.forEach(x => {

      this.availableDivisions = this.defaultDivisions.filter((e) => e !== x);

    });

  }

  filterAvailableShows() {

    this.selectedShows.forEach(x => {

      this.availableShows = this.defaultShows.filter((e) => e !== x);

    });

  }

  changeInDivisions(_event) {

    this.attachmentDetails.divisions = [];
    this.selectedDivisions.forEach(x => {

      const selectedItem = this.defaultDivisions.filter((e) => e.name.toLowerCase() === x.toLowerCase());

      if (selectedItem.length > 0) {

        this.attachmentDetails.divisions.push({ id: selectedItem[0].id, name: selectedItem[0].name });

      } else {

        this.selectedDivisions = this.selectedDivisions.filter(item => item !== x);

      }

    });

    const valid = (this.selectedDivisions.length != 0 || this.selectedShows.length != 0) ? true : false;

    this.materialsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: valid });

  }

  isNotSelectedDivisions(value: string): boolean {

    return this.selectedDivisions.indexOf(value) === -1;

  }

  changeInShows(_event) {

    this.attachmentDetails.shows = [];
    this.selectedShows.forEach(x => {

      const selectedItem = this.defaultShows.filter((e) => e.name.toLowerCase() === x.toLowerCase());

      if (selectedItem.length > 0) {

        this.attachmentDetails.shows.push(selectedItem[0]);

      } else {

        this.selectedShows = this.selectedShows.filter(item => item !== x);

      }

    });
    const valid = (this.selectedDivisions.length != 0 || this.selectedShows.length != 0) ? true : false;

    this.materialsUpdated.emit({ attachmentDetails: this.attachmentDetails, isValid: valid });

  }

  isNotSelectedShows(value: string): boolean {

    return this.selectedShows.indexOf(value) === -1;

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  get breakPoints(): any {

    return this.breakpointService.breakpoints.value;

  }

}


