import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Filters, Option } from '@models/ncx/filters';
import { LimitedLicense } from '@models/ncx/limited-license';
import { Post, PostVersions } from '@models/ncx/post';
import { PostReportable } from '@models/ncx/post-reportable';
import { StandardsGuidance } from '@models/ncx/standards-guidance';
import { PERMISSIONS } from '@models/users';
import { CommonService } from '@services/common-service';
import { PermissionsService } from '@services/profile/permissions.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { WebSocketService } from '@services/websocket.service';
import * as moment from 'moment/moment';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-posts-metadata',
  templateUrl: './posts-metadata.component.html',
  styleUrls: ['./posts-metadata.component.scss']
})
export class PostsMetadataComponent implements OnChanges {

  // Post information
  @Input() postDetails: Post = {} as Post;

  // List of filters (checkboxes) to choose from
  @Input() filterList: Filters = {} as Filters;

  // All related topics to choose from (edit-mode only)
  @Input() relatedTopics: string[] = [];

  // Is the Post in edit or view-mode?  Logic will change depending on the mode.
  @Input() viewMode: boolean = true;

  // When the Post is updated in view-only mode
  @Output() onPostUpdated: EventEmitter<Post> = new EventEmitter<Post>();

  // When labels are selected/de-selected
  @Output() onLabelsSelected: EventEmitter<{
    labels: string[],
    selected: string,
    list: 'markAsType' | 'statusType'
  }> = new EventEmitter<{
    labels: [],
    selected: '',
    list: 'markAsType'
  }>();

  // When Topics are updated
  @Output() onTopicsSelected: EventEmitter<{ relatedTopics: { topicId: string, topicName: string }[] }> = new EventEmitter<{ relatedTopics: [] }>();

  // When Tags are updated
  @Output() onTagsSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  // When checkbox to generate tags automatically is selected/de-selected
  @Output() onGenerateTags: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Loading indicator
  isLoading: boolean = false;

  // User who is logged in
  userId: number = 0;
  
  @Output() postEdited: EventEmitter<any> = new EventEmitter<any>();

  // Visibilty of modals
  modals: { visible: { [key: string]: boolean } } = {
    visible: {
      REPORTABLE: false,
      STANDARDS: false,
      'LIMITED LICENSE': false
    }
  };

  constructor(
    private cService: CommonService,
    private modalService: NzModalService,
    private permissions: PermissionsService,
    private router: Router,
    private toastService: ToastService
  ) {

    this.userId = +localStorage.getItem('userId');

  }

  async ngOnChanges(changes: SimpleChanges) {

    if ('postDetails' in changes) {

      if (JSON.stringify(changes.postDetails.currentValue) !== JSON.stringify(changes.postDetails.previousValue)) {

        this.postDetails = changes.postDetails.currentValue || {} as Post;
        this.initSelectedOptions();

      }

    }

    if ('filterList' in changes) {

      if (JSON.stringify(changes.filterList.currentValue) !== JSON.stringify(changes.filterList.previousValue)) {

        this.initSelectedOptions();

      }

    }

  }

  /**
   * Select Legal and Editorial options based on the Post information
   *
   */
  initSelectedOptions() {

    // Editorial checkboxes
    if (Array.isArray(this.filterList?.markAsType)) {

      this.filterList.markAsType.forEach((opt: Option) => opt.checked = (this.postDetails?.editorialStatus || []).includes(String(opt.value)));

    }

    // Legal & R/C checkboxes
    if (Array.isArray(this.filterList?.statusType)) {

      this.filterList.statusType.forEach((opt: Option) => opt.checked = (this.postDetails?.postLegal || []).includes(String(opt.value)));

    }

  }

  /**
   * When user selects/de-selects an option from Legal or Editorial checkboxes
   *
   * @param value selected value
   * @param isChecked was it checked?
   * @param list key indicating which list from Filters the selected value belongs to
   *
   */
  async onSelect(value: string, isChecked: boolean, list: 'markAsType' | 'statusType') {
    this.postEdited.emit({});

    // When in Edit mode, simply tell the parent component when a checkbox is
    // selected/de-selected and bypass any other logic
    if (!this.viewMode) {

      switch (list) {

      case 'markAsType':
        this.onLabelsSelected.emit({

          // all selected labels
          labels: this.selectedEditorialOptions,

          // the value that was just selected/de-selected
          selected: value,

          // the list that the value came from
          list
        });
        return;
      case 'statusType':
        this.onLabelsSelected.emit({
          labels: this.selectedLegalOptions,
          selected: value,
          list
        });
        break;

      }
      return;

    }

    try {

      // Refresh the Post information
      const currentPost = await this.getPost();

      this.postDetails = currentPost;

    } catch (error) {

      this.toastService.createMessage('error', 'Unable to load Post');

      return;

    }

    // If the Post is locked, do nothing else
    if (this.isLocked) {

      this.toastService.createMessage('warning', 'You cannot edit a locked Post');

      this.selectionCancelled(value, list, isChecked);

      return;

    }

    if (this.viewMode) {

      if (await this.getlivePostAndRefresh()) {

        return;

      }

    } else {

      // If the Post was archived
      if (this.isArchived) {

        this.toastService.createMessage('warning', 'You cannot edit an archived Post');

        this.selectionCancelled(value, list, isChecked);

        return;

      }

    }

    // If the Post has multiple versions, make sure the user is on the latest post
    // if (this.hasMultipleVersions) {

    //   try {

    //     // Check if there is a newer post that the user should be viewing
    //     const versions = await this.getPostVersions(this.postDetails.postVersionGroupId);
    //     const newerPost = this.hasNewerPost(versions);

    //     if (newerPost) {
    //       this.showNewerPostWarning(newerPost);
    //       return;
    //     }

    //   } catch (error) {

    //     this.toastService.createMessage('error', 'Unable to load previous Post versions');

    //     return;

    //   }

    // }

    // If the Post is deleted, re-direct the user
    if (this.isDeleted) {

      this.toastService.createMessage('error', 'This Post does not exist. Reirecting to the Stories Dashboard.');

      setTimeout(() => {

        this.router.navigate(['ncx/stories-dashboard']);

      }, 500);

      return;

    }

    // If the previous checks pass, then ask the user to confirm the selection
    // and optionally show the correct modal
    if (isChecked) {

      // Check for any conflicting label logic
      if (this.hasConflictingLabels(value)) {

        this.showConflictingLabelsModal(value);
        return;

      }

      // ... make sure the user confirms the selection
      const didConfirm = await this.confirmSelection();

      // ... if confirmed, show a modal to get more information, if necessary
      if (didConfirm) {

        switch (value) {

        case 'LIMITED LICENSE':
          this.modals.visible['LIMITED LICENSE'] = true;
          break;

        case 'STANDARDS':
          this.modals.visible.STANDARDS = true;
          break;

        case 'REPORTABLE':
          this.modals.visible.REPORTABLE = true;
          break;

        default:
          this.updatePost(list);
          break;

        }

      } else {

        // ... if not confirmed, simply de-select the checked option
        this.selectionCancelled(value, list);

      }

    } else {

      // .... if the user un-checked the box, update the Post
      this.updatePost(list);

    }

  }

  getlivePostAndRefresh(): Promise<boolean> {

    return new Promise((resolve, reject) => {

      let livePost = {} as Post;

      let livePostDetailsTime = '';

      this.cService.serviceRequestCommon('get', environment.getPostApi + '/', this.postDetails.postVersionGroupId + '/liveVersion').
        subscribe((response: any) => {

          for (let i = 0; i < response.posts.length; i++) {

            if (response.posts[i].postVersionState === 'Live') {

              livePostDetailsTime = response.posts[i].updateDateTime;
              livePost = response.posts[i];
              break;

            }

          }
          const livePostTime = moment.utc(livePostDetailsTime).local().format('YYYY-MM-DD HH:mm:ss');

          const currentPostTime = moment.utc(this.postDetails.updateDateTime).local().format('YYYY-MM-DD HH:mm:ss');

          const livePostDate = new Date(livePostTime);

          const draftPostDate = new Date(currentPostTime);

          if (livePostDate > draftPostDate) {

            this.showNewerPostWarning(livePost);
            resolve(true);

          }
          resolve(false);

        }, (err) => {

          this.toastService.createMessage('error', 'Error while fetching live post details');
          reject(err);

        });

    });

  }

  /**
   * Get the Post
   *
   */
  getPost(): Promise<Post> {

    return new Promise((resolve, reject) => {

      this.isLoading = true;

      this.cService.serviceRequestCommon('get', environment.getPostApi, `/${this.postDetails.postId}`).subscribe({
        next: (res: any) => {

          const post = res as Post;

          resolve(post || {} as Post);

        },
        error: (error: any) => {

          console.error('getPost', error);
          reject(error);

        }
      }).add(() => {

        this.isLoading = false;

      });

    });

  }

  /**
   * Get all versions of a Post
   *
   */
  getPostVersions(postVersionGroupId: number): Promise<Post[]> {

    return new Promise((resolve, reject) => {

      this.isLoading = true;

      this.cService.serviceRequestCommon('get', environment.getPostApi + '/', postVersionGroupId + '/version').
        subscribe({
          next: (res: any) => {

            const versions: PostVersions = res as PostVersions;

            resolve(versions.posts || []);

          },
          error: (error: any) => {

            console.error('hasNewerPost', error);
            reject();

          }
        }).add(() => {

          this.isLoading = false;

        });

    });

  }

  /**
   * Determine if there is a newer version among Posts
   *
   */
  hasNewerPost(versions: Post[]): Post | null {

    // Find the 'Live' post among all post versions
    const livePost = versions.find((post: Post) => post.postVersionState === 'Live');

    if (livePost) {

      const livePostDetailsTime = livePost.updateDateTime;

      const livePostTime = moment.utc(livePostDetailsTime).local().format('YYYY-MM-DD HH:mm:ss');

      const draftPostTime = moment.utc(this.postDetails.updateDateTime).local().format('YYYY-MM-DD HH:mm:ss');

      const livePostDate = new Date(livePostTime);

      const draftPostDate = new Date(draftPostTime);

      const hasNewerPost = livePostDetailsTime && (livePostDate > draftPostDate || livePostTime > draftPostTime);

      console.log('Newer Post Found', livePost);

      return hasNewerPost ? livePost : null;

    } else {

      return null;

    }

  }

  /**
   * Warn user that a more recent post is available
   *
   */
  showNewerPostWarning(post: Post) {

    this.modalService.info({
      nzTitle: 'A newer version of post is already available.<br/>Click OK to continue with the newer version and make changes again',
      nzOkText: 'OK',
      nzOnOk: () => {

        this.router.navigate(['ncx/view-post/:' + post.postId]);

      }
    });

  }

  /**
   * Warn user when there is a conflict between selected labels
   *
   */
  showConflictingLabelsModal(label: string) {

    switch (label) {

    case 'PUBLISHED/AIRED':
      this.modalService.warning({
        nzTitle: 'Cannot Add Label',
        nzContent: 'R&C requests that the label Published/Aired cannot be used in combination with the labels Needs Licensing, Copyright Risk or Do Not Use',
        nzOnOk: () => {

          this.selectionCancelled('PUBLISHED/AIRED', 'markAsType');

        }
      });
      break;

    }

  }

  /**
   * Show Modal to let the user confirm the selection
   *
   */
  async confirmSelection(): Promise<boolean> {

    return new Promise(resolve => {

      this.modalService.confirm({
        nzTitle: '<strong>Are you sure?</strong><br/><br/>Applying this label will generate e-mails to all users who follow this alert type and/or story.',
        nzOkText: 'Submit',
        nzOkDanger: true,
        nzCancelText: 'Cancel',
        nzOnOk: () => {

          resolve(true);

        },
        nzOnCancel: () => {

          resolve(false);

        }
      });

    });

  }

  /**
   * De-select the selected value from a given list
   *
   */
  selectionCancelled(selectedValue: string, list: 'markAsType' | 'statusType', isChecked: boolean = true) {

    const item = this.filterList[list].find((opt: Option) => opt.value === selectedValue);

    if (item) {

      item.checked = !isChecked;
      console.log('Selection Cancelled', selectedValue);

    }

  }

  /**
   * When any of the "Additional Information" modals are cancelled
   *
   */
  modalCancelled(value: 'LIMITED LICENSE' | 'REPORTABLE' | 'STANDARDS') {

    this.modals.visible[value] = false;

    if (value === 'LIMITED LICENSE') {

      this.selectionCancelled(value, 'statusType');

    }

    if (value === 'REPORTABLE' || value === 'STANDARDS') {

      this.selectionCancelled(value, 'markAsType');

    }

  }

  /**
   * Update Post when a simple selection is made (not requiring an 'additional information' modal)
   *
   */
  updatePost(list: 'markAsType' | 'statusType') {

    // All of the available options of the selected list
    const options = this.filterList[list];

    // All of the selected values from the options
    const selectedValues = options.filter((opt: Option) => opt.checked).map((opt: Option) => opt.value);

    const { isPostPublished, postVersionGroupId, postVersionNumber } = this.postDetails;

    const payload: { [key: string]: any } = {
      postId: this.postDetails.postId,
      isPostPublished,
      postVersionGroupId,
      postVersionNumber
    };

    // We update the playload according to the list that was changed
    switch (list) {

    case 'markAsType':
      payload.editorialStatus = selectedValues;
      break;

    case 'statusType':
      payload.postLegal = selectedValues;
      break;

    }

    // EXCEPTIONS
    // Check for label conflicts...
    if (this.hasConflictingLabels('PUBLISHED/AIRED')) {

      // De-selected the label
      this.selectionCancelled('PUBLISHED/AIRED', 'markAsType');

      // Add the updated editorial list to the payload
      payload.editorialStatus = this.selectedEditorialOptions;

    }

    // Finally, make the API call to update the Post
    this.isLoading = true;

    this.cService.serviceRequestCommon('put', `${environment.getPostApi}/markAsStatus/${this.postDetails.postId}`, '', payload).subscribe({
      next: (post: any) => {

        // Update the Post information
        this.postDetails = post as Post;

        // Re-select the selected options (in case any additional changes were made)
        this.initSelectedOptions();

        // Send the updated Post to the parent component
        this.onPostUpdated.emit(post);

        this.toastService.createMessage('success', 'The Post has been successfully updated');

      },
      error: (error: any) => {

        console.error('Update Post', { payload, error });

        this.toastService.createMessage('error', 'Error while saving Post. Please try again.');

      }
    }).add(() => {

      this.isLoading = false;

    });

  }

  /**
   * When topics are updated/selected
   *
   */
  updateTopics(topics: { relatedTopics: { topicId: string, topicName: string }[] }) {

    this.onTopicsSelected.emit(topics);

  }

  /**
   * When tags are updated/selected
   *
   */
  updateTags(tags: string[]) {

    this.onTagsSelected.emit(tags);

  }

  /**
   * Generate tags select box
   *
   */
  generateTags(isChecked: boolean) {

    this.onGenerateTags.emit(isChecked);

  }

  /**
   * Update Post with specific information from the 'Reportable' modal
   *
   */
  updatePostForReportable(data: PostReportable) {

    console.log('Save Reportable Post Modal: ', data);

    const { postVersionGroupId, postVersionNumber, postContent, editorialStatus, postVersionState } = this.postDetails;

    const payload = {
      postId: this.postDetails.postId,

      // must be 'true' because the post would have been published
      isPostPublished: true,
      postVersionGroupId,
      postVersionNumber,
      postContent,
      postVersionState,
      editorialStatus: Array.isArray(editorialStatus) ? editorialStatus : [],
      reportableApprover: {
        isOfficial: data.isOfficial,
        seniorApprovalName: data.seniorApprovalName,
        additionalNotes: data.additionalNotes
      }
    };

    if (!payload.editorialStatus.includes('REPORTABLE')) {

      payload.editorialStatus.push('REPORTABLE');

    }

    this.isLoading = true;

    this.cService.serviceRequestCommon('put', `${environment.getPostApi}/markAsStatus/${this.postDetails.postId}`, '', payload).subscribe({

      next: (post: any) => {

        // Update the Post information
        this.postDetails = post as Post;

        // Re-select the selected options (in case any additional changes were made)
        this.initSelectedOptions();

        // Send the updated Post to the parent component
        this.onPostUpdated.emit(post);

        // Close the modal
        this.modals.visible.REPORTABLE = false;

        this.toastService.createMessage('success', 'The Post has been successfully updated');

      },

      error: (error: any) => {

        console.error('Update Post for Reportable', error);

        this.toastService.createMessage('error', 'Error while saving Post. Please try again.');

      }

    }).add(() => {

      this.isLoading = false;

    });

  }

  /**
   * Update Post with additional information from the 'Limited License' modal
   *
   */
  updatePostForLimitedLicense(data: LimitedLicense) {

    console.log('Save Limited License Modal: ', data);

    const { postVersionGroupId, postVersionNumber, postLegal } = this.postDetails;

    const payload = {
      postId: this.postDetails.postId,

      // must be 'true' because the post would have been published
      isPostPublished: true,
      postVersionGroupId,
      postVersionNumber,
      postLegal: Array.isArray(postLegal) ? postLegal : [],
      limitedLicenseApprover: {
        additionalNotes: data.additionalNotes
      }
    };

    if (!payload.postLegal.includes('LIMITED LICENSE')) {

      payload.postLegal.push('LIMITED LICENSE');

    }

    this.isLoading = true;

    this.cService.serviceRequestCommon('put', `${environment.getPostApi}/markAsStatus/${this.postDetails.postId}`, '', payload).subscribe({

      next: (post: any) => {

        // Update the Post information
        this.postDetails = post as Post;

        // Re-select the selected options (in case any additional changes were made)
        this.initSelectedOptions();

        // Send the updated Post to the parent component
        this.onPostUpdated.emit(post);

        // Close the modal
        this.modals.visible['LIMITED LICENSE'] = false;

        this.toastService.createMessage('success', 'The Post has been successfully updated');

      },

      error: (error: any) => {

        console.error('Update Post for Limited License', error);

        this.toastService.createMessage('error', 'Error while saving Post. Please try again.');

      }

    }).add(() => {

      this.isLoading = false;

    });

  }

  /**
   * Update Post with additional information from the 'Standards Guidance' modal
   *
   */
  updatePostForStandardsGuidance(data: StandardsGuidance) {

    console.log('Save Standards Guidance Modal: ', data);

    const {
      postVersionGroupId,
      postVersionNumber,
      editorialStatus
    } = this.postDetails;

    const payload = {
      postId: this.postDetails.postId,

      // must be 'true' because the post would have been published
      isPostPublished: true,
      postVersionGroupId,
      postVersionNumber,
      editorialStatus: Array.isArray(editorialStatus) ? editorialStatus : [],
      standardsGuidance: {
        notes: data.notes,
        plainNotes: data.notes
      }
    };

    if (!payload.editorialStatus.includes('STANDARDS')) {

      payload.editorialStatus.push('STANDARDS');

    }

    this.isLoading = true;

    this.cService.serviceRequestCommon('put', `${environment.getPostApi}/markAsStatus/${this.postDetails.postId}`, '', payload).subscribe({

      next: (post: any) => {

        // Update the Post information
        this.postDetails = post as Post;

        // Re-select the selected options (in case any additional changes were made)
        this.initSelectedOptions();

        // Send the updated Post to the parent component
        this.onPostUpdated.emit(post);

        // Close the modal
        this.modals.visible.STANDARDS = false;

        this.toastService.createMessage('success', 'The Post has been successfully updated');

      },

      error: (error: any) => {

        console.error('Update Post for Standards Guidance', error);

        this.toastService.createMessage('error', 'Error while saving Post. Please try again.');

      }

    }).add(() => {

      this.isLoading = false;

    });

  }

  /**
   * Logic for determining when certain labels can't be checked together
   *
   */
  hasConflictingLabels(label: string): boolean {

    const selectedLegalOptions = this.selectedLegalOptions;

    const selectedEditorialOptions = this.selectedEditorialOptions;

    switch (label) {

    // When Published/Aired is selected, certain Legal/RC Labels can't be selected, too
    case 'PUBLISHED/AIRED':
      return !!(selectedEditorialOptions.includes(label) &&
          selectedLegalOptions.join(' ').match(/\b(NEEDS LICENSING|COPYRIGHT RISK|DO NOT USE)\b/));

    default:
      return false;

    }

  }

  /**
   * Permissions to change/edit certain labels
   *
   */
  canViewLabel(label: string): boolean {

    return this.permissions.canAddPostLabel(label);

  }

  /**
   * Does the user have permission to add/remove Editorial and R&C labels
   *
   */
  get canChangeLabels(): boolean {

    return this.permissions.hasPermissionTo(PERMISSIONS.ADD_POST_LABEL);

  }

  /**
   * Get all selected Ediorial options
   *
   */
  get selectedEditorialOptions(): string[] {

    return this.filterList.markAsType.filter((opt: Option) => opt.checked).map((opt: Option) => String(opt.value));

  }

  /**
   * Get all selected Legal/RC options
   *
   */
  get selectedLegalOptions(): string[] {

    return this.filterList.statusType.filter((opt: Option) => opt.checked).map((opt: Option) => String(opt.value));

  }

  /**
   * Is the Post deleted
   *
   */
  get isDeleted(): boolean {

    return this.postDetails?.isDeleted;

  }

  /**
   * Is the Post Archived
   *
   */
  get isArchived(): boolean {

    return this.postDetails?.postVersionState === 'Archived';

  }

  /**
   * Does the Post have multiple versions
   *
   */
  get hasMultipleVersions(): boolean {

    return this.postDetails?.postVersionNumber > 1;

  }

  /**
   * Does the Post have topics
   *
   */
  get hasTopics(): boolean {

    return Object.keys(this.postDetails?.topics || {}).length > 0;

  }

  /**
   * Does the Post have tags
   *
   */
  get hasTags(): boolean {

    return Array.isArray(this.postDetails?.postContentTags) && this.postDetails.postContentTags.length > 0;

  }

  /**
   * Is the Post locked by another user
   *
   */
  get isLocked(): boolean {

    return this.postDetails?.locked && this.userId !== this.postDetails?.lockedByUser?.userId;

  }

}
