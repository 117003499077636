<div class="banner">
   <div class="info">
      <i
         nz-icon
         class="icon"
         nzType="lock"
         nzTheme="fill"></i>

      <p>
         Post Locked: This post is currently being edited by
         <button
            nz-popover
            class="name"
            [nzPopoverContent]="profileCardTemplate"
            [nzPopoverPlacement]="'right'">
            {{ name }}
         </button>
      </p>
   </div>

   <!-- UNLOCK BUTTON -->
   <button
      nz-button
      nzType="default"
      nzSize="small"
      class="unlock"
      (click)="unlockEvent()"
      *ngIf="unlock">
      Unlock Post
   </button>
</div>

<ng-template #profileCardTemplate>
   <app-profile-overlay [profileData]="postDetails.lockedByUser"></app-profile-overlay>
</ng-template>
