import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LightboxAttachment } from '@models/ncx/lightbox';
import { PostAttachment } from '@models/ncx/post';
import { UrlRedirectService } from '@services/url-redirect.service';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { GoogleAnalyticsEventService } from 'src/app/services/google-analytics-events.service.service';

@Component({
  selector: 'app-view-thumbnails',
  templateUrl: './view-thumbnails.component.html',
  styleUrls: ['./view-thumbnails.component.scss']
})
export class ViewThumbnailsComponent implements OnInit {

  @Input() viewAttachments: PostAttachment[] = [];

  @Input() postDetails;

  @Input() isStoryLanding;

  @Input() postIndex;

  @Input() storyId;

  showLightBox: any = false;

  indexToOpen = 0;

  // Array of all attachments from parent
  attachments: LightboxAttachment[] = [];

  // All extensions that make up images or video
  imgExtn = /jpe?g|jfif|png|bmp/;
  videoExtn = /mov|mp4|mpg|3gp|m4v|mkv|webm/;
  fileExtn = /pdf|doc|docx|ppt|pptx|xls|xlsx|txt|rtf/;
  thumpVideoExtn = /mov|mp4|3gp|m4v|mkv|webm|flv|avi|mpeg|wmv|asf/;

  @ViewChild(NzCarouselComponent) carousel: NzCarouselComponent;

  constructor(
    private gaService: GoogleAnalyticsEventService,
    private urlRedirectService: UrlRedirectService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    this.buildAttachments();

  }
  getUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  /**
   * Build the album of attachments which is all of the attachments converted into
   * Lightbox images
   *
   */
  buildAttachments() {

    if (this.postDetails && this.viewAttachments) {

      this.attachments = this.viewAttachments.map((attachment: PostAttachment) => {

        const { attachmentId, attachmentName, attachmentPreview } = attachment;

        const extension = String(attachment.attachmentPath).split('.').pop().toLowerCase();

        const isImage = !!extension.match(this.imgExtn);

        const isVideo = !!extension.match(this.videoExtn);
        const isFile = !!extension.match(this.fileExtn);
        const isthumpVideo = !!extension.match(this.thumpVideoExtn);

        const lightboxAttachment: LightboxAttachment = {

          // Id of attachment
          attachmentID: attachmentId,

          // Name of full-size version of file
          attachmentName,

          // Name of preview-size version of file
          attachmentPreviewName: attachmentPreview ? attachmentPreview : '',

          // URL of full-size version of file
          fileUrl: this.imageURL(attachment),

          // URL of preview-size version of file
          previewUrl: this.imageURL(attachment, 'preview'),

          thumpnail: this.imageThumpnailURL(attachment, 'thumpnail'),

          // File extension
          extension,

          // Is this an image?
          isImage,

          // Is this a video?
          isVideo,
          isFile,
          isthumpVideo
        };

        return lightboxAttachment;

      });

    }

    console.log('View Thumbnails (buildAttachments)', { album: this.attachments, attachments: this.viewAttachments });

  }
  imageThumpnailURL(post: PostAttachment, type: string = 'full'): string {

    let url = type === 'thumpnail' && post.attachmentPreviewPath ? post.attachmentPreviewPath : "";

    if (url && !url.includes('?ncxjwttoken=')) {
      url = `${url}?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`;
    }
    console.log("attachmentPreviewPath: " + url)
    return url;

  }
  openlightbox(index: number) {

    if (this.isStoryLanding) {

      this.gaService.trackElementClickAction('Story Landing Clicks', this.storyId);

    }

    this.showLightBox = true;

    this.indexToOpen = index;

  }

  openvideolightbox(index: number, url: any, extension: any) {
    let supported = !!extension.match(this.videoExtn);
    if (supported) {
      if (this.isStoryLanding) {
        this.gaService.trackElementClickAction('Story Landing Clicks', this.storyId);
      }
      this.indexToOpen = index;
      this.showLightBox = true;
    }
    else {
      window.open(this.urlRedirectService.getRedirectURL(url), '_blank');
    }
  }

  /**
   * Close modal
   *
   */
  destroyModalPopup(isClosed: boolean) {

    if (isClosed) {

      this.showLightBox = false;

    }

  }

  /**
   * Image Url
   *
   */
  imageURL(post: PostAttachment, type: string = 'full'): string {

    let url = type === 'preview' && post.attachmentPreview && post.attachmentPreviewPath ? this.urlRedirectService.getRedirectAPIURL(post.attachmentPreviewPath) : this.urlRedirectService.getRedirectAPIURL(post.attachmentPath);

    if (url && !url.includes('?ncxjwttoken=')) {

      url = `${url}?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`;

    }
    url = `${url}&attachmentName=${post.attachmentName}`
    return url;

  }

  pre() {

    this.carousel.pre();

  }

  next() {

    this.carousel.next();

  }

}
