import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-tags-new',
  templateUrl: './tags-new.component.html',
  styleUrls: ['./tags-new.component.scss']
})
export class TagsNewComponent implements OnInit, OnChanges {

  inputVisible = false;

  inputValue = '';

  checked = true;

  smartLogicApi;

  smartBody = {
    title: '',
    description: ''
  };

  isLoaded: boolean;

  @Input() tagContent = [];

  @Input() title;

  @Input() description;

  @Input() isGenerateTags;

  @Input() viewMode = false;

  @Output() tagsUpdated: EventEmitter<any> = new EventEmitter<any>();

  @Output() generateFlag: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputElement') inputElement: ElementRef;

  constructor(private cService: CommonService, private toastService: ToastService) { }

  ngOnInit() {

    if (this.tagContent && this.tagContent.length > 0) {

      this.checked = false;

    }
    this.smartLogicApi = environment.smartLogicApi;
    this.isLoaded = true;

  }

  ngOnChanges() {

    if (this.tagContent && this.tagContent.length > 0) {

      this.isGenerateTags = false;

    }

    // console.log('Story title and Description', this.title, this.description);

  }

  toggleGenerateFlag(_type) {

    this.generateFlag.emit(this.isGenerateTags);

  }

  handleClose(removedTag: object): void {

    this.tagContent = this.tagContent.filter(tag => tag !== removedTag);
    this.tagsUpdated.emit(this.tagContent);
    if (this.tagContent.length === 0) {

      this.isGenerateTags = true;
      this.generateFlag.emit(this.isGenerateTags);
    }

  }

  sliceTagName(tag: string): string {

    const isLongTag = tag.length > 20;

    return isLongTag ? `${tag.slice(0, 20)}...` : tag;

  }

  showInput(): void {

    this.inputVisible = true;
    setTimeout(() => {

      this.inputElement.nativeElement.focus();

    }, 10);

  }

  handleInputConfirm(): void {

    if (this.tagContent === null || this.tagContent === undefined) {

      this.tagContent = [];

    }
    if (this.inputValue.toUpperCase() && this.tagContent.indexOf(this.inputValue.toUpperCase()) === -1) {

      this.tagContent = [...this.tagContent, this.inputValue.toLocaleUpperCase()];

    }
    this.tagsUpdated.emit(this.tagContent);
    this.inputValue = '';
    this.inputVisible = false;
    this.isGenerateTags = false;

  }

  clickedGenerateTags = false;

  generateTags() {

    this.clickedGenerateTags = true;

    // console.log('Tags Generation', this.title, this.description);
    if (this.title === '' || this.title === null || this.title === undefined) {

      this.toastService.createMessage('warning', 'Please Provide the Title');
      return;

    }
    if (this.description === '' || this.description === null || this.description === undefined) {

      this.toastService.createMessage('warning', 'Please provide content to Generate Tags');
      return;

    }

    // this.smartBody.isGenerateTags = this.isGenerateTags;
    this.smartBody.title = this.title;
    this.smartBody.description = this.description;
    console.log('data', this.smartBody);
    this.isLoaded = false;
    this.cService.serviceRequestCommon('post', this.smartLogicApi, '', this.smartBody).
      subscribe((val: any) => {

        console.log(`SUCCESS: ${this.smartLogicApi}`, val);
        this.addGenerateTags(val);
        this.isLoaded = true;

        // this.tagContent = [...this.tagContent, val];
        console.log('Total values', val);

        // this.toastService.createMessage('success', 'The Tags have been generated');

      }, () => {

        this.toastService.createMessage('error', 'Error While generating tags please try again');
        this.isLoaded = true;

      }
      );

  }

  addGenerateTags(generatedTags) {

    // generatedTags.forEach(element => {
    //     // this.inputValue = element;
    //     // this.handleInputConfirm();
    // });
    if (this.tagContent === null || this.tagContent === undefined) {

      this.tagContent = [];

    }
    let totalTags = [...this.tagContent, ...generatedTags];

    totalTags = [...totalTags].reduce(
      (acc, val) =>
        acc.includes(val.toUpperCase()) || acc.includes(val.toLowerCase())
          ? acc : [...acc, val], []
    );

    totalTags = totalTags.reduce((previousValue, currentValue) => {

      if (previousValue.indexOf(currentValue) === -1) {

        previousValue.push(currentValue);

      }
      return previousValue;

    }, []);

    this.tagContent = [...totalTags];
    this.tagsUpdated.emit(this.tagContent);
    console.log('Arrays', this.tagContent, generatedTags, ' -- ', totalTags);

  }

}
