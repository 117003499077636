<div
   *ngIf="!isLoaded"
   [ngClass]="{
      loader: true,
      app: loaderType === 'APP_LOADER',
      page: loaderType === 'PAGE_LOADER',
      section: loaderType === 'SECTION_LOADER',
   }"
   [attr.data-component]="'app/components/loader-component (' + loaderType + ')'">
   <nz-spin
      nzSimple
      [nzSize]="size"
      class="icon"></nz-spin>
</div>
