<!-- ************************* View Story Detail ************************** -->

<div
   *ngIf="isStoryLanding && !isFilter && !gdCollapsed && !ddCollapsed && !glCollapsed"
   [ngClass]="{ 'toggle-section': true, 'drawer': isDrawer }"
   [attr.data-component]="'ncx/toggle-section/toggle-section.component.html (Story Detail)'">
   <div style="padding: 16px 24px">
      <span class="storyTitle">Story Detail</span>
      <button
         class="close"
         (click)="close()">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
      </button>
   </div>

   <nz-divider style="margin: 0"></nz-divider>

   <nz-card [nzBordered]="!isDrawer">
      <div *ngIf="this.storyLandingDetails">
         <div class="storyStateCreated">
            <span>Story State</span>
            <div>{{ this.storyLandingDetails.storyState | titlecase }}</div>
         </div>
         <div class="storyPrivacyCreated">
            <span>Privacy</span>
            <div>{{ this.storyLandingDetails.storyAccess | titlecase }}</div>
         </div>
         <div class="storySlugCreated">
            <span>Creator</span>
            <div
               class="moreDetails"
               *ngIf="
                  this.storyLandingDetails &&
                  this.storyLandingDetails.createUser &&
                  this.storyLandingDetails.createUser.firstName
               ">
               <button
                  nz-popover
                  [nzPopoverContent]="profileCardTemplate"
                  [nzPopoverPlacement]="'left'"
                  *ngIf="storyLandingDetails.createUser.displayName.split(',').length > 1">
                  {{ storyLandingDetails.createUser.displayName.split(',')[1].trim() }}
                  {{ storyLandingDetails.createUser.displayName.split(',')[0] }}
               </button>
               <button
                  nz-popover
                  [nzPopoverContent]="profileCardTemplate"
                  [nzPopoverPlacement]="'left'"
                  *ngIf="storyLandingDetails.createUser.displayName.split(',').length === 1">
                  {{ storyLandingDetails.createUser.displayName.trim() }}
               </button>
               <ng-template #profileCardTemplate>
                  <app-profile-overlay [profileData]="storyLandingDetails.createUser"></app-profile-overlay>
               </ng-template>
            </div>
         </div>
         <div class="storySlugCreated">
            <span>Created</span>
            <div *ngIf="this.storyLandingDetails && this.storyLandingDetails.createDateTime">
               {{ utcToLocal(this.storyLandingDetails.createDateTime, 'DATE') | date: 'MMM dd yyyy' }},
               {{ utcToLocal(this.storyLandingDetails.createDateTime, 'TIME') }}
            </div>
         </div>
      </div>
   </nz-card>

   <nz-divider style="margin-top: 0"></nz-divider>

   <div class="extraFeatures">
      <a
         nz-button
         nzBlock="true"
         nzGhost="true"
         *ngIf="functionAbility.fa_add_post_via_email"
         href="{{ emailTo }}">
         <i
            nz-icon
            nzType="mail"
            nzTheme="fill"></i>
         <span>Add Post via Email</span>
      </a>
      <button
         nz-button
         nzBlock="true"
         nzGhost="true"
         (click)="openInfocenterResearch()">
         <i
            nz-icon
            nzType="question-circle"
            nzTheme="outline"></i>
         <span>Request Infocenter Research</span>
      </button>
   </div>
</div>

<!-- ************************* View Profile ************************** -->

<div
   *ngIf="isStoryFollowing && !gdCollapsed && !ddCollapsed && !glCollapsed"
   [ngClass]="{ 'toggle-section': true, 'drawer': isDrawer }"
   [attr.data-component]="'ncx/toggle-section/toggle-section.component.html (Profile)'">
   <app-loader-component
      [isLoaded]="isLoaded"
      loaderType="SECTION_LOADER"></app-loader-component>

   <div class="storyLayout">
      <nz-card class="card">
         <button class="close">
            <i
               nz-icon
               nzType="close"
               nzTheme="outline"
               (click)="close()"></i>
         </button>
         <div class="profileImage">
            <nz-avatar
               *ngIf="storyFollowingData.profilePictureURL !== '' && storyFollowingData.profilePictureURL !== null"
               [nzSize]="64"
               [nzSrc]="Common.formS3Link(storyFollowingData.profilePictureURL)"></nz-avatar>
            <nz-avatar
               *ngIf="
                  storyFollowingData.profilePictureURL === '' ||
                  storyFollowingData.profilePictureURL === null ||
                  undefined
               "
               [nzSize]="64"
               nzIcon="user"></nz-avatar>
            <div *ngIf="storyFollowingData">
               <div
                  *ngIf="storyFollowingData.name && storyFollowingData.name.preferredName.split(',').length > 1"
                  class="profileName">
                  {{ storyFollowingData.name.preferredName.split(',')[1].trim() }}
                  {{ storyFollowingData.name.preferredName.split(',')[0] }}
               </div>
               <div
                  *ngIf="storyFollowingData.name && storyFollowingData.name.preferredName.split(',').length === 1"
                  class="profileName">
                  {{ storyFollowingData.name.preferredName.trim() }}
               </div>
               <div
                  *ngIf="storyFollowingData.workInfo"
                  class="profileJobRole">
                  <span *ngIf="showWorkInfo('JobTitle')">{{ storyFollowingData.workInfo.jobTitle }}</span>
               </div>
               <button
                  nz-button
                  nzType="primary"
                  nzBlock="true"
                  class="profile-btn"
                  nzGhost
                  (click)="toggle(storyFollowingData)">
                  Full Profile
               </button>
            </div>
         </div>
      </nz-card>
      <div
         class="profile"
         nz-col
         nzXs="24"
         nzSm="24"
         nzMd="24"
         nzLg="24"
         nzXl="24"
         nzXXl="24">
         <ng-container>
            <nz-descriptions
               class="title"
               [nzColumn]="1"
               nzTitle="Phone"
               nzColon="false"></nz-descriptions>

            <ul class="list">
               <li *ngFor="let info of profileList.phone">
                  <ng-container *ngIf="showContactDetails(info.label)">
                     {{ info.label }}:
                     <span>{{ info.value }}</span>
                  </ng-container>
               </li>
            </ul>

            <nz-divider></nz-divider>

            <nz-descriptions
               class="title"
               [nzColumn]="1"
               nzTitle="Email"
               nzColon="false"></nz-descriptions>

            <ul class="list">
               <li *ngFor="let info of profileList.email">
                  <ng-container *ngIf="showContactDetails(info.label)">
                     {{ info.label }}:
                     <span>{{ info.value }}</span>
                  </ng-container>
               </li>
            </ul>

            <nz-divider></nz-divider>

            <nz-descriptions
               class="title"
               [nzColumn]="1"
               nzTitle="Work Info"
               nzColon="false"></nz-descriptions>

            <ul class="list">
               <li *ngFor="let info of profileList.workinfo">
                  <ng-container *ngIf="showContactDetails(info.label)">
                     {{ info.label }}:
                     <span>{{ info.value }}</span>
                  </ng-container>
               </li>
            </ul>
         </ng-container>
      </div>
   </div>
</div>

<!-- *************************Following Code Ends************************** -->

<div
   [ngClass]="{ 'toggle-section': true, 'drawer': isDrawer }"
   *ngIf="isStoryLanding && isFilter && !isStoryFollowing && !gdCollapsed && !ddCollapsed && !glCollapsed"
   [attr.data-component]="'ncx/toggle-section/toggle-section.component.html (Filter)'">
   <div style="padding: 16px 24px">
      <span class="storyTitle">Filter</span>
      <span style="float: right">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"
            (click)="close()"
            style="cursor: pointer"></i>
      </span>
   </div>
   <nz-card>
      <div>
         <div class="storyStateCreated">
            <label>
               <i
                  nz-icon
                  nzType="file"
                  nzTheme="outline"
                  class="filterStyle"></i>
               Post Type
            </label>
            <div class="status">
               <nz-radio-group [(ngModel)]="radioPostTypeValue">
                  <label
                     (click)="addFilters($event, item, 'type')"
                     style="display: block; margin: 8px 0px; font-size: 14px; line-height: 22px"
                     nz-radio
                     [nzValue]="item.value"
                     *ngFor="let item of radioPostOptions">
                     {{ item.label }}
                  </label>
               </nz-radio-group>
            </div>
         </div>
      </div>
   </nz-card>
   <nz-card>
      <div>
         <div class="storyStateCreated">
            <label>
               <i
                  nz-icon
                  nzType="flag"
                  nzTheme="outline"
                  class="filterStyle"></i>
               Labels
            </label>
            <div class="postStatus">
               <!-- <nz-checkbox-group [(ngModel)]="checkOptionsLabel" (ngModelChange)="addFilters($event)">
          </nz-checkbox-group> -->
               <ul nz-menu>
                  <li
                     nz-menu-item
                     *ngFor="let item of checkOptionsLabel">
                     <label
                        nz-checkbox
                        nzValue="item.label"
                        [ngModel]="item.checked"
                        (ngModelChange)="addFilters($event, item, 'labels')">
                        {{ item.label }}
                     </label>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </nz-card>
   <nz-card>
      <div>
         <div class="storyStateCreated">
            <label>
               <i
                  nz-icon
                  nzType="sort-ascending"
                  nzTheme="outline"
                  style="font-size: 16px; margin-right: 11.13px"></i>
               Order
            </label>
            <div class="status">
               <nz-radio-group [(ngModel)]="radioCreationDateValue">
                  <label
                     (click)="addFilters($event, item, 'date')"
                     class="radioCreation"
                     nz-radio
                     [nzValue]="item.value"
                     *ngFor="let item of radioCreationDateOptions">
                     {{ item.label }}
                  </label>
               </nz-radio-group>
            </div>
         </div>
      </div>
   </nz-card>
   <div class="clearButton">
      <div
         class="story"
         (click)="clear()">
         Clear
      </div>
   </div>
</div>

<!-- *************************View Story********************** -->

<div
   [ngClass]="{ 'toggle-section': true, 'drawer': isDrawer }"
   *ngIf="!isStoryLanding && !isStoryFollowing && !gdCollapsed && !ddCollapsed && !glCollapsed"
   [attr.data-component]="'ncx/toggle-section/toggle-section.component.html (View Story Details)'">
   <nz-card
      nzTitle="Story Details"
      [nzBordered]="!isDrawer"
      [nzExtra]="extraTemplate">
      <ng-template #extraTemplate>
         <button (click)="closeSider()">
            <i
               nz-icon
               nzType="close"
               nzTheme="outline"></i>
         </button>
      </ng-template>

      <div>
         <div>Topic</div>
         <div style="color: #1890ff">
            {{ topics }}
         </div>
      </div>
      <div
         style="padding-top: 11px"
         *ngIf="storyDetails && storyDetails.slackChannelName?.length > 0">
         <div>Slack Connections</div>
         <div *ngFor="let slack of storyDetails.slackChannelName">
            <a
               target="#"
               *ngIf="slack.length > 11 || !slack.startsWith('C0')"
               href="https://slack.com/app_redirect?channel={{ slack }}"
               class="nameLinks">
               #{{ slack }}
            </a>
            <a
               *ngIf="slack.length <= 11 && slack.startsWith('C0')"
               target="#"
               href="{{ getSlackUrl }}{{ slack }}"
               class="nameLinks">
               #{{ slack }}
            </a>
         </div>
      </div>
      <div style="padding-top: 11px">
         <div>Privacy</div>
         <div *ngIf="radioValue">{{ accessValue | titlecase }}</div>
      </div>
      <div style="padding-top: 11px">
         <div>State</div>
         <div *ngIf="accessValue">{{ radioValue | titlecase }}</div>
      </div>
      <div style="padding-top: 11px">
         <div>Creator</div>
         <button
            style="color: #1890ff"
            *ngIf="storyDetails && storyDetails.createUser && storyDetails.createUser.firstName">
            <span
               nz-popover
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'left'"
               *ngIf="storyDetails.createUser.displayName.split(',').length > 1">
               {{ storyDetails.createUser.displayName.split(',')[1].trim() }}
               {{ storyDetails.createUser.displayName.split(',')[0] }}
            </span>
            <span
               nz-popover
               [nzPopoverContent]="profileCardTemplate"
               [nzPopoverPlacement]="'left'"
               *ngIf="storyDetails.createUser.displayName.split(',').length === 1">
               {{ storyDetails.createUser.displayName.trim() }}
            </span>
            <ng-template #profileCardTemplate>
               <app-profile-overlay [profileData]="storyDetails.createUser"></app-profile-overlay>
            </ng-template>
         </button>
      </div>
      <div style="padding-top: 11px">
         <div>Created</div>
         <div *ngIf="storyDetails && storyDetails.createDateTime">
            {{ utcToLocal(storyDetails.createDateTime, 'DATE') | date: 'MMM dd yyyy' }},
            {{ utcToLocal(storyDetails.createDateTime, 'TIME') }}
         </div>
      </div>
   </nz-card>

   <div class="extraFeatures">
      <button
         nz-button
         *ngIf="canEditStory"
         (click)="redirect()"
         nzGhost="true"
         nzBlock="true">
         <i
            nz-icon
            nzType="edit"
            nzTheme="fill"></i>
         Edit Story
      </button>

      <button
         nz-button
         *ngIf="functionAbility.fa_merge_story"
         (click)="mergeStory()"
         nzGhost="true"
         nzBlock="true">
         <i
            nz-icon
            nzType="swap"
            nzTheme="outline"></i>
         Merge Story
      </button>

      <button
         nz-button
         nzGhost="true"
         nzBlock="true"
         class="delete"
         (click)="warning()"
         *ngIf="functionAbility.fa_delete_story">
         <i
            nz-icon
            nzType="delete"
            nzTheme="fill"></i>
         Delete
      </button>

      <a
         nz-button
         nzGhost="true"
         nzBlock="true"
         href="{{ viewStoryEmailTo }}"
         *ngIf="functionAbility.fa_add_post_via_email">
         <i
            nz-icon
            nzType="mail"
            nzTheme="fill"></i>
         Add Post via Email
      </a>

      <button
         nz-button
         nzGhost="true"
         nzBlock="true"
         (click)="openInfocenterResearch()"
         *ngIf="functionAbility.fa_delete_story">
         <i
            nz-icon
            nzType="question-circle"
            nzTheme="outline"></i>
         Request Infocenter Research
      </button>

      <ng-container *ngIf="functionAbility.fa_access_admin_console">
         <span class="custom-button">
            <span>Block from Slack</span>
            <nz-switch
               [(ngModel)]="isSlackBlockedSubscription"
               (click)="blockFromSlack()"
               nzSize="small"></nz-switch>
         </span>
      </ng-container>
   </div>
</div>

<!-- ************************* Group Detail ********************** -->

<div
   [ngClass]="{ 'toggle-section': true, 'drawer': isDrawer }"
   *ngIf="gdCollapsed && !isStoryLanding && !isStoryFollowing && !ddCollapsed && !glCollapsed"
   [attr.data-component]="'ncx/toggle-section/toggle-section.component.html (Group Detail)'">
   <nz-card
      nzTitle="Group Detail"
      [nzBordered]="!isDrawer"
      [nzExtra]="extraTemplate">
      <ng-template #extraTemplate>
         <button (click)="groupDetailsCloseSider()">
            <i
               nz-icon
               nzType="close"
               nzTheme="outline"></i>
         </button>
      </ng-template>

      <ul class="details-list">
         <li>
            <p>Members</p>
            <p>
               {{
                  groupDetails && groupDetails.groupMembership && groupDetails.groupMembership.length
                     ? groupDetails.groupMembersCount
                     : 0
               }}
            </p>
         </li>
         <li>
            <p>Following</p>
            <p>
               {{
                  groupDetails && groupDetails.groupFollowers && groupDetails.groupFollowers.length
                     ? groupDetails.groupFollowerCount
                     : 0
               }}
            </p>
         </li>
         <li>
            <p>Created</p>
            <p *ngIf="groupDetails && groupDetails.createDateTime">
               {{ utcToLocal(groupDetails.createDateTime, 'DATE') | date: 'MMM dd yyyy' }},
               {{ utcToLocal(groupDetails.createDateTime, 'TIME') }}
            </p>
         </li>
         <li>
            <p>Privacy</p>
            <p *ngIf="groupDetails && groupDetails.groupAccess">{{ groupDetails.groupAccess }}</p>
         </li>
         <li>
            <p>Creator</p>
            <p>
               <button
                  class="nameLinks"
                  *ngIf="groupDetails && groupDetails.createUser">
                  <span
                     nz-popover
                     [nzPopoverContent]="profileCardTemplate"
                     [nzPopoverPlacement]="'left'"
                     *ngIf="
                        groupDetails &&
                        groupDetails.createUser &&
                        groupDetails.createUser.displayName.split(',').length > 1
                     ">
                     {{ groupDetails.createUser.displayName.split(',')[1].trim() }}
                     {{ groupDetails.createUser.displayName.split(',')[0] }}
                  </span>
                  <span
                     nz-popover
                     [nzPopoverContent]="profileCardTemplate"
                     [nzPopoverPlacement]="'left'"
                     *ngIf="
                        groupDetails &&
                        groupDetails.createUser &&
                        groupDetails.createUser.displayName.split(',').length === 1
                     ">
                     {{ groupDetails.createUser.displayName.trim() }}
                  </span>
                  <ng-template #profileCardTemplate>
                     <app-profile-overlay [profileData]="groupDetails.createUser"></app-profile-overlay>
                  </ng-template>
               </button>
            </p>
         </li>
         <li *ngIf="groupDetails && groupDetails.isSlackIntegration">
            <p>Slack Connections</p>
            <p *ngFor="let slack of groupDetails.slackChannelName">
               <a
                  target="_blank"
                  href="https://slack.com/app_redirect?channel={{ slack }}"
                  class="nameLinks">
                  #{{ slack }}
               </a>
            </p>
         </li>
      </ul>
   </nz-card>

   <div class="extraFeatures">
      <button
         nz-button
         [disabled]="!canEditGroup"
         nzBlock="true"
         nzGhost="true"
         (click)="redirect()">
         <i
            nz-icon
            nzType="edit"
            nzTheme="fill"></i>
         <span>Edit Group</span>
      </button>

      <button
         nz-button
         nzBlock="true"
         nzGhost="true"
         (click)="exportMembers()">
         <i
            nz-icon
            nzType="file-excel"
            nzTheme="outline"></i>
         <span>Export Members</span>
      </button>

      <button
         nz-button
         nzBlock="true"
         nzGhost="true"
         *ngIf="functionAbility.fa_manage_group_members && isAdminOrOwner"
         (click)="manageMembers()">
         <i
            nz-icon
            nzType="team"
            nzTheme="outline"></i>
         <span>Manage Members</span>
      </button>

      <a
         nz-button
         href="{{ emailGroupTo }}"
         nzGhost="true"
         nzBlock="true"
         *ngIf="functionAbility.fa_manage_group_members || isAdminOrOwner">
         <i
            nz-icon
            nzType="mail"
            nzTheme="fill"></i>
         <span>Add Post via Email</span>
      </a>
   </div>
</div>

<!-- *************************Discussion Details********************** -->

<div
   [ngClass]="{ 'toggle-section': true, 'drawer': isDrawer }"
   *ngIf="ddCollapsed && !isStoryLanding && !isStoryFollowing && !gdCollapsed && !glCollapsed"
   [attr.data-component]="'ncx/toggle-section/toggle-section.component.html (Discussion Detail)'">
   <div style="padding: 16px 24px">
      <span class="storyTitle">Discussion Detail</span>
      <button
         class="close"
         (click)="discussionDetailsCloseSider()">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
      </button>
   </div>

   <nz-divider style="margin: 0"></nz-divider>

   <div class="extraFeatures">
      <button
         nz-button
         nzType="default"
         nzBlock="true"
         [disabled]="
            discussionDetails &&
            discussionDetails.locked &&
            discussionDetails.lockedByUser &&
            loggedInUserId !== discussionDetails.lockedByUser.userId ||
            userInfoDetails.role === userRoles.ReadOnly"
         (click)="redirectTo()">
         <i
            nz-icon
            nzType="edit"
            nzTheme="fill"></i>
         Edit Post
      </button>
      <button
         nz-button
         nzType="default"
         nzBlock="true"
         (click)="moveDiscussionPost()"
         [disabled]="
            discussionDetails &&
            discussionDetails.locked &&
            discussionDetails.lockedByUser &&
            loggedInUserId !== discussionDetails.lockedByUser.userId ||
            userInfoDetails.role === userRoles.ReadOnly">
         <i
            nz-icon
            nzType="swap"
            nzTheme="outline"></i>
         Move
      </button>
      <button
         nz-button
         nzType="default"
         nzBlock="true"
         (click)="goToGroupVersions()"
         [disabled]="
            discussionDetails &&
            discussionDetails.locked &&
            discussionDetails.lockedByUser &&
            loggedInUserId !== discussionDetails.lockedByUser.userId ||
            userInfoDetails.role === userRoles.ReadOnly">
         <i
            nz-icon
            nzType="exception"
            nzTheme="outline"></i>
         Versions
      </button>

      <button
         nz-button
         nzType="default"
         nzBlock="true"
         (click)="downloadDiscussionPDF()">
         <i
            nz-icon
            nzType="file-pdf"
            nzTheme="outline"></i>
         View as PDF
      </button>

      <button
         nz-button
         nzType="default"
         nzBlock="true"
         (click)="showConfirm()"
         [ngClass]="{
            deleteStory: !deleteFlag,
            deleteStoryActive: deleteFlag,
         }"
         [disabled]="
            discussionDetails &&
            discussionDetails.locked &&
            discussionDetails.lockedByUser &&
            loggedInUserId !== discussionDetails.lockedByUser.userId ||
            userInfoDetails.role === userRoles.ReadOnly">
         <i
            nz-icon
            nzType="delete"
            nzTheme="fill"></i>
         Delete
      </button>

      <button
         *ngIf="isAdmin"
         nz-button
         nzType="default"
         nzBlock="true"
         (click)="unlockingPost()"
         [disabled]="
            !(discussionDetails?.locked && loggedInUserId !== discussionDetails.lockedByUser?.userId && isAdmin) ||
            userInfoDetails.role === userRoles.ReadOnly">
         <i
            nz-icon
            nzType="unlock"
            nzTheme="fill"></i>
         Unlock Post
      </button>
   </div>
</div>

<!-- *************************Group Landing********************** -->

<div
   [ngClass]="{ 'toggle-section': true, 'drawer': isDrawer }"
   *ngIf="glCollapsed && !ddCollapsed && !isStoryLanding && !isStoryFollowing && !gdCollapsed"
   [attr.data-component]="'ncx/toggle-section/toggle-section.component.html (Group Landing)'">
   <div style="padding: 16px 24px">
      <span class="storyTitle">Filter</span>
      <button
         class="close"
         (click)="groupLandingCloseSider()">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
      </button>
   </div>

   <nz-divider style="margin: 0"></nz-divider>

   <section>
      <div class="radio-list">
         <div class="title">
            <i
               nz-icon
               nzType="team"></i>
            <span>Post Type</span>
         </div>
         <nz-radio-group
            [(ngModel)]="glradioValue"
            (ngModelChange)="sortByGroup($event)">
            <label
               class="radioLabel"
               nz-radio
               nzValue="ALL GROUPS">
               All Groups
            </label>
            <label
               class="radioLabel"
               nz-radio
               nzValue="MY GROUPS">
               My Groups
            </label>
            <label
               class="radioLabel"
               nz-radio
               nzValue="FOLLOWING">
               Following
            </label>
         </nz-radio-group>
      </div>
   </section>
</div>
