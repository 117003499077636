<div class="view-post-drawer">
   <app-loader-component
      loaderType="SECTION_LOADER"
      [isLoaded]="!isLoading"></app-loader-component>

   <nz-collapse
      [nzDisabled]="canChangeLabels"
      nzExpandIconPosition="right"
      class="mb-16"
      nzBordered="false">
      <nz-collapse-panel
         nzHeader="Editorial/Standards"
         nzActive="true">
         <div *ngFor="let opt of filterList?.markAsType">
            <label
               nz-checkbox
               [(ngModel)]="opt.checked"
               [disabled]="
                  opt.disabled ||
                  isLoading ||
                  !canViewLabel(opt.value) ||
                  (postDetails?.locked && userId !== postDetails.lockedByUser?.userId)
               "
               (ngModelChange)="onSelect(opt.value, $event, 'markAsType')">
               {{ opt.label }}
            </label>
         </div>
      </nz-collapse-panel>
   </nz-collapse>

   <nz-collapse
      [nzDisabled]="canChangeLabels"
      nzExpandIconPosition="right"
      nzBordered="false">
      <nz-collapse-panel
         nzHeader="R&C/Legal"
         nzActive="true">
         <div *ngFor="let opt of filterList?.statusType">
            <label
               nz-checkbox
               [(ngModel)]="opt.checked"
               [disabled]="
                  opt.disabled ||
                  isLoading ||
                  !canViewLabel(opt.value) ||
                  (postDetails?.locked && userId !== postDetails.lockedByUser?.userId)
               "
               (ngModelChange)="onSelect(opt.value, $event, 'statusType')">
               {{ opt.label }}
            </label>
         </div>
      </nz-collapse-panel>
   </nz-collapse>

   <!-- Show Topics when in Edit Mode or in View Mode and Topics exist -->
   <div *ngIf="!viewMode || (viewMode && hasTopics)">
      <div class="metadataSection">
         <div class="metadataTitle">Topic</div>
         <app-additional-topic-new
            [selectedValues]="postDetails.topics"
            [postRelatedTopics]="relatedTopics"
            (addedTopics)="updateTopics($event)"
            [viewMode]="viewMode"></app-additional-topic-new>
      </div>
   </div>

   <!-- Show Tags when in Edit/Create Mode or in View Mode and Tags exist -->
   <div *ngIf="!viewMode || (viewMode && hasTags)">
      <div class="metadataSection">
         <app-tags-new
            [tagContent]="postDetails.postContentTags"
            [isGenerateTags]="postDetails.isGenerateTags"
            [title]="postDetails.postTitle"
            [description]="postDetails.postContent"
            (tagsUpdated)="updateTags($event)"
            (generateFlag)="generateTags($event)"
            [viewMode]="viewMode"></app-tags-new>
      </div>
   </div>
</div>

<!-- MODALS FOR THE 'VIEW MODE' OF A POST -->

<app-reportable-modal
   [isVisible]="modals.visible['REPORTABLE']"
   [isLoaded]="!isLoading"
   (updated)="updatePostForReportable($event)"
   (cancelled)="modalCancelled('REPORTABLE')"></app-reportable-modal>

<app-limited-license-modal
   [isVisible]="modals.visible['LIMITED LICENSE']"
   [isLoaded]="!isLoading"
   (updated)="updatePostForLimitedLicense($event)"
   (cancelled)="modalCancelled('LIMITED LICENSE')">
   S
</app-limited-license-modal>

<app-standards-guidance-modal
   [isVisible]="modals.visible['STANDARDS']"
   [isLoaded]="!isLoading"
   (updated)="updatePostForStandardsGuidance($event)"
   (cancelled)="modalCancelled('STANDARDS')"></app-standards-guidance-modal>
