import { Component, EventEmitter, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as lodash from 'lodash';
import { SubSink } from 'subsink';
import { ISearch } from '@models/ncx/global-search';

@Component({
  selector: 'app-ready-working-filter',
  templateUrl: './ready-working-filter.component.html',
  styleUrls: ['../customization.component.scss']
})
export class ReadyWorkingFilterComponent implements OnInit, OnDestroy {

  id = '';

  isLoaded = true;

  newColumnName = '';

  originalColumnName = '';

  categories: any;

  isDisable = true;

  categoryName = '';

  selectedOrder = true;

  notification = 'Notifications Off';

  tabs = [
    {
      itemName: 'List',
      users: [],
      count: 0,
      groups: []
    },
    {
      itemName: 'Following',
      users: [],
      count: 0
    }
  ];

  editPersons = [
    {
      itemName: 'Added Lables',
      users: [],
      count: 0,
      groups: []
    },
    {
      itemName: 'Lables',
      users: [],
      count: 0
    }
  ];

  editGroups = [
    {
      itemName: 'Added Lables',
      users: [],
      count: 0,
      groups: []
    },
    {
      itemName: 'Lables',
      users: [],
      count: 0
    }
  ];

  /* Activity Declaration start */
  storiesList: any[];

  Stories = [
    {
      itemName: 'List',
      stories: [],
      count: 0
    },
    {
      itemName: 'Following',
      stories: [],
      count: 0
    }
  ];

  TopicsList: any[];

  Topics = [
    {
      itemName: 'Added Topics',
      users: [],
      count: 0
    },
    {
      itemName: 'Topics',
      users: [],
      count: 0
    }
  ];

  Labels = [
    {
      itemName: 'Added Labels',
      labels: [],
      count: 0
    },
    {
      itemName: 'Labels',
      labels: [],
      count: 0
    }
  ];

  Tag = '';

  Tags = [];

  disable = true;

  disableClear = true;

  disableClearTopic = true;

  disableClearAll = false;

  disableClearAllTopic = false;

  /* Activity Declaration End */
  searchTextLabel = 'Search for a Topic...';

  searchTextLabelHeader = 'Add a Person:';

  PostType = '';

  searchInput = '';

  searchInputStory = '';

  searchInputTopic = '';

  @Input() columnNumber = -1;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  private subs = new SubSink();

  constructor(
    private cS: CommonService,
    private toastService: ToastService,
    private router: Router,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private Translate: TranslateService
  ) {

    this.globalSearch = lodash.debounce(this.globalSearch, 1000);

  }

  ngOnInit() {

    this.cS.serviceRequestCommon('get', environment.getMetaDataAPI + '/categories').subscribe((res: any) => {

      this.categories = res;

    }, err => {

      this.toastService.createMessage('error', err);

    });

    this.cS.categoryDetails.subscribe((res) => {

      if (res === 'Person') {

        this.categoryName = 'Person';
        this.newColumnName = this.cS.columnName;
        this.selectboxClass(res);

      }
      if (res === 'Group') {

        this.categoryName = 'Group';
        this.newColumnName = this.cS.columnName;
        this.selectboxClass(res);

      }
      if (res === 'Activity') {

        this.categoryName = 'Activity';
        this.newColumnName = this.cS.columnName;
        this.selectboxClass(res);
        if (this.cS.columnName === 'Ready' || this.cS.columnName === 'Working') {

          this.PostType = 'Posts';

        }

      }
      if (res === 'Story') {

        this.categoryName = 'Story';
        this.newColumnName = this.cS.columnName;
        this.selectboxClass(res);
        if (this.cS.columnName === 'Ready' || this.cS.columnName === 'Working') {

          this.PostType = 'Posts';

        }

      }

    });

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  isInArray(value, array) {

    if (array != null) {

      const index = array.indexOf(value);

      if (index > -1) {

        return true;

      } else {

        return false;

      }

    } else {

      return false;

    }

  }

  isInList(value: any, array: any[]) {

    const index = array.map(e => e.topicId).indexOf(Number(value));

    if (index > -1) {

      return true;

    } else {

      return false;

    }

  }

  isStoryInList(value: any, array: any[]) {

    const index = array.map(e => e.storyId).indexOf(Number(value));

    if (index > -1) {

      return true;

    } else {

      return false;

    }

  }

  isPersonInList(value: any, array: any[]) {

    const index = array.map(e => e.ssoId).indexOf(Number(value));

    if (index > -1) {

      return true;

    } else {

      return false;

    }

  }

  isGroupInList(value: any, array: any[]) {

    const index = array.map(e => e.groupId).indexOf(Number(value));

    if (index > -1) {

      return true;

    } else {

      return false;

    }

  }

  ColumnDetails() {

    const userId = localStorage.getItem('userId');

    const queryStr = `/${userId}/userPersonalizedColumn/${this.columnNumber}`;

    const self = this;

    this.isLoaded = false;

    this.cS.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr).subscribe((res: any) => {

      this.newColumnName = res.columnName;
      this.originalColumnName = res.columnName;
      this.categoryName = res.categoryName;
      this.columnNumber = res.columnNumber;

      if (res.enabledNotifications === true) {

        this.notification = 'Notifications On';

      }

      if (res.categoryName === 'Person') {

        self.editPersons.forEach((element: any) => {

          if (element.ssoId !== '' && element.ssoId !== undefined && element.ssoId !== null) {

            const ssoId = element.ssoId.toString();

            if (this.isInArray(ssoId, res.personIds) && !this.isInArray(ssoId, this.tabs[0].users)) {

              this.tabs[0].users.push(element);
              this.tabs[0].count = this.tabs[0].count + 1;

            }

          }

        });

        self.tabs[1].users.forEach((element: any) => {

          if (element.ssoId !== '' && element.ssoId !== undefined && element.ssoId !== null) {

            const ssoId = element.ssoId.toString();

            if (this.isInArray(ssoId, res.personIds) && !this.isInArray(ssoId, this.tabs[0].users)) {

              this.tabs[0].users.push(element);
              this.tabs[0].count = this.tabs[0].count + 1;

            }

          }

        });

        res.personIds.forEach((element) => {

          if (!this.isPersonInList(element, self.tabs[0].users)) {

            // tslint:disable-next-line:no-shadowed-variable
            const queryStr = `/${element}`;

            this.cS.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr)

              // tslint:disable-next-line:no-shadowed-variable
              .subscribe((res: any) => {

                if (!this.isPersonInList(res.ssoId, self.tabs[0].users)) {

                  if (res && res.ssoId && res.ssoId !== undefined) {

                    const userDetails = {
                      email: res.email.workEmail,
                      firstName: res.firstName,
                      fullName: res.name.firstName + ' ' + res.name.lastName,
                      jobTitle: null,
                      lastName: null,
                      middleName: null,
                      profilePictureURL: '',
                      ssoId: res.ssoId,
                      userId: res.userId
                    };

                    self.tabs[0].users.push(userDetails);
                    self.tabs[0].count = self.tabs[0].count + 1;

                  }

                }
                this.isLoaded = true;

              },
              (err) => {

                this.toastService.createMessage('error', err);
                this.isLoaded = true;

              });

          }

        });

      }

      if (res.categoryName === 'Group') {

        self.editGroups.forEach((element: any) => {

          if (element.groupId !== '' && element.groupId !== undefined && element.groupId !== null) {

            const groupId = element.groupId.toString();

            if (this.isInArray(groupId, res.groupIds) && !this.isInArray(groupId, this.tabs[0].users)) {

              this.tabs[0].users.push(element);
              this.tabs[0].count = this.tabs[0].count + 1;

            }

          }

        });
        if (self.tabs[1].groups) {

          self.tabs[1].groups.forEach((element: any) => {

            if (element.groupId !== '' && element.groupId !== undefined && element.groupId !== null) {

              const groupId = element.groupId.toString();

              const isExist = this.containsObject(element, self.tabs[0].users, 'Group');

              if (!isExist && this.isInArray(groupId, res.groupIds)) {

                this.tabs[0].users.push(element);
                this.tabs[0].count = this.tabs[0].count + 1;

              }

            }

          });

        }
        res.groupIds.forEach((element) => {

          if (!this.isGroupInList(element, self.tabs[0].users)) {

            // tslint:disable-next-line:no-shadowed-variable
            const queryStr = `/${element}?isDiscussions=true`;

            this.isLoaded = false;
            this.cS.serviceRequestCommon('get', environment.getGroupsURL, queryStr).subscribe((res: any) => {

              if (!this.isGroupInList(res.groupId, self.tabs[0].users)) {

                self.tabs[0].users.push(res);
                self.tabs[0].count = self.tabs[0].count + 1;

              }

            },
            (err) => {

              this.toastService.createMessage('error', err);

            }).add(() => {

              this.isLoaded = true;

            });

          }

        });

      }

      if (res.categoryName === 'Activity') {

        if (res.postType === 'Element') {

          this.PostType = 'Elements';

        } else if (res.postType === 'Update') {

          this.PostType = 'Posts';

        } else if (res.postType === 'Summary') {

          this.PostType = 'Summaries';

        } else {

          this.PostType = 'All';

        }
        this.Tags = res.tags;
        if (Array.isArray(self.storiesList)) {

          self.storiesList.forEach((element: any) => {

            const ssoId = element.storyID === undefined ? element.storyId.toString() : element.storyID.toString();

            if (res.storyIds.includes(ssoId) && !this.isStoryInList(ssoId, self.Stories[0].stories)) {

              self.Stories[0].stories.push(element);
              self.Stories[0].count = self.Stories[0].count + 1;

            }

          });

        }
        self.Stories[1].stories.forEach((element: any) => {

          const storyId = element.storyID === undefined ? element.storyId.toString() : element.storyID.toString();

          if (storyId !== '' && storyId !== undefined && storyId !== null) {

            if (this.isInArray(storyId, res.storyIds) && !this.isStoryInList(storyId, self.Stories[0].stories)) {

              this.Stories[0].stories.push(element);
              self.Stories[0].count = self.Stories[0].count + 1;

            }

          }

        });
        res.storyIds.forEach((element) => {

          if (!this.isStoryInList(element, self.Stories[0].stories)) {

            // tslint:disable-next-line:no-shadowed-variable
            const queryStr = `/${element}`;

            this.cS.serviceRequestCommon('get', environment.getStoriesAPI, queryStr)

              // tslint:disable-next-line:no-shadowed-variable
              .subscribe((res: any) => {

                if (!this.isStoryInList(res.storyId, self.Stories[0].stories)) {

                  self.Stories[0].stories.push(res);
                  self.Stories[0].count = self.Stories[0].count + 1;

                }
                this.isLoaded = true;

              },
              (err) => {

                this.toastService.createMessage('error', err);
                this.isLoaded = true;

              });

          }

        });
        self.TopicsList.forEach((element: any) => {

          const topicId = element.topicID === undefined ? element.topicId.toString() : element.topicID.toString();

          if (this.isInArray(topicId, res.topics) && !this.isInList(topicId, this.Topics[0].users)) {

            this.Topics[0].users.push(element);
            this.Topics[0].count = this.Topics[0].count + 1;

          }

        });
        self.Topics[1].users.forEach((element: any) => {

          if (element.topicId !== '' && element.topicId !== undefined && element.topicId !== null) {

            const topicId = element.topicId.toString();

            if (this.isInArray(topicId, res.topics) && !this.isInList(topicId, this.Topics[0].users)) {

              this.Topics[0].users.push(element);
              this.Topics[0].count = this.Topics[0].count + 1;

            }

          }

        });
        const labelsList = [];

        let count = 0;

        res.labels.forEach((element) => {

          const lblValue = {
            label: element,
            value: element
          };

          labelsList.push(lblValue);
          count = count + 1;

        });
        self.Labels[0].labels = labelsList;
        self.Labels[0].count = count;

      }

      if (res.categoryName === 'Story') {

        this.notification = 'Notifications Off';
        this.PostType = null;
        this.Topics[0].users = [];
        this.Tags = [];
        this.Labels[0].labels = [];
        if (res.postType === 'Element') {

          this.PostType = 'Elements';

        } else if (res.postType === 'Update') {

          this.PostType = 'Posts';

        } else if (res.postType === 'Summary') {

          this.PostType = 'Summaries';

        } else {

          this.PostType = 'All';

        }
        this.Tags = res.tags;
        self.TopicsList.forEach((element: any) => {

          const topicId = element.topicID === undefined ? element.topicId.toString() : element.topicID.toString();

          if (this.isInArray(topicId, res.topics) && !this.isInList(topicId, this.Topics[0].users)) {

            this.Topics[0].users.push(element);
            this.Topics[0].count = this.Topics[0].count + 1;

          }

        });
        self.Topics[1].users.forEach((element: any) => {

          if (element.topicId !== '' && element.topicId !== undefined && element.topicId !== null) {

            const topicId = element.topicId.toString();

            if (this.isInArray(topicId, res.topics) && !this.isInList(topicId, this.Topics[0].users)) {

              this.Topics[0].users.push(element);
              this.Topics[0].count = this.Topics[0].count + 1;

            }

          }

        });
        const labelsList = [];

        let count = 0;

        if (res.labels && res.labels.length > 0) {

          res.labels.forEach((element) => {

            const lblValue = {
              label: element,
              value: element
            };

            labelsList.push(lblValue);
            count = count + 1;

          });
          self.Labels[0].labels = labelsList;
          self.Labels[0].count = count;

        }

      }

    });

  }

  globalSearch(event, Category) {

    if (Category === 'Topic') {

      const text = event.target.value;

      const queryStr = `?topicName=${encodeURIComponent(text)}`;

      this.cS.serviceRequestCommon('get', environment.getAllTopicsAPIURL, queryStr).pipe(
        debounceTime(1),
        distinctUntilChanged()
      ).subscribe((res: any) => {

        this.TopicsList = res.topics;
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

    } else if (Category === 'Story') {

      const text = event.target.value;

      const queryStr = `?storyTitle=${encodeURIComponent(text)}`;

      this.cS.serviceRequestCommon('get', environment.getStoryTitles, queryStr).pipe(
        debounceTime(1),
        distinctUntilChanged()
      ).subscribe((res: any) => {

        this.storiesList = res;
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

    } else {

      if (Category === 'Person') {

        const text = event.target.value;

        const userId = localStorage.getItem('userId');

        const queryStr = `?targetUserId=${userId}&userName=${text}`;

        this.cS.serviceRequestCommon('get', environment.getusersAndConnectionsURL, queryStr).pipe(
          debounceTime(1),
          distinctUntilChanged()
        ).subscribe((res: any) => {

          const list = [];

          if (Category === 'Person') {

            res[0].users.forEach(element => {

              list.push({
                fullName: element.fullName,
                userId: element.userId,
                ssoId: element.ssoId
              });

            });
            this.editPersons = list;

          }

          this.isLoaded = true;

        },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

      } else {

        const text = event.target.value;

        const queryStr = `?searchString=${encodeURIComponent(text)}&isSuggestNeeded=false` +
          '&sortField=modificationdate&sortOrder=descending&startIndex=1&endIndex=10';

        this.cS.serviceRequestCommon('post', environment.globalSearch, queryStr).pipe(
          debounceTime(1),
          distinctUntilChanged()
        ).subscribe((res: any) => {

          const list = [];

          const result = res as ISearch;

          if (Category === 'Person') {

            result.profileResult.forEach(element => {

              list.push({
                fullName: element.displayName,
                userId: element.userId,
                ssoId: element.ssoId
              });

            });
            this.editPersons = list;

          }
          if (Category === 'Group') {

            res.groupSearchResult.forEach(element => {

              list.push({
                groupName: element.storyTitle,
                fullName: element.storyTitle,
                groupId: element.storyID
              });

            });
            this.editGroups = list;

          }
          if (Category === 'Activity') {

            res.storySearchResult.forEach(element => {

              list.push({
                storyId: element.storyID,
                storyTitle: element.storyTitle
              });

            });
            this.storiesList = list;

          }
          this.isLoaded = true;

        },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

      }

    }

  }

  closeDrawer() {

    this.isDisable = true;
    this.cS.passValue(false);
    this.close.emit();

  }

  selectboxClass(event) {

    this.isLoaded = false;

    if (event === 'Person' && this.categoryName === 'Person') {

      this.searchTextLabel = 'Search for a Person...';
      this.searchTextLabelHeader = 'Add a Person:';
      const userId = localStorage.getItem('userId');

      const queryStr = `?targetUserId=${userId}`;

      this.cS.serviceRequestCommon('get', environment.getusersAndConnectionsURL, queryStr).subscribe((res: any) => {

        res.forEach((item: any) => {

          if (item.itemName === 'UsersList') {

            this.editPersons = item.users;

          }
          if (item.itemName === 'FollowersList') {

            this.tabs = [
              {
                itemName: 'List',
                users: [],
                count: 0
              }
            ];
            item.itemName = 'Following';
            this.tabs.push(item);

          }

        });
        this.ColumnDetails();

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });

    } else if (event === 'Group' && this.categoryName === 'Group') {

      this.searchTextLabel = 'Search for a Group...';
      this.searchTextLabelHeader = 'Add a Group:';

      this.cS.serviceRequestCommon('get', environment.getMYGroupsURL).subscribe((res: any) => {

        this.tabs = [
          {
            itemName: 'List',
            users: [],
            count: 0
          }
        ];

        // tslint:disable-next-line:prefer-const
        res.itemName = 'My Groups';
        res.count = Array.isArray(res.groups) ? res.groups.length : 0;
        this.tabs.push(res);

      },
      (err) => {

        this.toastService.createMessage('error', err);

      });

      this.cS.serviceRequestCommon('get', environment.getALLGroupsURL).subscribe((res: any) => {

        this.editGroups = res.groups;
        this.ColumnDetails();

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });

    } else if (event === 'Activity' && this.categoryName === 'Activity') {

      const userId = localStorage.getItem('userId');

      const ssoId = localStorage.getItem('ssoId');

      const queryStr = `?followerSSOId=${ssoId}&isFollowerId=true`;

      this.cS.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr).subscribe((res: any) => {

        res.itemName = 'Following';
        this.Stories[1].count = res.totalCount;
        this.Stories[1].stories = res.stories;

        // this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });
      this.cS.serviceRequestCommon('post', environment.getALLStories).subscribe((res: any) => {

        const options = res.storySearchResult.map((row) => {

          return {
            authorDisplayName: row.authorDisplayName,
            createDateTime: row.createDateTime,
            description: row.description,
            membersCount: row.membersCount,
            status: row.status,
            storyContentTags: row.storyContentTags,
            storyId: row.storyID,
            storyTitle: row.storyTitle,
            storyUrl: row.storyUrl,
            topics: row.topics,
            updatedDateTime: row.updatedDateTime,
            userId: row.userId
          };

        });

        this.storiesList = options;
        this.ColumnDetails();

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });
      this.cS.serviceRequestCommon('get', environment.getAllTopicsAPIURL).subscribe((res: any) => {

        this.TopicsList = res.topics;
        this.ColumnDetails();

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });

      /* Follower Topic section start */
      const FollowqueryStr = `/FollowingTopics/${userId}`;

      this.cS.serviceRequestCommon('get', environment.getFollowTopicsAPIURL, FollowqueryStr).subscribe((res: any) => {

        this.Topics[1].users = res.followingTopicIDs;
        this.Topics[1].count = res.topicFollowingCount;

        // this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });

      /* Follower Topic section End */
      this.cS.serviceRequestCommon('get', environment.getALLLabels).subscribe((res: any) => {

        this.Labels[1].labels = res;
        this.Labels[1].count = res.length;
        this.ColumnDetails();

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });

    } else if (event === 'Story' && this.categoryName === 'Story') {

      const userId = localStorage.getItem('userId');

      // const ssoId = localStorage.getItem('ssoId');
      // const queryStr = `?followerSSOId=${ssoId}&isFollowerId=true`;

      //   this.cS.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr)
      //   .subscribe((res: any) => {
      //     res.itemName = 'Following';
      //     this.Stories[1].count = res.totalCount;
      //     this.Stories[1].stories = res.stories;
      //     this.isLoaded = true;
      //   },
      // (err) => {
      //   this.toastService.createMessage('error', err);
      //   this.isLoaded = true;
      //   });
      // this.cS.serviceRequestCommon('get', environment.getALLStories)
      //   .subscribe((res: any) => {
      //     const options = res.storySearchResult.map((row) => {
      //       return {authorDisplayName: row.authorDisplayName,
      //               createDateTime: row.createDateTime,
      //               description: row.description,
      //               membersCount: row.membersCount,
      //               status: row.status,
      //               storyContentTags: row.storyContentTags,
      //               storyId: row.storyID,
      //               storyTitle: row.storyTitle,
      //               storyUrl: row.storyUrl,
      //               topics: row.topics,
      //               updatedDateTime: row.updatedDateTime,
      //               userId: row.userId
      //        };
      //    });
      //     this.storiesList = options;
      //     this.isLoaded = true;
      //     this.ColumnDetails(6);
      //   },
      // (err) => {
      //   this.toastService.createMessage('error', err);
      //   this.isLoaded = true;
      // });
      this.cS.serviceRequestCommon('get', environment.getAllTopicsAPIURL).subscribe((res: any) => {

        this.TopicsList = res.topics;
        this.ColumnDetails();

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });

      /* Follower Topic section start */
      const FollowqueryStr = `/FollowingTopics/${userId}`;

      this.cS.serviceRequestCommon('get', environment.getFollowTopicsAPIURL, FollowqueryStr).subscribe((res: any) => {

        this.Topics[1].users = res.followingTopicIDs;
        this.Topics[1].count = res.topicFollowingCount;

        // this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);

      }).add(() => {

        this.isLoaded = true;

      });

      /* Follower Topic section End */
      this.cS.serviceRequestCommon('get', environment.getALLLabels).subscribe((res: any) => {

        this.Labels[1].labels = res;
        this.Labels[1].count = res.length;
        this.ColumnDetails();

      },
      (err) => {

        this.toastService.createMessage('error', err);

      });

    }

  }

  selectbox(event: any) {

    this.searchInput = '';
    this.searchInputStory = '';
    this.searchInputTopic = '';
    this.isLoaded = false;
    this.id = event;
    this.isDisable = false;
    this.categoryName = event;
    if (event === 'Person') {

      this.searchTextLabel = 'Search for a Person...';
      this.searchTextLabelHeader = 'Add a Person:';
      const userId = localStorage.getItem('userId');

      const queryStr = `?targetUserId=${userId}`;

      this.cS.serviceRequestCommon('get', environment.getusersAndConnectionsURL, queryStr).subscribe((res: any) => {

        res.forEach((item: any) => {

          if (item.itemName === 'UsersList') {

            this.editPersons = item.users;

          }
          if (item.itemName === 'FollowersList') {

            this.tabs = [
              {
                itemName: 'List',
                users: [],
                count: 0
              }
            ];
            item.itemName = 'Following';
            this.tabs.push(item);

          }
          this.isLoaded = true;

        });

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

    }
    if (event === 'Group') {

      this.searchTextLabel = 'Search for a Group...';
      this.searchTextLabelHeader = 'Add a Group:';
      this.cS.serviceRequestCommon('get', environment.getMYGroupsURL).subscribe((res: any) => {

        this.tabs = [
          {
            itemName: 'List',
            users: [],
            count: 0
          }
        ];

        res.itemName = 'My Groups';
        res.count = Array.isArray(res.groups) ? res.groups.length : 0;
        res.users = res.groups === null ? [] : res.groups;
        this.tabs.push(res);
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

      this.cS.serviceRequestCommon('get', environment.getALLGroupsURL).subscribe((res: any) => {

        this.editGroups = res.groups;
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

    }
    if (event === 'Activity') {

      const ssoId = localStorage.getItem('ssoId');

      const queryStr = `?followerSSOId=${ssoId}&isFollowerId=true`;

      this.cS.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr).subscribe((res: any) => {

        res.itemName = 'Following';
        this.Stories[1].count = res.totalCount;
        this.Stories[1] = res;
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });
      this.cS.serviceRequestCommon('post', environment.getALLStories).subscribe((res: any) => {

        this.storiesList = res.storySearchResult;
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

      this.cS.serviceRequestCommon('get', environment.getAllTopicsAPIURL).subscribe((res: any) => {

        this.TopicsList = res.topics;
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

      /* Follower Topic section start */
      this.cS.serviceRequestCommon('get', environment.getAllTopicsAPIURL).subscribe((res: any) => {

        this.Topics[1].users = res.topics;
        this.Topics[1].count = res.topics.length;
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

      /* Follower Topic section End */
      this.cS.serviceRequestCommon('get', environment.getALLLabels).subscribe((res: any) => {

        this.Labels[1].labels = res;
        this.Labels[1].count = res.length;
        this.isLoaded = true;

      },
      (err) => {

        this.toastService.createMessage('error', err);
        this.isLoaded = true;

      });

    }

  }

  selectOrder(item, _idName) {

    this.selectedOrder = item;

  }

  selectPostType(item, idName) {

    if (idName === 'AllPostType') {

      this.PostType = 'All';

    }
    if (idName === 'PostsPostType') {

      this.PostType = 'Posts';

    }
    if (idName === 'ElementsPostType') {

      this.PostType = 'Elements';

    }
    if (idName === 'SummariesPostType') {

      this.PostType = 'Summaries';

    }

  }

  changeLabelStatus(data, tagName) {

    this.disableClearAll = false;
    this.disableClear = true;
    const isExist = this.containsObject(data, this.tabs[0].users, tagName);

    if (!isExist) {

      this.tabs[0].users.push(Object.create(data));
      this.tabs[0].count = this.tabs[0].users.length;

    } else {

      this.toastService.createMessage('error', 'Selected item already exist in list.');

    }
    this.searchInput = '';
    (document.getElementById('groupPersonValue') as HTMLInputElement).value = '';

  }

  SelectCheckbox(TabName, objitem, checked) {

    this.disableClearAll = false;
    this.disableClear = false;
    this.isLoaded = false;

    // tslint:disable-next-line:only-arrow-functions
    const self = this;

    this.tabs.filter((item, itemindex) => {

      if (self.categoryName === 'Person') {

        // tslint:disable-next-line:only-arrow-functions
        if ((TabName === 'List' && item.itemName === TabName)) {

          self.tabs[itemindex].users.filter((subitem, subindex) => {

            if (subitem.userId === objitem.userId) {

              checked = (checked === undefined || checked === false || checked === '') ? false : true;
              self.tabs[itemindex].users[subindex].checked = !checked;

            }

          });

        }
        if ((TabName === 'Following' && item.itemName === TabName)) {

          self.tabs[itemindex].users.filter((subitem, subindex) => {

            if (subitem.userId === objitem.userId) {

              checked = (checked === undefined || checked === false || checked === '') ? false : true;
              self.tabs[itemindex].users[subindex].checked = !checked;

            }

          });

        }

      }

      if (self.categoryName === 'Group') {

        if (item.itemName === 'List' && item.itemName === TabName) {

          item.users.filter((subitem, subindex) => {

            if (subitem.groupId === objitem.groupId) {

              checked = (checked === undefined || checked === false || checked === '') ? false : true;
              item.users[subindex].checked = !checked;

            }

          });

        }
        if (item.itemName === 'My Groups' && item.itemName === TabName) {

          item.groups.filter((subitem, subindex) => {

            if (subitem.groupId === objitem.groupId) {

              checked = (checked === undefined || checked === false || checked === '') ? false : true;
              item.groups[subindex].checked = !checked;

            }

          });

        }

      }
      this.isLoaded = true;

    });

  }

  SelectAllOptions(TabName) {

    // this.disableClearAll = false;
    this.disableClear = false;
    this.isDisable = false;

    // tslint:disable-next-line:only-arrow-functions
    this.tabs.filter((item) => {

      if (item.itemName === TabName) {

        if (TabName === 'My Groups') {

          // tslint:disable-next-line:only-arrow-functions
          item.groups.filter((subitem, subindex) => {

            item.groups[subindex].checked = true;

          });

        } else {

          // tslint:disable-next-line:only-arrow-functions
          item.users.filter((subitem, subindex) => {

            item.users[subindex].checked = true;

          });

        }

      }

    });

  }

  ClearSelectAllOptions(buttonName, TabName) {

    this.disableClear = true;
    if (buttonName === 'clear-button') {

      // tslint:disable-next-line:one-variable-per-declaration
      // tslint:disable-next-line:no-shadowed-variable
      let searchInput: any;

      // tslint:disable-next-line:no-shadowed-variable
      let i: any;

      // tslint:disable-next-line:no-shadowed-variable
      let count: any;

      searchInput = Object.create(this.tabs);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].users.length;
          while (i--) {

            {

              if (searchInput[count].users[i].checked === true) {

                searchInput[count].users.splice(i, 1);

              }

            }

          }

        }

      }
      this.tabs[0].count = this.tabs[0].users.length;

    } else {

      // tslint:disable-next-line:no-shadowed-variable
      let searchInput: any;

      // tslint:disable-next-line:no-shadowed-variable
      let i: any;

      // tslint:disable-next-line:no-shadowed-variable
      let count: any;

      searchInput = Object.create(this.tabs);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          if (TabName === 'My Groups') {

            i = searchInput[count].groups.length;
            while (i--) {

              {

                if (searchInput[count].groups[i].checked === true) {

                  searchInput[count].groups[i].checked = false;
                  const objectData = Object.assign({}, searchInput[count].groups[i]);

                  const isContains = this.containsObject(objectData, this.tabs[0].users, 'Group');

                  if (!isContains) {

                    this.tabs[0].users.push({ ...searchInput[count].groups[i] });
                    this.tabs[0].count = this.tabs[0].users.length;

                  } else {

                    this.toastService.createMessage('error', 'Selected resource already exist in list.');

                  }

                }

              }

            }

          }
          if (TabName === 'Following') {

            i = searchInput[count].users.length;
            while (i--) {

              {

                if (searchInput[count].users[i].checked === true) {

                  searchInput[count].users[i].checked = false;
                  const objectData = Object.assign({}, searchInput[count].users[i]);

                  const isContains = this.containsObject(objectData, this.tabs[0].users, 'Person');

                  if (!isContains) {

                    this.tabs[0].users.push({ ...searchInput[count].users[i] });
                    this.tabs[0].count = this.tabs[0].users.length;

                  } else {

                    this.toastService.createMessage('error', 'Selected resource already exist in list.');

                  }

                }

              }

            }

          }

        }

      }
      this.tabs[0].count = this.tabs[0].users.length;

    }
    if (this.tabs[0].users.length === 0) {

      this.disableClearAll = true;

    } else if (this.tabs[0].groups.length === 0) {

      this.disableClearAll = true;

    }

  }

  containsObject(obj, list, tagName) {

    let i: any;

    let isExists = false;

    if (list.length > 0) {

      for (i = 0; i < list.length; i++) {

        for (const key of list) {

          if (tagName === 'Group') {

            if (key.groupId === obj.groupId && key.groupName === obj.groupName) {

              isExists = true;

            }

          }
          if (tagName === 'Person') {

            if (key.ssoId === obj.ssoId && key.fullName === obj.fullName) {

              isExists = true;

            }

          }
          if (tagName === 'Story') {

            if (key.storyId === obj.storyId && key.storyTitle === obj.storyTitle) {

              isExists = true;

            }

          }
          if (tagName === 'Topics') {

            if (key.topicId === obj.topicId && key.topicName === obj.topicName) {

              isExists = true;

            }

          }
          if (tagName === 'Label') {

            if ((list[i].label === obj.label && list[i].value === obj.value)) {

              isExists = true;

            }

          }

        }

      }

    }
    return isExists;

  }

  enableNotification(_event) {

    if (this.notification === 'Notifications Off') {

      this.notification = 'Notifications On';

    } else {

      this.notification = 'Notifications Off';

    }

  }

  updateColumn() {

    this.isDisable = false;
    if ((this.categoryName !== '' && this.categoryName !== undefined && this.categoryName !== null) &&
      (this.newColumnName !== '' && this.newColumnName !== undefined && this.newColumnName !== null)
    ) {

      if ((this.categoryName === 'Person' && this.tabs[0].users.length > 0) ||
        (this.categoryName === 'Group' && this.tabs[0].users.length > 0) ||
        (this.categoryName === 'Activity' && (this.Stories[0].stories.length > 0
          || this.Labels[0].labels.length > 0 || this.Topics[0].users.length > 0
          || (this.PostType !== undefined && this.PostType !== '' && this.PostType !== null))) ||
        (this.categoryName === 'Story' && (this.Labels[0].labels.length > 0 || this.Topics[0].users.length > 0
          || (this.PostType !== undefined && this.PostType !== '' && this.PostType !== null)))) {

        this.isLoaded = false;
        const ssoid = [];

        const groupids = [];

        const stories = [];

        const labelsList = [];

        const topicslst = [];

        if (this.categoryName === 'Person') {

          this.tabs.forEach(item => {

            if (item.itemName === 'List') {

              item.users.forEach(subelement => {

                ssoid.push(subelement.ssoId);

              });

            }

          });

        } else if (this.categoryName === 'Group') {

          this.tabs.forEach(item => {

            if (item.itemName === 'List') {

              item.users.forEach(subelement => {

                groupids.push(subelement.groupId);

              });

            }

          });

        } else if (this.categoryName === 'Activity') {

          this.Stories.forEach(item => {

            if (item.itemName === 'List') {

              item.stories.forEach(subelement => {

                stories.push(subelement.storyID === undefined ? subelement.storyId : subelement.storyID);

              });

            }

          });
          this.Labels.forEach(item => {

            if (item.itemName === 'Added Labels') {

              item.labels.forEach(subelement => {

                labelsList.push(subelement.value);

              });

            }

          });
          this.Topics.forEach(item => {

            if (item.itemName === 'Added Topics') {

              item.users.forEach(subelement => {

                topicslst.push(subelement.topicId);

              });

            }

          });

        } else if (this.categoryName === 'Story') {

          this.Labels.forEach(item => {

            if (item.itemName === 'Added Labels') {

              item.labels.forEach(subelement => {

                labelsList.push(subelement.value);

              });

            }

          });
          this.Topics.forEach(item => {

            if (item.itemName === 'Added Topics') {

              item.users.forEach(subelement => {

                topicslst.push(subelement.topicId);

              });

            }

          });

        }

        const userId = localStorage.getItem('userId');

        const queryStr = `/${userId}`;

        this.cS.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr).subscribe((res: any) => {

          const response = res;

          let index = -1;

          res.personalizedColumns.forEach((element, indexValue) => {

            if (element.columnNumber === (this.columnNumber + 1)) {

              index = indexValue;

            }

          });
          const dataToSave = {
            categoryName: this.categoryName,
            columnName: this.newColumnName,
            columnNumber: this.columnNumber,
            storyIds: stories,
            postIds: null,
            personIds: ssoid,
            groupIds: groupids,
            enabledNotifications: this.notification === 'Notifications On' ? true : false,
            sortField: null,
            pageType: 'StoryDetails',
            postType: this.PostType === 'Posts' ? 'Update' :
              this.PostType === 'All' ? 'All' :
                this.PostType === 'Elements' ? 'Element' :
                  this.PostType === 'Summaries' ? 'Summary' : '',
            readyStory: this.newColumnName === 'Ready' ? true : false,
            workingStory: this.newColumnName === 'Working' ? true : false,
            labels: labelsList,
            tags: this.Tags,
            topics: topicslst
          };

          response.personalizedColumns[index] = dataToSave;

          // tslint:disable-next-line:no-shadowed-variable
          const queryStr = `/${userId}/userPersonalizedColumn`;

          this.cS.serviceRequestCommon('put', environment.getProfileAPIURL, queryStr, dataToSave).subscribe(() => {

            this.toastService.createMessage('success', 'Customized column successfully updated.');
            this.isLoaded = true;

            // tslint:disable-next-line:only-arrow-functions
            setTimeout(() => {

              this.close.emit();
              this.cS.UpdateDashboard.next(dataToSave);

            }, 2000);

          }, () => {

            this.isLoaded = true;
            this.toastService.createMessage('error', this.Translate.instant('toaster.toastMessageError'));

          }
          );

        }, err => {

          this.isLoaded = true;
          this.toastService.createMessage('error', err);

        });

      } else {

        if (this.categoryName === 'Person') {

          this.toastService.createMessage('error', 'Please select atleast one person to list.');

        }
        if (this.categoryName === 'Group') {

          this.toastService.createMessage('error', 'Please select atleast one group to list.');

        }
        if (this.categoryName === 'Activity' && this.Stories[0].stories.length <= 0
          && this.Labels[0].labels.length <= 0 || this.Topics[0].users.length <= 0
          && (this.PostType === undefined || this.PostType === '' || this.PostType === null)) {

          this.toastService.createMessage('error', 'Please select any one from Post Type, Story, Topic or Label.');

        }
        this.isLoaded = true;

      }

    } else {

      if ((this.newColumnName === '' || this.newColumnName === undefined || this.newColumnName == null)) {

        this.toastService.createMessage('error', 'Please enter column name.');

      } else {

        this.toastService.createMessage('error', 'Please select category name.');

      }
      this.isLoaded = true;

    }

  }

  /* Activity Event Section Start */

  ClearSelectAllStoriesOptions(buttonName, TabName) {

    this.disableClear = true;
    if (buttonName === 'clear-button') {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Stories);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].stories.length;
          while (i--) {

            {

              if (searchInput[count].stories[i].checked === true) {

                searchInput[count].stories.splice(i, 1);

              }

            }

          }

        }

      }
      this.Stories[0].count = this.Stories[0].stories.length;

    } else {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Stories);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          if (TabName === 'Following') {

            i = searchInput[count].stories.length;
            while (i--) {

              {

                if (searchInput[count].stories[i].checked === true) {

                  searchInput[count].stories[i].checked = false;
                  const objectData = Object.assign({}, searchInput[count].stories[i]);

                  const isContains = this.isActivitycontainsObject(objectData, this.Stories[0].stories, 'Story');

                  if (!isContains) {

                    this.Stories[0].stories.push({ ...searchInput[count].stories[i] });
                    this.Stories[0].count = this.Stories[0].stories.length;

                  } else {

                    this.toastService.createMessage('error', 'Selected resource already exist in list.');

                  }

                }

              }

            }

          }

        }

      }
      this.Stories[0].count = this.Stories[0].stories.length;

    }
    if (this.Stories[0].stories.length === 0) {

      this.disableClearAll = true;

    }

  }

  changeStoriesLabelStatus(data, TagName) {

    this.disableClearAll = false;
    this.disableClear = true;
    const isExists = this.isActivitycontainsObject(data, this.Stories[0].stories, TagName);

    if (!isExists) {

      data.checked = false;
      this.Stories[0].stories.push({ ...data });
      this.Stories[0].count = this.Stories[0].stories.length;

    } else {

      this.toastService.createMessage('error', 'Selected Story already exists in list.');

    }
    this.searchInputStory = '';
    (document.getElementById('storyValue') as HTMLInputElement).value = '';

  }

  SelectAllStoriesOptions(TabName) {

    this.disableClear = false;

    // tslint:disable-next-line:only-arrow-functions
    this.Stories.filter((item) => {

      if (item.itemName === TabName) {

        // tslint:disable-next-line:only-arrow-functions
        item.stories.filter((subitem, subindex) => {

          item.stories[subindex].checked = true;

        });

      }

    });

  }

  SelectStoriesCheckbox(TabName, objitem, checked) {

    this.disableClear = false;
    const self = this;

    this.Stories.filter((item, itemindex) => {

      if ((TabName === 'List' && item.itemName === TabName)) {

        self.Stories[itemindex].stories.filter((subitem, subindex) => {

          if ((subitem.storyID || subitem.storyId) === (objitem.storyId || objitem.storyID)) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Stories[itemindex].stories[subindex].checked = !checked;

          }

        });

      }
      if ((TabName === 'Following' && item.itemName === TabName)) {

        self.Stories[itemindex].stories.filter((subitem, subindex) => {

          if ((subitem.storyID || subitem.storyId) === (objitem.storyId || objitem.storyID)) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Stories[itemindex].stories[subindex].checked = !checked;

          }

        });

      }

    });

  }

  ClearSelectAllTopicsOptions(buttonName, TabName) {

    this.disableClearTopic = true;
    if (buttonName === 'clear-button') {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Topics);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].users.length;
          while (i--) {

            {

              if (searchInput[count].users[i].checked === true) {

                searchInput[count].users.splice(i, 1);

              }

            }

          }

        }

      }
      this.Topics[0].count = this.Topics[0].users.length;

    } else {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Topics);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          if (TabName === 'Topics') {

            i = searchInput[count].users.length;
            while (i--) {

              {

                if (searchInput[count].users[i].checked === true) {

                  searchInput[count].users[i].checked = false;
                  const objectData = Object.assign({}, searchInput[count].users[i]);

                  const isContains = this.containsObject(objectData, this.Topics[0].users, 'Topics');

                  if (!isContains) {

                    this.Topics[0].users.push({ ...searchInput[count].users[i] });
                    this.Topics[0].count = this.Topics[0].users.length;

                  } else {

                    this.toastService.createMessage('error', 'Selected resource already exist in list.');

                  }

                }

              }

            }

          }

        }

      }
      this.Topics[0].count = this.Topics[0].users.length;

    }
    if (this.Topics[0].users.length === 0) {

      this.disableClearAllTopic = true;

    }

  }

  changeTopicsLabelStatus(data) {

    this.disableClearAllTopic = false;
    this.disableClearTopic = true;
    const isExists = this.containsObject(data, this.Topics[0].users, 'Topics');

    if (!isExists) {

      data.checked = false;
      this.Topics[0].users.push({ ...data });
      this.Topics[0].count = this.Stories[0].stories.length;

    } else {

      this.toastService.createMessage('error', 'Selected Topic already exists in list.');

    }
    this.searchInputTopic = '';
    (document.getElementById('topicValue') as HTMLInputElement).value = '';

  }

  SelectAllTopicsOptions(TabName) {

    this.disableClearTopic = false;

    // tslint:disable-next-line:only-arrow-functions
    this.Topics.filter((item) => {

      if (item.itemName === TabName) {

        // tslint:disable-next-line:only-arrow-functions
        item.users.filter((subitem, subindex) => {

          item.users[subindex].checked = true;

        });

      }

    });

  }

  SelectTopicsCheckbox(TabName, objitem, checked) {

    this.disableClearTopic = false;
    this.isLoaded = false;
    const self = this;

    this.Topics.filter((item, itemindex) => {

      if ((TabName === 'Added Topics' && item.itemName === TabName)) {

        self.Topics[itemindex].users.filter((subitem, subindex) => {

          if (subitem.topicId === objitem.topicId) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Topics[itemindex].users[subindex].checked = !checked;

          }

        });

      }
      if ((TabName === 'Topics' && item.itemName === TabName)) {

        self.Topics[itemindex].users.filter((subitem, subindex) => {

          if (subitem.topicId === objitem.topicId) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Topics[itemindex].users[subindex].checked = !checked;

          }

        });

      }
      this.isLoaded = true;

    });

  }

  ClearSelectAllLabelsOptions(buttonName, TabName) {

    if (buttonName === 'clear-button') {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Labels);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].labels.length;
          while (i--) {

            {

              if (searchInput[count].labels[i].checked === true) {

                searchInput[count].labels.splice(i, 1);

              }

            }

          }

        }

      }
      this.Labels[0].count = this.Labels[0].labels.length;

    } else {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Labels);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].labels.length;
          while (i--) {

            {

              if (searchInput[count].labels[i].checked === true) {

                searchInput[count].labels[i].checked = false;
                const objectData = Object.assign({}, searchInput[count].labels[i]);

                const isContains = this.containsObject(objectData, this.Labels[0].labels, 'Label');

                if (!isContains) {

                  this.Labels[0].labels.push({ ...searchInput[count].labels[i] });
                  this.Labels[0].count = this.Labels[0].labels.length;

                } else {

                  this.toastService.createMessage('error', 'Selected resource already exist in list.');

                }

              }

            }

          }

        }

      }
      this.Labels[0].count = this.Labels[0].labels.length;

    }

  }

  changeLabelsLabelStatus(data) {

    const isExists = this.isActivitycontainsObject(data, this.Labels[0].labels, 'Labels');

    if (!isExists) {

      data.checked = false;
      this.Labels[0].labels.push({ ...data });
      this.Labels[0].count = this.Labels[0].labels.length;

    } else {

      this.toastService.createMessage('error', 'Selected Label already exists in list.');

    }
    this.searchInput = '';

  }

  SelectAllLabelsOptions(TabName) {

    // tslint:disable-next-line:only-arrow-functions
    this.Labels.filter((item) => {

      if (item.itemName === TabName) {

        // tslint:disable-next-line:only-arrow-functions
        item.labels.filter((subitem, subindex) => {

          item.labels[subindex].checked = true;

        });

      }

    });

  }

  SelectLabelsCheckbox(TabName, objitem, checked) {

    this.isLoaded = false;
    const self = this;

    this.Labels.filter((item, itemindex) => {

      if ((TabName === 'Added Labels' && item.itemName === TabName)) {

        self.Labels[itemindex].labels.filter((subitem, subindex) => {

          if (subitem.value === objitem.value) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Labels[itemindex].labels[subindex].checked = !checked;

          }

        });

      }
      if ((TabName === 'Labels' && item.itemName === TabName)) {

        self.Labels[itemindex].labels.filter((subitem, subindex) => {

          if (subitem.value === objitem.value) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Labels[itemindex].labels[subindex].checked = !checked;

          }

        });

      }
      self.isLoaded = true;

    });

  }

  /* Activity Event Section End */

  AddTags(event) {

    if (event.charCode === 13 && this.Tag.length > 0) {

      this.Tag = this.Tag.toUpperCase();
      if (this.Tags === null) {

        this.Tags = [];

      }
      this.Tags.push(this.Tag);
      this.Tag = '';

    }

  }

  AddTagsonClick() {

    if (this.Tag.length > 0) {

      this.Tag = this.Tag.toUpperCase();
      if (this.Tags === null) {

        this.Tags = [];

      }
      this.Tags.push(this.Tag);
      this.Tag = '';

    }

  }

  removeTag(tag) {

    const index = this.Tags.indexOf(tag);

    if (index > -1) {

      this.Tags.splice(index, 1);

    }

  }

  isActivitycontainsObject(obj, list, TagName) {

    let i;

    for (i = 0; i < list.length; i++) {

      if (TagName === 'Story') {

        if ((list[i].storyId === obj.storyId && list[i].storyTitle === obj.storyTitle)) {

          return true;

        }

      }

    }
    return false;

  }

  deleteColumn() {

    this.isLoaded = false;
    const userId = localStorage.getItem('userId');

    const queryStr = `/${userId}/userPersonalizedColumn/` + this.columnNumber;

    this.cS.serviceRequestCommon('delete', environment.getProfileAPIURL, queryStr).subscribe(() => {

      this.toastService.createMessage('success', 'Column Successfully Deleted.');
      this.cS.UpdateDashboard.next({ action: 'Delete', column: this.columnNumber });
      this.close.emit();

    }, () => {

      this.toastService.createMessage('error', this.Translate.instant('toaster.toastMessageError'));

    }).add(() => {

      this.isLoaded = true;

    });

  }

  closeColumn() {

    this.isDisable = false;
    this.close.emit();

  }

  onChange(value: string, tag: string): void {

    if (tag === 'Story' && typeof (value) === 'object') {

      this.changeStoriesLabelStatus(value, 'Story');

    } else if ((tag === 'Person' || tag === 'Group') && typeof (value) === 'object') {

      this.changeLabelStatus(value, tag);

    } else if (tag === 'Topic' && typeof (value) === 'object') {

      this.changeTopicsLabelStatus(value);

    }

  }

}
