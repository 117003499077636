<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout
   class="story-create"
   *ngIf="!viewPreview">
   <app-standard-header
      title="Back"
      (clickBack)="redirectTo()"></app-standard-header>

   <nz-content
      *ngIf="!isMobile"
      class="storyOffset">
      <div class="create-story-content d-flex">
         <div class="story-split">
            <ng-container [ngTemplateOutlet]="createStoryPostAngleTpl"></ng-container>
            <div
               class="create-story-body"
               #bodyElement>
               <ng-container [ngTemplateOutlet]="createStoryTpl"></ng-container>
            </div>
         </div>
         <div class="story-split">
            <ng-container [ngTemplateOutlet]="metaDataTitleTpl"></ng-container>
            <div
               class="create-story-side"
               #sideMetaElement>
               <ng-container [ngTemplateOutlet]="metaDataTpl"></ng-container>
            </div>
         </div>
      </div>
   </nz-content>
   <nz-content
      *ngIf="isMobile"
      class="storyOffset">
      <div class="story-post-angle-btn">
         <ng-container *ngTemplateOutlet="createStoryPostAngleTpl"></ng-container>
      </div>
      <div class="ant-tab-override ant-tab-border-override create-story-content">
         <nz-tabset class="tabset">
            <nz-tab nzTitle="Main Details">
               <div
                  class="create-story-body"
                  #bodyElement>
                  <ng-container [ngTemplateOutlet]="createStoryTpl"></ng-container>
               </div>
            </nz-tab>
            <nz-tab nzTitle="Metadata">
               <div
                  class="create-story-side"
                  #sideMetaElement>
                  <ng-container [ngTemplateOutlet]="metaDataTpl"></ng-container>
               </div>
            </nz-tab>
         </nz-tabset>
      </div>
   </nz-content>
   <nz-footer class="site-page-footer">
      <button
         class="cancel-btn"
         nz-button
         [nzShape]="windowWidth >= 992 ? 'round' : 'circle'"
         nzType="danger"
         (click)="redirectTo()">
         <i
            nz-icon
            nzType="close"
            nzTheme="outline"></i>
         <span *ngIf="windowWidth >= 992">Cancel</span>
      </button>
      <button
         *ngIf="functionAbility.fa_create_draft"
         class="save-draft-btn"
         nz-button
         [nzShape]="windowWidth >= 992 ? 'round' : 'circle'"
         nzType="default"
         (click)="saveDraft('SaveDraft')">
         <i
            nz-icon
            nzType="save"
            nzTheme="outline"></i>
         <span *ngIf="windowWidth >= 992">Save Draft</span>
      </button>
      <button
         class="preview-btn"
         nz-button
         [nzShape]="windowWidth >= 992 ? 'round' : 'circle'"
         nzType="default"
         (click)="showPreview()">
         <i
            nz-icon
            nzType="expand"
            nzTheme="outline"></i>
         <span *ngIf="windowWidth >= 992">Preview</span>
      </button>
      <button
         nz-button
         nzShape="round"
         nzType="primary"
         id="test"
         (click)="saveDraft('Publish')"
         [disabled]="isPublishDisable">
         <i
            nz-icon
            nzType="send"
            nzTheme="outline"></i>
         Publish
      </button>
   </nz-footer>
   <ng-template #createStoryPostAngleTpl>
      <div
         nz-row
         class="story-post-btn">
         <nz-radio-group
            class="story-btn"
            [(ngModel)]="radioValue"
            [nzButtonStyle]="'solid'"
            (ngModelChange)="toggleStory($event)">
            <label
               nz-radio-button
               nzValue="Story">
               Story
            </label>
            <label
               nz-radio-button
               nzValue="Post"
               [nzDisabled]="!functionAbility.fa_create_post">
               Post
            </label>
            <label
               nz-radio-button
               nzValue="Angle"
               [nzDisabled]="!(functionAbility.fa_create_public_angle || functionAbility.fa_create_private_angle)">
               Angle
               <app-beta [showBInMobile]="true"></app-beta>
            </label>
         </nz-radio-group>
      </div>
   </ng-template>
   <ng-template #createStoryTpl>
      <div class="mb-16 input-group">
         <div
            nz-row
            class="content">
            <div
               nz-col
               class="mb-16 story-grp-btn">
               <nz-radio-group
                  class="mr-16 work-ready"
                  [(ngModel)]="selectedValue"
                  [nzButtonStyle]="'solid'">
                  <label
                     nz-radio-button
                     nzValue="WORKING"
                     *ngIf="functionAbility.fa_change_story_state">
                     Working
                  </label>
                  <label
                     nz-radio-button
                     nzValue="READY"
                     *ngIf="functionAbility.fa_change_story_state"
                     [nzDisabled]="getUserRole() === userRoles.Journalist">
                     Ready
                  </label>
               </nz-radio-group>
               <button
                  nz-button
                  class="storyHeaderBtn"
                  [disabled]="!isNewStory && accessValue === 'public'"
                  [nzDanger]="accessValue === 'private'"
                  [nzType]="accessValue === 'private' ? 'primary' : 'default'"
                  (click)="toggleAccessValue()">
                  <i
                     nz-icon
                     [nzType]="accessValue === 'private' ? 'eye-invisible' : 'eye'"
                     nzTheme="outline"></i>
               </button>
               <nz-select
                  class="storySelectBtn"
                  [(ngModel)]="accessValue"
                  [disabled]="!isNewStory && accessValue === 'public'"
                  style="border-radius: 0">
                  <nz-option
                     nzValue="private"
                     nzLabel="Private"
                     *ngIf="functionAbility.fa_create_private_story"></nz-option>
                  <nz-option
                     nzValue="public"
                     nzLabel="Public"
                     *ngIf="functionAbility.fa_create_public_story"></nz-option>
               </nz-select>
            </div>
            <div
               nz-col
               nzXs="24"
               class="mb-16">
               <div
                  class="example-input storyInput"
                  *ngIf="storyDetails && (storyDetails.storyTitle || !storyDetails.storyTitle)">
                  <input
                     required
                     placeholder="Give your story a title"
                     nz-input
                     [(ngModel)]="storyDetails.storyTitle"
                     (input)="onInput($event.target?.value)"
                     #title="ngModel"
                     #searchStoryTitle />

                  <div
                     *ngIf="title.invalid && (title.dirty || title.touched)"
                     class="alert alert-danger">
                     <div
                        class="errorMessage"
                        *ngIf="title.errors.required">
                        <span>Story Title is required</span>
                     </div>
                  </div>
               </div>
            </div>
            <div
               nz-col
               nzXs="24"
               class="rteOffset">
               <app-rteditor
                  [rteValue]="rteValue"
                  [isEmitted]="isEmitted"
                  [googleDocs]="googleDocs"
                  rteHeight="150"
                  [input]="'storyDesc'"
                  (rteUpdated)="getRTEData($event)"
                  (rtePasteContent)="getRTEPastedData($event)"
                  (gFlag)="checkFlag($event)"></app-rteditor>
            </div>
         </div>
      </div>
   </ng-template>
   <ng-template #metaDataTpl>
      <div class="input-group">
         <div nz-row>
            <div
               nz-col
               nzXs="24"
               class="content mb-16">
               <div class="mb-12 side-tab-sub-header">Topic</div>
               <app-additional-topic-new
                  [selectedValues]="selectedTopicValues"
                  (addedTopics)="attachTopicsToPost($event)"></app-additional-topic-new>
            </div>
            <div
               nz-col
               nzXs="24"
               class="content mb-16">
               <app-tags-new
                  [tagContent]="tagValues"
                  [isGenerateTags]="storyDetails.isGenerateTags"
                  [title]="storyDetails.storyTitle"
                  [description]="rteValue"
                  (tagsUpdated)="getTagsData($event)"
                  (generateFlag)="toggleGenerateFlag($event)"></app-tags-new>
            </div>
            <div
               *ngIf="userInfoDetails.role !== userRoles.Journalist"
               nz-col
               nzXs="24"
               class="content">
               <div class="mb-12 side-tab-sub-header">Slack Integration</div>
               <div class="example-input">
                  <nz-input-group [nzSuffix]="suffixTemplate">
                     <input
                        placeholder="Enter slack channel name here"
                        nz-input
                        [(ngModel)]="slackName"
                        required
                        pattern="[a-zA-Z0-9\s]+"
                        #slackChannelName="ngModel"
                        (keypress)="AddSlackChannelOnEnterClick($event)"
                        #searchGroupTitleText />
                  </nz-input-group>
                  <ng-template #suffixTemplate>
                     <i
                        class="cursor-pointer add-icon"
                        nz-icon
                        nzType="plus-circle"
                        nzTheme="outline"
                        (click)="AddSlackChannelOnBtnClick()"></i>
                  </ng-template>
               </div>
               <ng-container *ngFor="let slack of slackChannelNames; let first = first">
                  <div
                     class="slack-list"
                     [ngClass]="{ 'mt-12': first, 'mt-5': !first }">
                     <span>#{{ slack }}</span>
                     <i
                        class="cursor-pointer"
                        nz-icon
                        nzType="delete"
                        nzTheme="outline"
                        (click)="removeTag(slack)"></i>
                  </div>
               </ng-container>
            </div>
         </div>
      </div>
   </ng-template>
   <ng-template #metaDataTitleTpl>
      <div class="meta-data-title">Metadata</div>
   </ng-template>
</nz-layout>
<nz-layout *ngIf="viewPreview">
   <nz-page-header
      class="site-page-header"
      (nzBack)="goBackToCreate()"
      nzBackIcon>
      <nz-page-header-title class="headerLabel">Back</nz-page-header-title>
      <nz-page-header-extra class="extra-header">
         <!-- <i nz-icon
         nzType="setting"
         (click)="toggleSider()"
         style="font-size: 20px; cursor: pointer;"
         nzTheme="twotone"></i> -->
      </nz-page-header-extra>
   </nz-page-header>
   <nz-layout>
      <nz-content>
         <div class="previewStoryCard">
            <nz-card>
               <app-story-status [status]="selectedValue"></app-story-status>
               <div
                  class="storyTitle"
                  *ngIf="storyDetails && storyDetails.storyTitle">
                  <!-- {{storyDetails.storyTitle}} -->
                  <a href="#">
                     {{ storyDetails.storyTitle }}
                  </a>
               </div>
               <!-- <div class="storySlug" *ngIf="slugValues">
              {{slugValues?.who}}_{{slugValues?.what}}_{{slugValues?.where}}_{{slugValues?.when  | date:'ddMMMyyyy'}}
          </div> -->
               <div
                  class="fr-element fr-view storyDescription"
                  #previewStorycontent>
                  <span [innerHtml]="storyDetails?.storyContent | safeHtml"></span>
               </div>
               <div class="tagsSection">
                  <span *ngIf="tagValues">
                     <i
                        nz-icon
                        nzType="tags"
                        nzTheme="fill"></i>
                     Tags:
                     <span
                        *ngFor="let i of tagValues; last as isLast"
                        class="tagsList">
                        {{ i }}
                        <span *ngIf="!isLast">,</span>
                     </span>
                  </span>
                  <span
                     class="followerIcon"
                     *ngIf="storyDetails.storyId">
                     <app-follow-story
                        type="text"
                        [storyId]="storyDetails.storyId"
                        [isFollowing]="storyDetails.isUserFollowing"
                        [followers]="storyDetails.storyFollowerCount"
                        [showFollowCount]="true"
                        [showFollowStatus]="false"
                        (updatedFollower)="updateFollowers($event)"></app-follow-story>
                  </span>
               </div>
            </nz-card>
         </div>
      </nz-content>
   </nz-layout>
   <nz-footer class="site-page-footer">
      <div class="rightFooter">
         <button
            nz-button
            nzType="primary"
            (click)="saveDraft('Publish')">
            Publish
         </button>
         <button
            nz-button
            nzType="default"
            (click)="redirectToEdit()">
            Cancel
         </button>
      </div>
   </nz-footer>
</nz-layout>

<!-- -- mparillo, I don't think this is being used anymore -->
<!--
<nz-drawer [nzClosable]="false"
           [nzVisible]="!isCollapsed"
           [nzMaskClosable]="true"
           nzPlacement="right"
           [nzWidth]="320"
           (nzOnClose)="isCollapsed = true"
           [nzWrapClassName]="'no-padding'">
  <ng-container *nzDrawerContent>
    <app-story-publish-settings
      [accessValue]="accessValue"
      [selectedValue]="selectedValue"
      (onToggleAccessValue)="accessValue === 'private' ? 'public' : 'private'"
      (onClose)="toggleSider()"
      (changeAccessValue)="accessValue = $event"
      (changeSelectedValue)="selectedValue = $event">
    </app-story-publish-settings>
  </ng-container>
</nz-drawer>
-->

<app-google-docs
   [isVisible]="isVisible"
   (docLinkUpdated)="getDocLink($event)"></app-google-docs>

<!-- -- mparillo, I don't think this is being used anymore -->
<!--
<app-embedly
  [isEmbedlyVisible]="isEmbedlyVisible"
  (embedlyUpdated)="getEmbedlyData($event)"></app-embedly>
-->
