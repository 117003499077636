<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<app-standard-header
   title="Back"
   [defaultBackAction]="true"></app-standard-header>

<div class="container"
     [attr.data-component]="'ncx/settings/settings.component.html'" >
  <!-- <perfect-scrollbar [config]=config -->
      <app-scroll-container footerHeight="0">
        <ng-template bodyTemplate>
          <div class="scroll-container-content">
            <div class="privacy settings-page-wrapper"
                appDebounceClick
                [debounceTime]="700"
                (debounceClick)="saveSettings()">
              <nz-card class="ant-card-body"
                      nzTitle="Privacy">
                <div nz-row>
                  <div *ngFor="let content of settingsDetails.privacy;index as i"
                      class="privacyDetails"
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzXXl="12">
                    <span class="privacyTerms">
                      {{content.name}}
                    </span>
                    <span class="privacyValue">
                      <nz-switch [(ngModel)]="settingResponseData[content.type]"
                                nzSize="small">
                      </nz-switch>
                    </span>
                    
                    <nz-divider class="ant-divider-horizontal-privacy"
                                *ngIf="(i < (settingsDetails.privacy.length-1))"></nz-divider>
                  </div>
                </div>
              </nz-card>
            </div>
            <div appDebounceClick
                [debounceTime]="700"
                (debounceClick)="saveSettings()"
                class="emailAlerts">
              <nz-card class="ant-card-body"
                      nzTitle="Email Alerts">
                <div nz-row>
                  <div *ngFor="let index of settingsDetails.emailAlerts;index as i"
                      class="privacyDetails"
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzXXl="12">
                    <span class="emailTerms">
                      <div>
                        {{index.title}}
                      </div>
                      <div class="alertsSubtitle">
                        {{index.subTitle}}
                      </div>
                    </span>
                    <span class="privacyValue">
                      <nz-switch [(ngModel)]="settingResponseData[index.type]"
                                nzSize="small">
                      </nz-switch>
                    </span>
                    <nz-divider class="ant-divider-horizontal-privacy"
                                *ngIf="formatDivider(i,settingsDetails.emailAlerts.length)"></nz-divider>
                  </div>
                </div>
              </nz-card>
            </div>

            <div appDebounceClick
                [debounceTime]="700"
                (debounceClick)="saveSlackSettings()"
                class="slackAlerts">
              <nz-card class="ant-card-body"
                      nzTitle="Slack Alerts">
                <div nz-row>
                  <div *ngFor="let index of settingsDetails.slackAlerts;index as i"
                      class="slackDetails"
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzXXl="12">
                    <ng-container *ngIf="index.type != 'slackEnabled'">
                      <span class="slackTerms">
                        <div>
                          {{index.title}}
                        </div>
                        <div class="slackSubtitle">
                          {{index.subTitle}}
                        </div>
                      </span>
                      <span class="slackValue">
                        <nz-switch [(ngModel)]="slackSettingResponseData[index.type]"
                                  nzSize="small">
                        </nz-switch>
                      </span>
                      <nz-divider class="ant-divider-horizontal-privacy"
                                  *ngIf="formatDivider(i,settingsDetails.slackAlerts.length)"></nz-divider>
                    </ng-container>
                  </div>
                </div>
              </nz-card>

            </div>
            <div class="privacy settings-page-wrapper"
                appDebounceClick
                [debounceTime]="700"
                (debounceClick)="saveSettings()">
              <nz-card class="ant-card-body"
                      nzTitle="Site View">
                <div nz-row>
                  <div *ngFor="let content of settingsDetails.siteView;index as i"
                      class="privacyDetails"
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzXXl="12">
                    <span class="privacyTerms">
                      {{content.name}}
                    </span>
                    <span class="privacyValue">
                      <nz-switch [(ngModel)]="settingResponseData[content.type]"
                                nzSize="small">
                      </nz-switch>
                    </span>
                    <nz-divider class="ant-divider-horizontal-privacy"
                                *ngIf="(i < (settingsDetails.siteView.length-1))"></nz-divider>
                  </div>
                </div>
              </nz-card>
            </div>

            <div class="privacy settings-page-wrapper"
                appDebounceClick
                [debounceTime]="700"
                (debounceClick)="saveSettings()">
              <nz-card class="ant-card-body"
                      nzTitle="Search Options">
                <div nz-row>
                  <div *ngFor="let content of settingsDetails.searchOptions;index as i"
                      class="privacyDetails"
                      nz-col
                      nzXs="24"
                      nzSm="24"
                      nzMd="24"
                      nzLg="24"
                      nzXl="24"
                      nzXXl="12">
                    <span class="privacyTerms">
                      <div>{{content.name}}</div>
                      <div class="relevantSubTitle">
                        {{content.subTitle}}
                      </div>
                    </span>
                    <span class="privacyValue">
                      <nz-switch [(ngModel)]="settingResponseData[content.type]"
                                nzSize="small">
                      </nz-switch>
                    </span>
                    <nz-divider class="ant-divider-horizontal-privacy"
                                *ngIf="(i < (settingsDetails.searchOptions.length-1))"></nz-divider>
                  </div>
                </div>
              </nz-card>
            </div>

            <div
               class="iosDeviceContainer"
               *ngIf="deviceDetails && deviceDetails.length">
               <nz-card
                  class="ant-card-body"
                  nzTitle="Devices"
                  style="padding: '0px' !important">
                  <nz-card
                     class="ant-card-body"
                     *ngFor="let device of deviceDetails"
                     style="padding: 12px">
                     <div
                        *ngIf="device && device.active"
                        style="padding: 4px">
                        <div
                           nz-col
                           nzXs="4"
                           nzSm="3"
                           nzMd="2"
                           nzLg="1"
                           nzXl="1">
                           <i
                              nz-icon
                              class="phone"
                              nzType="mobile"
                              nzTheme="outline"></i>
                        </div>
                        <div
                           nz-col
                           nzXs="16"
                           nzSm="18"
                           nzMd="19"
                           nzLg="21"
                           nzXl="21">
                           <div>
                              {{ device.deviceName }}
                              <span style="font-size: 6px; margin: 4px 4px; padding: 8px">&#9899;</span>
                              {{ device.deviceLocation ? device.deviceLocation : '' }}
                              <!-- IPhone • XR  • New York, NY United States -->
                           </div>
                           <div>
                              {{ device && device.addedDate ? device.addedDate : '' }}
                              <!-- Added: 00/00/2020 Last Active: 8 hrs -->
                           </div>
                        </div>
                        <div
                           class="iconSection"
                           nz-col
                           nzXs="4"
                           nzSm="3"
                           nzMd="3"
                           nzLg="2"
                           nzXl="2">
                           <i
                              style="padding-right: 20px; color: #1890ff"
                              nz-icon
                              nzType="bell"
                              nzTheme="outline"
                              *ngIf="!device.notificationFlag"
                              (click)="updateDeviceDetails(device, 'notificationFlag', true)"></i>
                           <i
                              style="padding-right: 20px; color: #1890ff"
                              nz-icon
                              nzType="bell"
                              nzTheme="fill"
                              *ngIf="device.notificationFlag"
                              (click)="updateDeviceDetails(device, 'notificationFlag', false)"></i>

                           <i
                              nz-icon
                              nzType="delete"
                              nzTheme="outline"
                              (click)="updateDeviceDetails(device, 'active', false)"></i>
                        </div>
                     </div>
                  </nz-card>
               </nz-card>
            </div>
         </div>
      </ng-template>
   </app-scroll-container>
   <!-- </perfect-scrollbar> -->
</div>
