import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { UserRoles } from '@models/types/user-roles';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { TranslationService } from '@services/translation-service.service';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  isVisible = false;

  roleCheck = false;

  username;

  emailTo;

  private subs = new SubSink();
  userRoles = UserRoles;
  @Input() public isCollapsed = false;

  @Input() public menuList;

  // Must watch for breakpoint changes in order to programatically close the navigation
  // drawer if the user resizes their browser from desktop to mobile
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

    if (event.target.innerWidth <= 992 /* 992 is the max mobile breakpoint*/ &&
      !this.breakpointService.isMobile.value &&
      !this.cService.isNavDrawerClosed.value) {

      this.cService.isNavDrawerClosed.next(true);
    
    }
  
  }

  constructor(
    private tService: TranslationService,
    private userInfo: TransferInformationComponent,
    private Translate: TranslateService,
    private cService: CommonService,
    private breakpointService: BreakpointService
  ) {}

  ngOnInit() {

    this.roleCheck = this.userInfo.userRole?.toUpperCase() === this.userRoles.Admin;
    this.userInfo.currentLinkDetails.subscribe(username => this.username = username);
    this.Translate.use(this.tService.setDefaultLanguage());

    const d = new Date();

    const dFormat = [
      d.getMonth() + 1,
      d.getDate(),
      d.getFullYear()
    ].join('/') + ' ' +
      [
        d.getHours(),
        d.getMinutes(),
        d.getSeconds()
      ].join(':');

    this.emailTo = 'mailto:newsconnecthelp@nbcuni.com?subject= ' + dFormat + ' - ' + this.username + ' - NewsConnect help request';

    //this.config.swipeEasing = false;

    // If NOT in the mobile breakpoint, let's automatically open the navigation drawer
    // if the user has already set the drawer to the open state
    const isCollapsedValue = localStorage.getItem('isCollapsed');

    if (!this.breakpointService.isMobile.value) {

      this.cService.isNavDrawerClosed.next(isCollapsedValue === 'true');
    
    }

    // Must trigger a 'resize' event in order to force some AntDesign elements (e.g. Hot Banner carousel)
    // to repaint themselves whenever the navigation drawer opens/closes
    this.subs.sink = this.cService.isNavDrawerClosed.subscribe((isClosed: boolean) => {

      localStorage.setItem('isCollapsed', String(isClosed));
      setTimeout(() => {

        window.dispatchEvent(new Event('resize'));
      
      }, 250);
    
    });

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Close drawer when link is clicked only on Mobile
   *
   */
  closeDrawer() {

    if (this.breakpointService.isMobile.value) {

      this.cService.isNavDrawerClosed.next(true);
    
    }

  }

  get isLocal(): boolean {

    return !!window.location.href.match(/localhost/);

  }

}
