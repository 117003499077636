import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BreakpointService } from '@services/breakpoint.service';

@Component({
  selector: 'app-post-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss']
})
export class LabelsComponent implements OnInit, OnChanges {

  // All editorial labels
  @Input() editorial: string[];

  // All legal labels
  @Input() legal: string[];

  // If max labels > 0 then show overflow button
  @Input() maxLabelCount: number = 0;

  // Combined list of legal/editorial tags
  labels: string[] = [];

  // Any extra tags to show
  extraLabels: string[] = [];

  // Mapping of the tags to the classname
  labelsCSSClass = {
    REPORTABLE: 'reportable-label',
    VERIFIED: 'verified-label',
    LOG: 'log-label',
    IMPORTANT: 'important-label',
    HOT: 'hot-label',
    STANDARDS: 'standards-label',
    FLAGGED: 'flagged-label',
    LOGISTICS: 'logistics-label',
    BUZZWORTHY: 'buzzworthy-label',
    CLEARED: 'cleared-label',
    LICENSED: 'licensed-label',
    LEGAL: 'legal-label',
    'NOT REPORTABLE': 'not-reportable-label',
    'PUBLISHED/AIRED': 'published-aired-label',
    'GREAT VIDEO': 'great-video-label',
    'LIMITED LICENSE': 'limited-license-label',
    'NEEDS LICENSING': 'needs-licensing-label',
    'COPYRIGHT RISK': 'copyright-risk-label',
    'DO NOT USE': 'do-not-use-label',
    'UNDER REVIEW': 'under-review-label'
  };

  visible: boolean;

  isMobile: boolean = false;

  constructor(private breakpointService: BreakpointService) { }

  ngOnInit() {

    this.setLabels();

    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

  }

  /**
   * Listen for changes to the tags array
   *
   */
  ngOnChanges(change: SimpleChanges) {

    let didChange = false;

    if ('editorial' in change) {

      this.editorial = Array.isArray(change.editorial.currentValue) ? change.editorial.currentValue : [];
      didChange = true;

    }

    if ('legal' in change) {

      this.legal = Array.isArray(change.legal.currentValue) ? change.legal.currentValue : [];
      didChange = true;

    }

    if (didChange) {

      this.setLabels();

    }

  }

  /**
   * Create the final list of tags and overflow (extra) tags to show
   *
   */
  setLabels() {

    const legal = Array.isArray(this.legal) ? this.legal : [];

    const editorial = Array.isArray(this.editorial) ? this.editorial : [];

    // Combine the arrays of the legal and editorial tags into one list, filtering out
    // any non-standard tags and alphabetize the list
    const labels = [...new Set([...legal, ...editorial])].filter((label: string) => {

      return label in this.labelsCSSClass;

    }).sort();

    // This is the final list of tags
    this.labels = labels;

    // ... but if there are any overflow tags, change tags and add extraTags
    if (this.maxLabelCount > 0 && labels.length > this.maxLabelCount) {

      this.labels = labels.slice(0, this.maxLabelCount);
      this.extraLabels = labels.slice(this.maxLabelCount);

    }

  }

  closeExtraTags(): void {
    this.visible = false;
  }

}
