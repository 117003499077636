import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { BreakpointService } from '@services/breakpoint.service';
import { Time } from '@utilities/time';

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss']
})
export class DraftsComponent implements OnInit, AfterViewInit {

  getFilterListAPI: string;

  getDraftAPI: any;

  draftFilterList;

  draftType;

  draftTypeSelected = 'posts';

  draftOrder;

  draftTypeName = 'Posts';

  orderedBy = 'Newest First';

  isCollapsed = true;

  sortByField = 'CREATEDATE';

  draftList: any = [];

  isLoaded: boolean;

  sortOrder = 'DESC';

  filterType = 'ALL';

  pageStartCount = 1;

  pageLength = 10;

  totalCount = 0;

  @ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private breakpointService: BreakpointService,
    private router: Router,
  ) { }

  ngOnInit() {

    this.getFilterListAPI = environment.getPostFiltersURL;
    this.getDraftAPI = environment.draftsApi;

    this.getFilterList();
    this.getDrafts(this.draftTypeSelected, this.sortByField, this.sortOrder);

  }

  ngAfterViewInit() {

    const el = document.querySelector('.draftsSection');

    el && el[0].focus();

  }

  getFilterList() {

    this.cService.serviceRequestCommon('get', this.getFilterListAPI).subscribe((res: any) => {

      this.draftFilterList = res.draftType;
      this.draftType = res.draftType;
      this.draftOrder = res.orderDraft;

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  getDrafts(draftTypeName, sortByField, sortOrder) {

    this.isLoaded = false;

    const queryStr = `/${draftTypeName}/drafts?pageStartCount=${this.pageStartCount}&pageLength=${this.pageLength}&sortByField=${sortByField}&sortOrder=${sortOrder}`;

    this.cService.serviceRequestCommon('get', this.getDraftAPI, queryStr).subscribe((res: any) => {

      if (draftTypeName === 'posts' && res.stories?.length) {

        this.draftList = this.pageStartCount === 1 ? [...res.stories] : [...this.draftList, ...res.stories];

      }

      if (draftTypeName === 'story' && res.stories?.length) {

        this.draftList = this.pageStartCount === 1 ? [...res.stories] : [...this.draftList, ...res.stories];

      }

      if (draftTypeName === 'discussion' && res.groups?.length) {

        this.draftList = this.pageStartCount === 1 ? [...res.groups] : [...this.draftList, ...res.groups];

      }

      this.totalCount = res.totalCount;

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Change draft type
   *
   */
  changeDraftType(value) {

    this.draftList = [];
    this.pageStartCount = 1;
    this.pageLength = 10;
    this.totalCount = 0;

    if (value === 'Posts') {

      this.draftTypeSelected = 'posts';
      this.draftTypeName = 'Posts';

    } else if (value === 'Stories') {

      this.draftTypeSelected = 'story';
      this.draftTypeName = 'Stories';

    } else if (value === 'Discussions') {

      this.draftTypeSelected = 'discussion';
      this.draftTypeName = 'Discussions';

    }

    this.getDrafts(this.draftTypeSelected, this.sortByField, this.sortOrder);

  }

  /**
   * Change draft order
   *
   */
  changeDraftOrder(value) {

    this.orderedBy = value;
    this.draftList = [];
    this.pageStartCount = 1;
    this.pageLength = 10;
    this.totalCount = 0;

    if (value === 'A-Z') {

      this.sortOrder = 'ASC';

      if (this.draftTypeSelected === 'posts') {

        this.sortByField = 'POSTTITLE';

      } else if (this.draftTypeSelected === 'story') {

        this.sortByField = 'STORYTITLE';

      } else if (this.draftTypeSelected === 'discussion') {

        this.sortByField = 'SUBJECT';

      }

    } else if (value === 'Z-A') {

      this.sortOrder = 'DESC';

      if (this.draftTypeSelected === 'posts') {

        this.sortByField = 'POSTTITLE';

      } else if (this.draftTypeSelected === 'story') {

        this.sortByField = 'STORYTITLE';

      } else if (this.draftTypeSelected === 'discussion') {

        this.sortByField = 'SUBJECT';

      }

    } else if (value === 'Newest First') {

      this.sortOrder = 'DESC';
      this.sortByField = 'CREATEDATE';

    } else if (value === 'Oldest First') {

      this.sortByField = 'CREATEDATE';
      this.sortOrder = 'ASC';

    }

    this.getDrafts(this.draftTypeSelected, this.sortByField, this.sortOrder);

  }

  /**
   * Clear all filters
   *
   */
  clearFilter(_event: any) {

    this.sortByField = 'CREATEDATE';
    this.draftTypeSelected = 'posts';
    this.sortOrder = 'DESC';
    this.pageStartCount = 1;
    this.draftList = [];

    this.getDrafts(this.draftTypeSelected, this.sortByField, this.sortOrder);

  }

  toggleSider(): void {

    this.isCollapsed = !this.isCollapsed;

  }

  closeSlider() {

    this.isCollapsed = !this.isCollapsed;

  }

  onScrollDown() {

    // If there are no records to retrieve
    if ((this.pageStartCount + this.pageLength) >= this.totalCount) {

      return;

    }

    this.pageStartCount += this.pageLength;

    this.getDrafts(this.draftTypeSelected, this.sortByField, this.sortOrder);

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  getDraftDetails(draftObj, draftType) {

    let queryStr = '';

    let type = 'postId';

    let Id = '';

    switch (draftType) {

    case 'Posts':
      queryStr = `/post/${draftObj.postId}/version`;
      type = 'postId';
      Id = draftObj.postId;
      break;
    case 'Stories':
      type = 'storyId';
      queryStr = `/story/${draftObj.storyId}`;
      Id = draftObj.storyId;
      break;
    case 'Discussions':
      type = 'discussionId';
      queryStr = `/discussion/${draftObj.discussionId}/version`;
      Id = draftObj.discussionId;
      break;
    case 'default':
      break;

    }
    return { type, Id, queryStr };

  }

  deleteDraftType(draftObj, draftType) {

    const dtaftDetail = this.getDraftDetails(draftObj, draftType);

    this.deleteDraft(dtaftDetail);

  }

  removeDraftFromList(resObj) {

    const dtaftDetail = this.getDraftDetails(resObj, this.draftTypeName);

    let Id;

    switch (dtaftDetail.type) {

    case 'postId' :
      Id = this.draftList.findIndex((draft) => parseFloat((draft.storyPosts[0].postId)) === parseFloat(dtaftDetail.Id));
      break;
    case 'storyId' :
      Id = this.draftList.findIndex((draft) => parseFloat((draft.storyId)) === parseFloat(dtaftDetail.Id));
      break;
    case 'discussionId' :
      Id = this.draftList.findIndex((draft) => parseFloat((draft.groupDiscussions[0].discussionId)) === parseFloat(dtaftDetail.Id));
      break;

    }
    if (Id >= 0) {

      this.draftList.splice(Id, 1);

    }

  }

  deleteDraft(draftObj) {

    this.isLoaded = false;

    this.cService.serviceRequestCommon('delete', this.getDraftAPI, draftObj.queryStr).subscribe(() => {

      this.toastService.createMessage('success', 'Draft successfully deleted.');
      const obj = {};

      obj[draftObj.type] = draftObj.Id;
      this.removeDraftFromList(obj);

    }, () => {

      this.toastService.createMessage('error', 'Error deleting Draft. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  openDiscussion(draft) {
    this.router.navigate(['ncx/discussion-details'], { queryParams: { groupId: draft?.groupDiscussions[0]?.groupId, discussionId: draft?.groupDiscussions[0]?.discussionId } })
  }

}
