import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common-service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TransferInformationComponent } from '../TransferInformation/TransferInformation.component';
import { IFunctionAbility } from '@models/users';
import { ToastService } from '@services/toastService/toastMessage.service';

@Component({
  selector: 'app-additional-topic-new',
  templateUrl: './additional-topic-new.component.html',
  styleUrls: ['./additional-topic-new.component.scss']
})
export class AdditionalTopicNewComponent implements OnInit, OnChanges {

  value: string = '';

  options: string[] = [];

  @Input() postRelatedTopics = [];

  @Input() isPinnedOnTop;

  @Input() viewMode = false;

  // When topics are added
  @Output() addedTopics: EventEmitter<{ relatedTopics: { topicId: string, topicName: string }[] }> = new EventEmitter();

  getStoryTitlesAPI;

  listofStories = [];

  relatedStoryTitles = [];

  filteredOptions: string[] = [];

  filteredOptionsInit: any[];

  subject: Subject<string> = new Subject();

  public functionAbility = {} as IFunctionAbility;

  selectedValue: string;

  @Input() selectedValues;

  listOfOption: string[] = [];

  listOfSelectedValue = [];

  constructor(private cService: CommonService, private tI: TransferInformationComponent, private toasterService: ToastService) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(() => {

      this.getTopicTitlesList();

    });

  }

  ngOnInit() {

    this.getStoryTitlesAPI = environment.getStoryTitles;
    this.functionAbility = { ...this.tI.userFunctionAbility } as IFunctionAbility;
    this.getTopicTitlesList();

    // this.selectedValues = this.selectedValues;

  }

  ngOnChanges(changes: any) {

    if (changes.selectedValues && changes.selectedValues.currentValue) {

      this.selectedValues = changes.selectedValues.currentValue;
      this.getTopicTitlesList();

      const keysList = Object.keys(this.selectedValues);

      this.listOfSelectedValue = [];
      keysList.forEach((item) => {

        const subItem = {
          topicId: item,
          topicName: this.selectedValues[item]
        };

        this.listOfSelectedValue.push(subItem);

      });
      this.listOfOption = this.listOfSelectedValue;

    }

  }

  resetOptions() {

    this.filteredOptions = [];
    this.filteredOptionsInit = [];
    this.listofStories = [];

  }

  TopicSearch(event) {

    const text = event.target.value;

    const queryStr = `?topicName=${encodeURIComponent(text)}`;

    this.cService.serviceRequestCommon('get', environment.getAllTopicsAPIURL, queryStr).pipe(debounceTime(100)).subscribe((res: any) => {

      this.listOfOption = res.topics;

    });

  }

  bindTitleDetails() {

    const updatedRelatedStories = [];

    this.relatedStoryTitles.forEach(story => {

      const temp = this.listofStories.filter(a => a.storyId === story.storyId)[0];

      if (temp === null || temp === undefined) {

        return;

      }
      updatedRelatedStories.push({
        storyId: story.storyId,
        storyTitle: (temp && temp.storyTitle) ? temp.storyTitle : '',
        isPrimary: story.isPrimary,
        isPinnedOnTop: this.isPinnedOnTop
      });

    });
    this.relatedStoryTitles = [...updatedRelatedStories];

    // console.log('ngOnChanges Related Stories1', updatedRelatedStories, this.listofStories);

  }

  getTopicTitlesList() {

    this.cService.serviceRequestCommon('get', environment.getAllTopicsAPIURL).subscribe((data: any) => {

      this.listOfOption = data.topics;
      this.filteredOptions = this.listofStories.map((story) => story.storyTitle); //
      this.filteredOptionsInit = this.listofStories.map((story) => story.storyTitle);

      // this.listOfSelectedValue = this.selectedValues;
      // data.topics.forEach((element) => {
      //   if (this.selectedValues.hasOwnProperty(element.topicId)) {
      //     this.listOfSelectedValue.push(element);
      //   }
      // });

    });

  }

  onInput(value: string): void {

    this.options = value ? [value] : [];

  }

  saveSettings() {

    // console.log('CLICK DEBOUNCE TEST : ' );
  }

  onChange(value: string, event: any): void {

    setTimeout(() => {

      this.subject.next(event.target.value);

    }, 200);

  }

  assignValues(val) {

    if (!val) {

      this.filteredOptions = this.filteredOptionsInit;

    }
    this.filteredOptions = this.filteredOptionsInit.filter((item) => {

      if (item !== null && item !== undefined) {

        const storyTitle = item.toLowerCase();

        // console.log('storyTitle ', value, event);
        if (storyTitle.includes(val.toLowerCase())) {

          return item;

        }

      }

    });

  }

  selectedTopics($event) {

    this.listOfSelectedValue.push({ topicId: $event.topicId.toString(), topicName: $event.topicName });

    const uniqueArray = [];

    this.listOfSelectedValue.forEach((element) => {

      let isExist = false;

      uniqueArray.forEach((subelement) => {

        if (subelement.topicId.toString() === element.topicId.toString() && subelement.topicName === element.topicName) {

          isExist = true;

        }

      });
      if (!isExist) {

        uniqueArray.push(element);

      } else {

        this.toasterService.createMessage('error', 'Selected Topic already exist');

      }

    });
    this.addedTopics.emit({ relatedTopics: uniqueArray });
    this.listOfSelectedValue = uniqueArray;
    this.cService.serviceRequestCommon('get', environment.getAllTopicsAPIURL).subscribe((data: any) => {

      this.listOfOption = data.topics;

    }, () => { });

    setTimeout(() => {

      this.selectedValue = '';

    }, 50);

  }

  isNotSelected(value: any): boolean {

    //  const result = this.listOfSelectedValue.filter((obj) => {
    //   return obj.topicName !== value.topicName;
    // });
    // return result;
    this.listOfSelectedValue.forEach((obj) => {

      if (obj.topicName === value.topicName) {

        return false;

      }

    });

    return true;

  }

  handleClose(removedTopic: any): void {

    this.listOfSelectedValue = this.listOfSelectedValue.filter(tag => tag !== removedTopic);
    this.addedTopics.emit({ relatedTopics: this.listOfSelectedValue });

  }

}
