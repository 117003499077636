import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-linked-stories-view',
  templateUrl: './linked-stories-view.component.html',
  styleUrls: ['./linked-stories-view.component.scss']
})
export class LinkedStoriesViewComponent {

  @Input() relatedStoryTitles: any = [];

  constructor() { }

  redirectTo(storyId) {

    const url = window.location.origin + '/ncx/landing-story/:' + storyId;

    window.open(url, '_self');

  }

}
