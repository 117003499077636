import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  isCollapsed = false;

  icoNewsConnect = 'assets/images/menu_24px.png';

  searchField = false;

  openSearch() {
    this.searchField = true;
    const searchBar = document.getElementById('toolbarItems');

    searchBar.style.width = '80%';
  }

  closeSearch() {
    this.searchField = false;
    const searchBar = document.getElementById('toolbarItems');

    searchBar.style.width = 'auto';
  }
}
