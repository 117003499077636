import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { interval } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-session-banner',
  templateUrl: './session-banner.component.html',
  styleUrls: ['./session-banner.components.scss']
})
export class SessionBannerComponent implements OnInit, OnDestroy {

  // Number of minutes before token expires
  minutesBeforeExpiration: number = -1;

  // Threshold in minutes when warning banner should show
  warningMinutes: number = 10;

  private subs = new SubSink();

  /**
   * Session should be validated only if the user has successfully logged in to the application.
   * If the user is disabled in the Admin screen, then the 'Disabled-user' screen should be shown and the user should not be redirected to the log off screen.
   */

  canCheckSession: boolean = true;

  constructor(
    private authService: AuthService
  ) {

    // Check for session expiring every 60 seconds
    this.subs.sink = interval(60000).subscribe(() => { if (this.canCheckSession) { this.checkSession(); } });

  }

  ngOnInit() {

    //this.checkSession();

    this.authService.checkSession.subscribe(res => {
      this.canCheckSession = res;
    })

    console.log('Session Check Initialized');

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  checkSession() {

    try {

      // Date when the token was issued
      const accessTokenIssueDate = localStorage.getItem('access_token_issuedate');

      // Number of minutes (after token was issued) when it expires
      const accessTokenExpirationMinutes = +(localStorage.getItem('ncx_token_expires_in') || 0);

      // If no issue date or expires time, then immediately log out the user
      if (!accessTokenIssueDate || !accessTokenExpirationMinutes) {

        this.authService.logoutUser();

      }

      // Convert the issue date and minutes to expiration into a new date in the future
      const accessTokenExpiresInSeconds = new Date(accessTokenIssueDate).getTime() + (accessTokenExpirationMinutes * 1000);

      const currentDate = new Date();

      const expiresDate = new Date(accessTokenExpiresInSeconds);

      // Subtract the current date from the future expires date to calculate how many minutes are left before expiration
      const minutesBeforeExpiration = Math.floor((expiresDate.getTime() - currentDate.getTime()) / 1000 / 60);

      // If the token as expired, then logout the user
      if (minutesBeforeExpiration <= 0) {

        this.authService.logoutUser();

      }

      // console.log({ tokenExpiresInMinutes: minutesBeforeExpiration });

      this.minutesBeforeExpiration = minutesBeforeExpiration;

    } catch (error) {

      console.error('Unable to calculate token expiration', error);

    }

  }

  extendSession() {

    localStorage.removeItem('access_token_issuedate');

    this.authService.getOpenIdToken();

  }

}
