export const environment = {
  production: false,
  environmentName: 'devrts',
  baseAPIPath: 'https://devapi.newsconnect.nbcuni.com',
  baseWSPath: 'https://devwss.newsconnect.nbcuni.com',
  mailPath: 'ncx-dev.nbcuni.com',
  slackUrl: 'https://nbc-news-sandbox.enterprise.slack.com/archives/',
  froalaKey: 'Ne2C1sA4F4B3B16B7C8B6kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6C4A1I4C2C3A6C5==',
  ga4TrackingId: 'G-D57KHBWHGJ',
  oAuthClientId: 'NewsConnect',
  oAuthClientSecret: '',
  oAuthUrl: 'https://fss.stg.inbcu.com/fss/as/authorization.oauth2',
  oAuthLogoutUrl: 'https://login.stg.inbcu.com/login/logoff.jsp',
  oAuthTokenUrl: 'https://fss.stg.inbcu.com/fss/as/token.oauth2',
  oAuthRevokeTokenUrl: 'https://fss.stg.inbcu.com/fss/as/revoke_token.oauth2',
  oAuthGrantsUrl: 'https://fss.stg.inbcu.com/fss/as/oauth_access_grants.ping',
  oAuthRedirctUrl: 'http://localhost:4200',
  webSocketUrl: '/ncx-websocket/ws-notification',
  webSocketNotificationUrl: '/ncx-websocket/queue/notification',
  bannerUrl: '/api/v1/gateway/sysAnnouncements',
  alertConfigUrl: '/api/v1/gateway/alert/config',
  announcementUrl: '/api/v1/gateway/sysAnnouncement',
  getUsersOnDutyURL: '/api/v1/gateway/users',
  getProfileAPIURL: '/api/v1/gateway/user',
  getEditorsAPIURL: '/api/v1/gateway/editorOnDuty',
  getSettingsAPIURL: '/api/v1/gateway/user/',
  postProfileAPIURL: '/api/v1/gateway/user/',
  postSettingsAPIURL: '/api/v1/gateway/user/',
  postAvatarAPIURL: '/api/v1/gateway/user/123478/avatar',
  getStoriesAPI: '/api/v1/gateway/story',
  getStoryMetaDataAPI: '/api/v1/gateway/storyMetadata',
  getStoryTitles: '/api/v1/gateway/story/title',
  getStoryDashboardAPI: '/api/v1/gateway/storyDashboard',
  getpersonalizedStoryDashboardAPI: '/api/v1/gateway/personalizedStoryDashboard',
  storyFollower: '/api/v1/gateway/story/follower',
  getPostApi: '/api/v1/gateway/post',
  draftsApi: '/api/v1/gateway',
  getMetaDataAPI: '/api/v1/gateway/metadata',
  getprofileMetaDataAPI: '/api/v1/gateway/profileMetadata',
  getSummaryTemplatesURL: '/api/v1/gateway/summary', // summaryTemplate
  getRepliesURL: '/api/v1/gateway/reply',
  getNotificationURL: '/api/v1/gateway/notification',
  getGroupsURL: '/api/v1/gateway/group',
  getGroupListURL: '/api/v1/groups',
  getDiscussionURL: '/api/v1/gateway/discussion',
  getInpageSearchURL: '/api/v1/gateway/inPage/search',
  getPostFiltersURL: '/api/v1/gateway/postMetadata',
  getActivityURL: '/api/v1/gateway/posts/activity',
  globalSearch: '/api/v1/gateway/contentsearch',
  smartLogicApi: '/api/v1/gateway/smartlogicTag',
  getEditorAPIURL: '/api/v1/gateway/geteditoronduty',
  getExportEditorAPIURL: '/api/v1/gateway/export/editors/editorOnDutyLogs.csv',
  getauditAPIURL: '/api/v1/gateway/admin/audit/getcontentChange',
  getExportauditAPIURL: '/api/v1/gateway/admin/audit/export/contentChange.csv',
  getMarkAsLabelsApi: '/api/v1/gateway/metadata/markAsType',
  getStatusLabelsApi: '/api/v1/gateway/metadata/statusType',
  getUserExportUrl: '/api/v1/gateway/users/exportUserList.csv',
  leadershipAPI: '/api/v1/gateway/leadershipMessage',
  mentionsAPI: '/api/v1/gateway/mentions',
  getHistoryApiUrl: '/api/v1/userfeed',
  getSearchHistoryApiUrl: '/api/v1/gateway/inPage/search',
  getActivitySearchURL: '/api/v1/gateway/inPage/search',
  getTrackSentToMediaCentral: '/api/v1/gateway/post/senttopam/activity',
  sentToMediaCentral: '/api/v1/gateway/notification/senttopam',
  getMediaCentralExportUrl: '/api/v1/gateway/post/pamdashboard/exportpamList',
  getusersAndConnectionsURL: '/api/v1/gateway/usersAndConnections',
  getAllGroupsAPIURL: '/api/v1/gateway/allGroups',
  getMEMBERGroupsURL: '/api/v1/gateway/group',
  getALLGroupsURL: '/api/v1/gateway/groups',
  getMYGroupsURL: '/api/v1/gateway/groups?pageStartCount=1&pageLength=25&filterType=MY GROUPS',
  getALLStories: '/api/v1/gateway/search?searchString=&contentPage=1&postSortField=modificationdate&postSortOrder=descending&storyTitleStartIndex=1&storyTitleEndIndex=10&storySortField=modificationdate&storySortOrder=descending',
  getALLLabels: '/api/v1/gateway/metadata/activityLabels',
  getAllTopicsAPIURL: '/api/v1/gateway/topic/allTopics',
  getFollowTopicsAPIURL: '/api/v1/gateway/topic',
  updateTopicAPIURL: '/api/v1/gateway/topic',
  getAngleApi: '/api/v1/gateway/angle',
  getAngleTitle: '/api/v1/gateway/angle/title',
  getAngleSearch: '/api/v1/gateway/inPage/angleSearch',

  // JWT Token path is different
  getjwtAPIURL: '/api/gateway/v1/jwt/token', // use basePathJWT

  // Paths to assets that are bundled with the application
  mockAPIURL: '/assets/jsons/settingsDetails.json',
  mockProfileAPIURL: '/assets/jsons/mockData.json',
  mockAlertAPIURL: '/assets/jsons/alertList.json',
  getProfileJSONURL: '/assets/jsons/profile-mapping.json',
  getFilterListURL: '/assets/jsons/filterList.json',
  getProfileFollowingJSONURL: '/assets/jsons/following-mapping.json',
  otherToolsData: '/assets/jsons/otherToolsData.json'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
