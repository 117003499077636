import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { UserDetails } from '@models/admin-users';
import { HistoryStory, ProfileHistory } from '@models/ncx/history';
import { Stories, StoryReportableApprover } from '@models/ncx/story';
import { IFunctionAbility } from '@models/users';
import { BreakpointService } from '@services/breakpoint.service';
import { Time } from '@utilities/time';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {

  isLoaded: boolean = true;

  // User info from URL params
  userId: number;

  userSso: number;

  // List of all Story history
  activityDetails: Stories[] = [];

  getFilterListURL: string;

  activityApiUrl: string;

  getApiURL: string;

  searchHistoryApiUrl: string;

  pageLength: number = 10;

  pageStartCount: number = 1;

  searchPostInput: string;

  isSearchVisible = false;

  totalRecords: number;

  profileName = '';

  avatarImgUrl: any;

  @ViewChild(InfiniteScrollDirective, { read: false }) infiniteScroll: InfiniteScrollDirective;

  @ViewChild('searchText', { read: false }) searchText: ElementRef;

  @ViewChild('disContent') testing: ElementRef;

  searchHistorySubject = new Subject();

  functionAbility = {} as IFunctionAbility;

  subscriptionsWs: Subscription = new Subscription();
  storyDataLoaded = false;

  constructor(
    private router: ActivatedRoute,
    private http: HttpClient,
    private cService: CommonService,
    private reRoute: Router,
    private breakpointService: BreakpointService,
    private toastService: ToastService
  ) {

    this.searchHistorySubject.pipe(debounceTime(500)).subscribe(() => {

      this.pageStartCount = 1;
      this.getProfileHistory();

    });

  }

  ngOnInit() {

    this.getFilterListURL = environment.getPostFiltersURL;
    this.activityApiUrl = environment.getHistoryApiUrl;
    this.searchHistoryApiUrl = environment.getSearchHistoryApiUrl;

    this.router.params.subscribe((params: Params) => {

      if (params && params.userId) {

        const id = params.userId;

        const sso = params.ssoId;

        this.userId = id.substr(1);
        this.userSso = sso.substr(1);

      } else {

        this.userId = +localStorage.getItem('userId');

      }

      this.activityApiUrl = `${environment.getHistoryApiUrl}/${this.userId}`;

      // Load Profile info
      this.getProfileDetails();

      // Load Profle History
      this.getProfileHistory();

    });

  }

  ngOnDestroy() {

    this.subscriptionsWs.unsubscribe();

  }

  /**
   * Switch tabs from Profile to History
   *
   */
  switchTab(tab: string) {

    switch (tab) {

      case 'History':
        this.reRoute.navigate(['ncx/history']);
        break;

      case 'Profile':
        this.reRoute.navigate(['ncx/profile/:' + this.userId]);
        break;

    }

  }

  /**
   * Toggle search visibility on Mobile
   *
   */
  toggleSearch() {

    this.isSearchVisible = !this.isSearchVisible;

    if (!this.isSearchVisible) {

      this.clearSearch();

    }

  }

  /**
   * Scroll page to load more records
   *
   */
  onScrollDown() {

    this.pageStartCount += 1;

    this.getProfileHistory();

  }

  /**
   * When search enter button is clicked or text is entered in search bar
   *
   */
  searchHistory(value: string) {

    if (value) {

      this.searchPostInput = value;
      this.searchHistorySubject.next('');

    }
    else{
      this.clearSearch();
    }

  }

  /**
   * Clear search
   *
   */
  clearSearch() {

    this.searchPostInput = '';
    this.pageStartCount = 1;

    this.getProfileHistory();

  }

  /**
   * Get User Profile
   *
   */
  getProfileDetails() {

    this.getApiURL = `${environment.getProfileAPIURL}/${this.userId}`;

    this.cService.serviceRequestCommon('get', this.getApiURL).subscribe((res: any) => {

      const user: UserDetails = res as UserDetails;

      const { name, ssoId, profilePictureURL } = user;

      // Construct name from the value of the Preferred Name, including only the names that are available
      const [lastName, firstName] = String(name.preferredName || '').split(',');

      this.profileName = [firstName, lastName].filter((text: string) => !!text).join(' ');

      this.userSso = ssoId || 0;

      if (profilePictureURL) {

        const token = localStorage.getItem('ncxjwttoken') || '';

        this.avatarImgUrl = profilePictureURL + '?random+\=' + Math.random() + '&ncxjwttoken=' + token;

      }

    }, (error: any) => {

      console.error('Get Profile Details', error);

      this.toastService.createMessage('error', 'Error loading profile. Please try again.');

    }).add(() => {

      this.isLoaded = this.storyDataLoaded;

    });

  }

  /**
   * Get User Profile history (Stories)
   *
   */

  getProfileHistory() {

    this.isLoaded = false;

    const queryStr = `?searchString=${this.searchPostInput}&contentPage=${this.pageStartCount}&authorSSO=${this.userSso}&postSortField=modificationdate&postSortOrder=descending&pageType=UserHistory`;

    if (this.pageStartCount === 1) {

      this.activityDetails = [];

    }

    this.cService.serviceRequestCommon('post', this.searchHistoryApiUrl, queryStr).subscribe((res: any) => {

      const data = (res || {}) as ProfileHistory;

      if (res.errorMessage) {

        this.toastService.createMessage('error', 'Error loading history. Please try again.');
        return;

      }

      this.totalRecords = data.totalCount || 0;

      // Need to convert the information from the API into a valid Story object
      const stories = this.convertToStory(data.stories);

      this.activityDetails = [...this.activityDetails, ...stories];
      this.storyDataLoaded = true;
    }, (error: any) => {

      console.error('Get History', error);

      this.toastService.createMessage('error', 'Error loading history. Please try again.');

    }).add(() => {
      this.isLoaded = this.storyDataLoaded;
    });

  }

  /**
   * Convert history information into a common Story object
   *
   */
  convertToStory(stories: any[]): Stories[] {

    return stories.map((story: HistoryStory) => {

      const post = story.initActivityDto;

      const postCreateDateTime = Time.convertingUtcTimestampToLocal(post.createDateTime);

      const postUpdateDateTime = post.updateDateTime > 0 ? Time.convertingUtcTimestampToLocal(post.updateDateTime) : '';

      return {
        storyId: story.containerId,
        storyTitle: story.containerTitle,
        storyContent: story.initActivityDto.content,
        storyPlaintContent: story.initActivityDto.plainContent,
        standardGuidance: '',
        standardGuidancePlainContent: '',
        isUserFollowing: story.isUserFollowing,
        isGenerateTags: false,
        isDeleted: story.deleted,
        isGroupPost: !post.post,
        ranking: 0,
        repliesCount: story.initActivityDto.repliesCount,
        doNotSendEmail: true,
        storySlug: {
          who: '',
          what: '',
          where: '',
          when: ''
        },
        storyState: story.containerState,
        isStoryPublished: story.containerPublished,
        storyAccess: story.containerAccess,
        storyContentTags: [],
        storyPosts: [
          {
            postId: post.contentId,
            postType: post.contentType,
            storyId: post.contentId,
            snippet: '',
            sentToPam: false,
            postTitle: post.title,
            postContent: post.content,
            postPlainContent: post.plainContent,
            postContentTags: post.contentTags,
            isPostPublished: post.published,
            postStatus: post.contentStatus,
            postMarkedAs: post.markAs,
            editorialStatus: post.editorialStatus,
            postLegal: post.postLegal,
            isPinnedOnTop: post.isPinnedOnTop !== 0,
            relatedStories: [],
            reportableApprover: {} as StoryReportableApprover,
            standardsGuidance: '',
            limitedLicenseApprover: '',
            postAttachments: post.attachments,
            attachmentDetails: {
              linkToSource: '',
              clearanceStatus: '',
              captureDate: '',
              isMandatoryCredit: false,
              creditName: ''
            },
            postReplies: [],
            repliesCount: post.repliesCount,
            postVersionNumber: post.versionNumber,
            postVersionState: post.versionState,
            postVersionGroupId: post.versionGroupId,
            livePostId: 0,
            createDateTime: postCreateDateTime,
            updateDateTime: postUpdateDateTime,
            createUser: post.createUser,
            updateUser: post.updateUser,
            isDeleted: post.deleted,
            locked: '',
            lockedByUser: '',
            plumeGuid: ''
          }
        ],
        storyFollower: [],
        storyUserMentions: [],
        storyFollowerCount: 0,
        storyPostCount: 0,
        storyAttachmentCount: 0,
        createDateTime: '',
        updateDateTime: '',
        createUserId: 0,
        updateUserId: 0,
        pageStartCount: 0,
        pageLength: 0,
        createUser: {},
        updateUser: {},
        topics: {},
        slackChannelName: [],
        isSlackIntegration: false
      } as Stories;

    });

  }

  get canScrollMore(): boolean {

    return (this.pageStartCount + this.pageLength) < this.totalRecords;

  }

  get isMobile() {

    return this.breakpointService.isMobile.value;

  }

}
