import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StorySearchResult } from '@models/ncx/global-search';
import { User } from '@models/users';
import { NavigationService } from '@services/navigation-service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';

@Component({
  selector: 'ncx-story-search',
  templateUrl: './story-search.component.html',
  styleUrls: ['../../cards-common.scss', './story-search.component.scss']
})
export class StorySearchComponent {

  @Input() story: StorySearchResult = {} as StorySearchResult;

  @Output() viewStory: EventEmitter<StorySearchResult> = new EventEmitter<StorySearchResult>();

  public readonly Common = Common;

  constructor(
    private urlRedirectService: UrlRedirectService,
    private navigationService: NavigationService
  ) {}

  /**
   * Return 'true' if a mobile app in order for the link to open up into
   * the same window.  If on a browser, open into a new window
   *
   */
  onViewStory() {

    if (this.navigationService.isIOSMobileApp.value) {

      this.viewStory.emit(this.story);
      return true;
    
    } else {

      this.viewStory.emit(this.story);
      window.open(this.urlRedirectService.getRedirectURL(this.story.storyUrl), '_blank');
      return false;
    
    }

  }

  get date(): string {

    const { updatedDateTime, createDateTime } = this.story;

    const created = new Date(createDateTime);

    const updated = updatedDateTime ? new Date(updatedDateTime) : null;

    if (updated && created < updated) {

      return `updated on ${Time.convertingUtcToLocalDate(updatedDateTime)} at ${Time.convertingUtcToLocalTime(updatedDateTime)}`;
    
    } else {

      return `posted on ${Time.convertingUtcToLocalDate(createDateTime)} at ${Time.convertingUtcToLocalTime(createDateTime)}`;
    
    }

  }

  get authorName(): string {

    return Common.formatName({ displayName: this.story.authorDisplayName } as User);

  }

}
