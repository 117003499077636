<div
   *ngIf="show"
   class="banner">
   <div class="info">
      <i
         nz-icon
         class="icon round"
         nzType="info"
         nzTheme="outline"></i>

      <p>{{ message }}</p>
   </div>

   <button
      (click)="close()"
      class="close">
      <i
         nz-icon
         nzType="close"
         nzTheme="outline"></i>
   </button>
</div>
