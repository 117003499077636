import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { AddAngleComponent } from '@modals/ncx/add-angle/add-angle.component';
import { Post } from '@models/ncx/post';
import { PermissionsService } from '@services/profile/permissions.service';
import { Time } from '@utilities/time';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ncx-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss']
})
export class ElementComponent implements OnChanges, AfterViewInit {

  @Input() post: Post = {} as Post;

  @Input() postDetails: any = {};

  @Input() isStoryLanding = false;

  @Input() postIndex = 0;

  @Input() isAddToAngle: boolean = true;

  @Output() contentShared: EventEmitter<Post> = new EventEmitter<Post>();

  // Permission for Add to Angle
  canAddToAngle = false;

  // Labels to display
  displayLabels = [];

  // Maximum number of labels to show before the extra labels button is shown
  maxLabelCount = 2;

  showShareModal: boolean = false;

  @ViewChild('closeIconTpl', { static: false }) closeIconTpl: any;

  constructor(
    private modalService: NzModalService,
    private permissions: PermissionsService
  ) {}

  ngOnChanges() {

    const { editorialStatus, postLegal } = this.post;

    const editorial = Array.isArray(editorialStatus) ? editorialStatus : [];

    const legal = Array.isArray(postLegal) ? postLegal : [];

    this.displayLabels = editorial.concat(legal);

  }

  /**
   * 'Add To Angle' option should not be available to read only user.
   * Editor and Jounalist can add only the angles where they have rights. Either the Creator of the Angle or as a Collaborator in the angle.
   * Angle filtration is happening in the backend.
   */
  ngAfterViewInit() {

    this.canAddToAngle = !this.permissions.isReadOnlyRole;

  }

  /**
   * Convert datetime to local date/time
   *
   */
  dateDisplay(datetime: string) {

    const date = Time.convertingUtcToLocalDate(datetime);

    const time = Time.convertingUtcToLocalTime(datetime);

    return `${date} at ${time}`;

  }

  /**
   * Add to Angle
   *
   */
  addToAngle(post: Post) {

    this.modalService.create({
      nzContent: AddAngleComponent,
      nzData: {
        postId: post.postId,
        postTitle: post.postTitle
      },
      nzWidth: 605,
      nzBodyStyle: {
        padding: '16px'
      },
      nzCloseIcon: this.closeIconTpl,
      nzFooter: null
    }).afterClose.subscribe(_ => {
    });

  }

  /**
   * Share Post
   *
   */
  onShare() {

    this.showShareModal = true;

    this.contentShared.emit(this.post);

  }

  closeShareModal() {

    this.showShareModal = false;

  }

  get shareMessage(): string {

    return `Check out ${this.post.postTitle}`;

  }

  get isPostupdated(): boolean {

    return this.post.updateDateTime > this.post.createDateTime;

  }

}
