import { Location } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { FollowStoryUpdateEvent } from '@models/ncx/followers';
import { Stories } from '@models/ncx/story';
import { IFunctionAbility } from '@models/users';
import { AuthService } from '@services/auth/auth.service';
import { BannerSessionResizeObserverService } from '@services/banner-session-resize-observer.service';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Observable, Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UserRoles } from '@models/types/user-roles';

@Component({
  selector: 'app-create-story',
  templateUrl: './create-story.component.html',
  styleUrls: ['./create-story.component.scss']
})
export class CreateStoryComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  functionAbility = {} as IFunctionAbility;

  viewPreview = false;

  inputValue: string;

  options: string[] = [];

  selectedValue = 'WORKING';

  isCollapsed = true;

  radioValue = 'Story';

  saveType: any = '';

  storyId = 0;

  slugValues;

  tagValues = [];

  rteValue: any;

  rteValueSG: any;

  storyDetails = {} as Stories;

  originalStoryDetails;

  isNewStory = false;

  isLoaded: boolean;

  savedStoryId = 0;

  googleDocs = '';

  isPost = false;

  getApiURL: any;

  slugDetails: any;

  accessValue: any = 'public';

  isConfirmLoading: boolean;

  isVisible = false;

  embedlyLink: any;

  isEmbedlyVisible: boolean;

  isEmitted: boolean;

  userInfoDetails: any;

  standardGuidancePlainContent: any;

  selectedTopicValues: any;

  slackName = '';

  slackChannelNames: any[];

  slackIntegration = true;

  isPublishDisable = false;

  isMobile = false;

  windowWidth = 992;

  footerHeight = 65;

  resizeObservable$!: Observable<Event>;

  private destroy$ = new Subject();
  userRoles = UserRoles;
  @ViewChild('previewStorycontent') testing: ElementRef;

  @ViewChild('searchStoryTitle') searchStoryTitle: ElementRef;

  @ViewChild('bodyElement', { static: false }) bodyElement: ElementRef | any;

  @ViewChild('sideMetaElement', { static: false }) sideMetaElement: ElementRef | any;

  constructor(
    private tI: TransferInformationComponent,
    private router: ActivatedRoute,
    private cService: CommonService,
    private toastService: ToastService,
    private authService: AuthService,
    private reRoute: Router,
    private location: Location,
    private breakpointService: BreakpointService,
    private bannerSessionResizeObserverService: BannerSessionResizeObserverService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.resizeObservable$ = fromEvent(window, 'resize');

    this.storyDetails.isGenerateTags = false;

    // this.storyDetails.doNotSendEmail = false;
    this.savedStoryId = 0;
    this.isLoaded = false;
    this.getApiURL = environment.getStoriesAPI;
    this.isVisible = false;
    this.isPost = false;
    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });
    this.userInfoDetails = {
      userId: this.tI.userInfoDetails.userId,
      role: this.tI.userInfoDetails.role?.toUpperCase()
    };
    this.router.params.subscribe(
      (params: Params) => {

        this.setStoryId(params);

      });

    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

    this.windowWidth = window.innerWidth;
    this.resizeObservable$.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe(() => {

      this.windowWidth = window.innerWidth;

    });

    this.bannerSessionResizeObserverService.setBannerSessionHeight();

  }

  windowResizeEvent() {

    const bodyElementTab = (this.bodyElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    const bodyElementTabHeight = window.innerHeight - bodyElementTab?.top - this.footerHeight;

    document.documentElement.style.setProperty('--body-tab-height', bodyElementTabHeight + 'px');
    const sideMetaElementTab = (this.sideMetaElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    const sideMetaElementTabHeight = window.innerHeight - sideMetaElementTab?.top - this.footerHeight;

    document.documentElement.style.setProperty('--side-tab-height', sideMetaElementTabHeight + 'px');

  }

  ngAfterViewInit() {

    this.searchStoryTitle.nativeElement.focus();
    this.changeDetector.detectChanges();

  }

  ngAfterViewChecked(): void {

    this.windowResizeEvent();
    this.changeDetector.detectChanges();

  }

  setStoryId(params) {

    if (params && params.storyId) {

      const id = params.storyId;

      this.storyId = id.substr(1);
      this.isNewStory = false;
      this.isEmitted = false;
      this.getStoryDetails(this.storyId);

    } else {

      this.isEmitted = true;
      this.createStoryObject();

    }

  }

  /**
   * Callback when clicking the button to change Access Value (public/private)
   *
   */
  toggleAccessValue() {

    if (this.functionAbility.fa_create_private_story) {

      this.accessValue = this.accessValue === 'private' ? 'public' : 'private';

    }

  }

  onInput(_value: string): void {

    // this.options = value ? [value] : [];
    this.options = [];

  }

  toggleStory(type: any) {

    (type === 'Post') ? this.reRoute.navigate(['ncx/post']) : (type === 'Angle')
      ? this.reRoute.navigate(['ncx/angle'])
      : this.reRoute.navigate(['ncx/create-story']);

  }

  toggleGenerateFlag(type) {

    this.originalStoryDetails.isGenerateTags = type;
    this.storyDetails.isGenerateTags = type;

  }

  validateStorySlug(): boolean {

    if (this.selectedValue === 'READY' || this.selectedValue === 'WORKING') {

      if (this.originalStoryDetails && this.originalStoryDetails.storySlug

        // tslint:disable-next-line:max-line-length
        && ((this.originalStoryDetails.storySlug.who && this.originalStoryDetails.storySlug.who.trim() !== '' &&
          this.originalStoryDetails.storySlug.who.trim() !== null)

          // tslint:disable-next-line:max-line-length
          || (this.originalStoryDetails.storySlug.what && this.originalStoryDetails.storySlug.what.trim() !== '' &&
            this.originalStoryDetails.storySlug.what.trim() !== null)

          // tslint:disable-next-line:max-line-length
          ||
          (this.originalStoryDetails.storySlug.when && this.originalStoryDetails.storySlug.when !== '' && this.originalStoryDetails.storySlug.when !==
            null)

          // tslint:disable-next-line:max-line-length
          || (this.originalStoryDetails.storySlug.where && this.originalStoryDetails.storySlug.where.trim() !== '' &&
            this.originalStoryDetails.storySlug.where.trim() !== null))) {

        return true;

      } else {

        return false;

      }

    } else {

      return true;

    }

  }

  validateStoryDetails(): boolean {

    if (!this.originalStoryDetails.storyTitle || !this.originalStoryDetails.storyTitle.trim()) {

      this.toastService.createMessage('warning', 'Please provide story title to publish');
      return false;

    }
    if (!Common.isInputHasValidTextContent(this.originalStoryDetails.storyContent)) {

      this.toastService.createMessage('warning', 'Please provide story content to publish');
      return false;

    }

    // if (!this.htmlToText(this.originalStoryDetails.storyContent).trim()) {
    //   this.toastService.createMessage('warning', 'Please select Story content to Publish');
    //   return false;
    // }
    // if (this.selectedTopicValues === undefined || this.selectedTopicValues === null) {
    //   this.toastService.createMessage('warning', 'Please Provide the Topic.');
    //   return false;
    // }
    // if (!this.validateStorySlug()) {
    //   this.toastService.createMessage('warning', 'Please Provide the Slug Field');
    //   return false;
    // }
    return true;

  }

  saveDraft(type: any) {

    setTimeout(() => {

      this.isLoaded = false;
      if (this.isVisible) {

        this.isVisible = true;
        this.isLoaded = true;
        return;

      }
      if (this.isEmbedlyVisible) {

        this.isEmbedlyVisible = true;
        this.isLoaded = true;
        return;

      }
      if (type === 'SaveDraft') {

        this.originalStoryDetails.isStoryPublished = false;

      }
      this.saveType = 'Publish';
      if (type === 'Publish') {

        this.originalStoryDetails.isStoryPublished = true;

      }
      this.originalStoryDetails.storyTitle = this.storyDetails.storyTitle;
      this.originalStoryDetails.isGenerateTags = (this.storyDetails.isGenerateTags) ? this.storyDetails.isGenerateTags : false;
      this.originalStoryDetails.storyContentTags = this.tagValues;
      this.originalStoryDetails.storySlug = this.slugValues;
      this.originalStoryDetails.storyContent = (this.rteValue) ? this.rteValue : this.originalStoryDetails.storyContent;

      this.originalStoryDetails.storyState = this.selectedValue;
      this.originalStoryDetails.storyAccess = this.accessValue;
      this.originalStoryDetails.storyFollower = null;
      const isValid = this.validateStoryDetails();

      if (!isValid) {

        this.isLoaded = true;
        return;

      }
      this.originalStoryDetails.storyContent = this.authService.removeJWTTokenFromLink(this.originalStoryDetails.storyContent, 'img');   //JWT Token
      // has
      // to be removed from the img S3 link in the content to be saved
      if (this.isNewStory) {

        this.originalStoryDetails.storyId = 0;
        this.saveStoryById(false);

      } else {

        this.saveStoryById(true);

      }

    }, 500);

  }

  /*API for CRUD operation on Group End */
  AddSlackChannelOnEnterClick(event) {

    if (event.charCode === 13) {

      this.AddSlackChannel();

    }

  }

  AddSlackChannelOnBtnClick() {

    this.AddSlackChannel();

  }

  AddSlackChannel() {

    if (!this.slackChannelNames) {

      this.slackChannelNames = [];

    }
    if (this.slackName.trim().length > 0) {

      this.removeHashInTheFront();
      if (!this.slackChannelNames.includes(this.slackName.trim())) {

        this.slackChannelNames.push(this.slackName.trim());
        this.slackName = '';
        this.slackNameLength();

      } else {

        this.toastService.createMessage('warning', 'Slack channel name already added to story.');

      }

    } else {

      if (this.slackName.length > 0 && this.slackName.trim().length === 0) {

        this.slackName = '';
        this.toastService.createMessage('warning', 'Enter Slack channel name.');

      }

    }

  }

  removeHashInTheFront() {

    const firstChar = this.slackName.substring(0, 1);

    if (firstChar == '#') {

      this.slackName = this.slackName.substring(1, this.slackName.length);
      this.removeHashInTheFront();

    }

  }

  slackNameLength() {

    if (this.slackChannelNames && this.slackChannelNames.length <= 0) {

      this.slackIntegration = true;

    } else {

      this.slackIntegration = false;

    }

  }

  removeTag(tag) {

    const index = this.slackChannelNames.indexOf(tag);

    if (index > -1) {

      this.slackChannelNames.splice(index, 1);
      this.slackNameLength();

    }

  }

  removeS3Link(url) {

    return url.split('?ncxjwttoken')[0];

  }

  showPreview() {

    if (this.isVisible || this.isEmbedlyVisible) {

      return;

    }
    this.viewPreview = true;
    setTimeout(() => {

      if (this.rteValue) {

        this.storyDetails.storyContent = this.rteValue;

      }

    });

  }

  getTagsData(data: any) {

    this.tagValues = data;

  }

  getSlugsData(data: any) {

    this.slugValues = data;

  }

  getRTEData(data: any) {

    this.rteValue = data;
    this.originalStoryDetails.storyContent = data;
    this.googleDocs = '';
    this.embedlyLink = '';

  }

  getRTEPastedData(data: any) {

    this.rteValue = data;
    this.originalStoryDetails.storyContent = data;
    this.googleDocs = '';
    this.embedlyLink = '';

  }

  getRTEDataSG(data: any) {

    this.rteValueSG = data;
    this.originalStoryDetails.standardGuidance = data;

  }

  getRTEPastedDataSG(data: any) {

    this.rteValueSG = data;
    this.originalStoryDetails.standardGuidance = data;

  }

  htmlToText(html) {

    const tmp = document.createElement('DIV');

    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';

  }

  checkFlag(event) {

    if (event === 'true') {

      this.googleDocs = '';
      this.isVisible = true;
      document.getElementById('test').click();

    } else {

      this.isVisible = false;
      this.googleDocs = '';

    }

  }

  checkEmbedlyFlag(event) {

    if (event === 'true') {

      this.embedlyLink = '';
      this.isEmbedlyVisible = true;
      document.getElementById('test').click();

    } else {

      this.isEmbedlyVisible = false;
      this.embedlyLink = '';

    }

  }

  getDocLink(value) {

    if (value !== 'close') {

      this.googleDocs = value;
      this.updateRTELinkContent('googleDoc');

    }
    this.isVisible = false;

  }

  getEmbedlyData(value) {

    if (value !== 'close') {

      this.embedlyLink = value;
      this.updateRTELinkContent('embedlyLink');

    }
    this.isEmbedlyVisible = false;

  }

  updateRTELinkContent(type) {

    if (this.rteValue === '<p><br></p>') {

      // tslint:disable-next-line:max-line-length
      this.rteValue = (type === 'googleDoc')
        ? `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0" src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>`
        : `<p>${this.embedlyLink}<p>`;

    } else {

      // tslint:disable-next-line:max-line-length
      this.rteValue += (type === 'googleDoc')
        ? `<span><a href="${this.googleDocs}">${this.googleDocs}</a><br/><iframe id="googleDoc" height="600" width="800" frameborder="0"scrolling="no" marginheight="0" marginwidth = "0" src = "${this.googleDocs}"></iframe><div id="googleDocError"></div></span>`
        : `<p>${this.embedlyLink}<p>`;

    }

  }

  handleCancel(): void {

    this.isVisible = false;
    this.isLoaded = true;

  }

  assignValues(res) {

    console.log('Assing values', res);
    this.standardGuidancePlainContent = res.standardGuidancePlainContent;
    if ((res && res.storyAccess === 'private') && !(((res.createUser && res.createUser.userId === Number(localStorage.getItem('userId')))
      || (this.functionAbility.fa_access_private_story)))) {

      this.toastService.createMessage('error', 'You Do Not Have Access to the Story');
      setTimeout(() => {

        this.reRoute.navigate(['ncx/stories-dashboard']);

      }, 500);
      return;

    }
    this.storyDetails = res;
    this.originalStoryDetails = JSON.parse(JSON.stringify(res));
    if (res && res.storyContentTags) {

      this.tagValues = res.storyContentTags.map((obj) => {

        return obj;

      });

    }
    this.selectedTopicValues = this.originalStoryDetails.topics;
    this.slugValues = this.storyDetails.storySlug;
    if (this.slugValues === null) {

      this.slugValues = {
        who: '',
        what: '',
        where: '',
        when: ''
      };

    }

    this.storyDetails.storyContent = this.authService.addJWTTokenToLink(this.storyDetails.storyContent, 'img'); //JWT Token has to be added to the
    // img S3
    // link to download the file from S3

    this.rteValue = this.storyDetails.storyContent;
    if (this.storyDetails && this.storyDetails.storyState) {

      this.selectedValue = this.storyDetails.storyState;
      this.accessValue = this.storyDetails.storyAccess;

    }

  }

  createStoryObject() {

    this.storyDetails.storyTitle = null;
    this.storyDetails.storyContent = null;
    this.storyDetails.standardGuidance = null;
    this.slugValues = { who: '', what: '', where: '', when: '' };
    this.storyDetails.storySlug = this.slugValues;
    this.originalStoryDetails = JSON.parse(JSON.stringify(this.storyDetails));

    // this.storyDetails.isSlackIntegration = this.storyDetails.isSlackIntegration;
    this.isNewStory = true;
    this.isLoaded = true;

  }

  goBackToCreate() {

    if (this.viewPreview) {

      this.viewPreview = false;

    } else {

      this.location.back();

    }

  }

  redirectTo() {

    this.location.back();

  }

  redirectToEdit() {

    this.viewPreview = false;

  }

  toggleSider(): void {

    // console.log('toggleSider' , this.isCollapsed);
    this.isCollapsed = !this.isCollapsed;

  }

  updateFollowers(status: FollowStoryUpdateEvent) {

    this.storyDetails.isUserFollowing = status.type;
    this.storyDetails.storyFollowerCount = status.data.storyFollowerCount;

  }

  /* API for CRUD Opeartion on Story Start */
  getStoryDetails(storyId) {

    const queryStr = `/${storyId}`;

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStr).subscribe((res: any) => {

      /**
       * if the story was already deleted then show the msg and redirect to stories dashboard
       */
      if (res.isDeleted === true) {

        this.toastService.createMessage('error', 'Requested story does not exist. Redirecting to stories dashboard');
        this.redirectToStoriesDashboard();

      } else {
        console.log(`SUCCESS: ${this.getApiURL}`);
        this.slackChannelNames = res.slackChannelName;
        this.assignValues(res);
        this.slackNameLength();
        this.isLoaded = true;
      }

    }, err => {

      console.log(`ERROR: ${this.getApiURL}`, err);
      if (err === 'STORY-003') {

        this.toastService.createMessage('error', 'You Do Not Have Access to the Story');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/stories-dashboard']);

        }, 500);

      } else {

        this.toastService.createMessage('error', err);

      }
      this.isLoaded = true;

    });

  }

  /**
   * Redirect to Stories Dashboard
   */
  redirectToStoriesDashboard() {

    setTimeout(() => {

      this.reRoute.navigate(['ncx/stories-dashboard']);

    }, 500);

  }

  saveStoryById(isUpdate: boolean) {

    console.log('saving data to backend ', this.originalStoryDetails, this.originalStoryDetails.storyId);
    if(this.savedStoryId && this.savedStoryId>0){
      this.storyId=this.savedStoryId;
    }
    const queryStr = (isUpdate || this.savedStoryId) ? `/${this.storyId}` : '';

    const type = (isUpdate || this.savedStoryId) ? 'put' : 'post';

    this.originalStoryDetails.slackChannelName = this.slackChannelNames;
    if (this.savedStoryId) {

      this.originalStoryDetails.storyId = this.savedStoryId;

    }
    this.isPublishDisable = true;
    this.cService.serviceRequestCommon(type, this.getApiURL + queryStr, '', this.originalStoryDetails).subscribe((val: any) => {

      /**
       * if the story was already deleted then show the msg and redirect to stories dashboard
       */
      if (val.isDeleted === true) {

        this.toastService.createMessage('error', 'Requested story does not exist. Redirecting to stories dashboard');
        this.redirectToStoriesDashboard();

      } else {

        console.log(`SUCCESS: ${this.getApiURL}`, val);
        this.isLoaded = true;
        this.savedStoryId = val.storyId;
        if (val.isStoryPublished) {

          this.toastService.createMessage('success', 'The Story has been successfully updated');
          this.reRoute.navigate(['ncx/view-story/:' + this.savedStoryId]);

        } else {

          this.toastService.createMessage('success', 'The Draft has been successfully updated');

        }
      }

    },
      (err) => {

        this.isLoaded = true;
        this.isPublishDisable = false;
        this.toastService.createMessage('error', err);

      }
    );

  }

  getUserRole() {

    //  const accessToken = localStorage.getItem('ncxjwttoken');
    //  const decodedAccessToken = this.authService.getDecodedAccessToken(accessToken);
    //  const role = decodedAccessToken.role;
    const role = this.tI.userRole?.toUpperCase();
    return role;

  }

  /* API for CRUD Opeartion on Story End */

  attachTopicsToPost(TopicObj) {

    if (TopicObj.relatedTopics && TopicObj.relatedTopics.length) {

      const item = {};

      TopicObj.relatedTopics.forEach((element) => {

        item[element.topicId.toString()] = element.topicName;

      });
      this.originalStoryDetails.topics = item;
      if (this.selectedTopicValues !== null && this.selectedTopicValues !== undefined) {

        this.selectedTopicValues = item;

      } else {

        this.selectedTopicValues = {};
        this.selectedTopicValues = item;

      }

    } else {

      this.originalStoryDetails.topics = {};
      this.selectedTopicValues = [];

    }

  }

  ngOnDestroy() {

    //this.destroy$.next();
    this.destroy$.complete();
    document.documentElement.style.removeProperty('--body-tab-height');
    this.bannerSessionResizeObserverService.unsetBannerSessionHeight();

  }

}

