<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<div class="tags tags-page-wrapper">
   <div class="title">
      <span class="tags-Title">Tags</span>
      <button
         class="btn-right"
         [ngClass]="{ 'tags-added-btn': tagContent?.length > 0 }"
         nz-button
         (click)="generateTags()"
         *ngIf="!viewMode">
         Generate Tags
      </button>
   </div>
   <div class="tagsDetails">
      <div *ngIf="!viewMode">
         <nz-input-group
            [nzSuffix]="suffixTemplateInfo"
            [nzPrefix]="prefixTemplateUser"
            class="tag-input-group">
            <input
               #inputElement
               nz-input
               type="text"
               placeholder="Add Tag"
               [(ngModel)]="inputValue"
               class="tag-input"
               (blur)="handleInputConfirm()"
               (keydown.enter)="handleInputConfirm()" />
         </nz-input-group>
         <ng-template #prefixTemplateUser>
            <i
               nz-icon
               nzType="tag"></i>
         </ng-template>
         <ng-template #suffixTemplateInfo>
            <i
               class="add-tags-icon"
               nz-tooltip
               nzTitle="Add Tag"
               nz-icon
               nzType="plus-circle"
               nzTheme="outline"
               (click)="showInput()"></i>
         </ng-template>

         <div
            class="show-tags"
            *ngIf="!viewMode">
            <ng-container *ngFor="let tag of tagContent; let i = index">
               <span class="display-tags">
                  <span class="tags-text">{{ sliceTagName(tag) | uppercase }}</span>
                  <span
                     nz-icon
                     nzType="close"
                     nzTheme="outline"
                     (click)="handleClose(tag)"
                     class="tags-close"></span>
               </span>
            </ng-container>
         </div>

         <span class="generate-tags-auto">
            <label
               class="generate-tags"
               nz-checkbox
               [(ngModel)]="isGenerateTags"
               (ngModelChange)="toggleGenerateFlag($event)">
               Generate Tags Automatically
            </label>
         </span>
      </div>
   </div>

   <div
      *ngIf="viewMode"
      class="viewTags">
      <ng-Container *ngFor="let item of tagContent">
         <nz-form-label class="tagLabel">{{ item }}</nz-form-label>
      </ng-Container>
   </div>
</div>
