import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { environment } from '@environments/environment';
import { User } from '@models/users';
import { UrlRedirectService } from '@services/url-redirect.service';

@Component({
  selector: 'app-profile-overlay',
  templateUrl: './profile-overlay.component.html',
  styleUrls: ['./profile-overlay.component.scss']
})
export class ProfileOverlayComponent implements OnInit, OnChanges {

  @Input() profileData: User = {} as User;

  @Input() screenType;

  @Output() emitFollow = new EventEmitter();

  @Output() destroyParentModal = new EventEmitter();

  followerApi: string;

  followerUserIDs: any[];

  isFollowing = false;

  isLoading = false;

  isVisible = false;

  loginUser = localStorage.getItem('userId');

  isLoggedInUser = false;
  constructor(
    private reRoute: Router,
    private cService: CommonService,
    private toastService: ToastService,
    private urlRedirectService:UrlRedirectService
  ) { }

  ngOnInit() {

    this.followerApi = environment.getProfileAPIURL;

  }

  getUrl(url)
  {
  return this.urlRedirectService.getRedirectAPIURL(url);
  }

  ngOnChanges() {

    console.log('profileData ngOnChanges : ', this.profileData);

    this.followerApi = environment.getProfileAPIURL;

    this.isFollowing = false;

    if (this.profileData && this.profileData.userId) {

      this.getFollowerList(this.profileData.userId);
      this.getProfileData(this.profileData.userId);

    } else {

      this.isLoading = false;
      this.isVisible = true;

    }

    if (this.profileData && this.profileData.userId && (this.loginUser.toString() === this.profileData.userId.toString())) {

      this.isLoggedInUser = true;

    }
    if (this.profileData && this.profileData.profilePictureURL && !this.profileData.profilePictureURL.includes('?ncxjwttoken=')) {

      this.profileData.profilePictureURL = this.profileData.profilePictureURL + `?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`;

    }

  }

  viewProfile(userId) {

    this.destroyParentModal.emit();
    this.reRoute.navigate(['ncx/profile/:' + userId]);

  }

  getFollowerList(userId) {

    this.isLoading = true;
    this.followerUserIDs = [];
    const queryStr = `/${userId}/userFollower`;

    this.cService.serviceRequestCommon('get', this.followerApi, queryStr).subscribe((res: any) => {

      this.followerUserIDs = [...res.followerUserIDs];
      this.isFollower();

    }, () => {

      this.toastService.createMessage('error', 'Error in Loading Data, Please try again later');

    }).add(() => {

      this.isLoading = false;

    });

  }

  getProfileData(userId) {

    this.isLoading = true;
    this.cService.serviceRequestCommon('get', this.followerApi, '/' + userId).subscribe((res: any) => {

      this.profileData = res;
      if (res.name.preferredName.split(',').length > 1) {

        this.profileData.firstName = res.name.preferredName.split(',')[1].trim();
        this.profileData.lastName = res.name.preferredName.split(',')[0].trim();

      } else if (res.name.preferredName.split(',').length == 1) {

        this.profileData.firstName = res.name.preferredName.trim();
        this.profileData.lastName = '';

      }
      this.profileData.jobTitle = res.workInfo.jobTitle;
      if (this.profileData && this.profileData.profilePictureURL && !this.profileData.profilePictureURL.includes('?ncxjwttoken=')) {

        this.profileData.profilePictureURL = this.profileData.profilePictureURL + `?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`;

      }

    }, () => {

      this.toastService.createMessage('error', 'Error in Loading Data, Please try again later');

    }).add(() => {

      this.isLoading = false;

    });

  }

  isFollower() {

    const userFollower = localStorage.getItem('userId');

    const userObj = this.followerUserIDs.filter(user => user.toString() === userFollower.toString())[0];

    this.isFollowing = (userObj !== null && userObj !== undefined);
    this.isVisible = true;

  }

  followUser(targetUserId, type) {

    if (this.screenType === 'connections') {

      this.emitFollow.emit(type);
      return;

    }
    const followerUserId = localStorage.getItem('userId');

    if (targetUserId.toString() === followerUserId.toString()) {

      this.toastService.createMessage('error', 'Please select Different User to Follow.');
      return;

    }
    const queryStr = `/${targetUserId}/userFollower/${followerUserId}`;

    console.log('followUser : ', targetUserId, followerUserId, type);

    this.isLoading = true;

    this.cService.serviceRequestCommon(type, this.followerApi, queryStr).subscribe(data => {

      console.log('Follow Response', data);
      if (type === 'post') {

        this.toastService.createMessage('success', 'You are now following the User');
        this.isFollowing = true;

      } else {

        this.toastService.createMessage('success', 'You have Unfollowed the User');
        this.isFollowing = false;

      }

    }, () => {

      this.toastService.createMessage('error', 'Error saving data. Please try again');

    }).add(() => {

      this.isLoading = false;

    });

  }

}
