import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {

  radioValue = 'following';

  name = 'Topics';

  followerApi;

  isFollowing = false;

  connectionsApi;

  followersUsers;

  followingUsers;

  connections;

  users;

  isLoaded;

  screenType;

  currentSelectedTab: any = 'following';

  followingUserIds: any = [];

  followerUserIds: any = [];

  constructor(
    private cService: CommonService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {

    this.screenType = 'connections';
    this.followerApi = environment.getProfileAPIURL;

    this.connectionsApi = environment.getAllTopicsAPIURL;
    console.log('connectionsApi', this.connectionsApi);
    this.getTopicsDetails();

  }

  getTopicsDetails() {

    this.isLoaded = false;
    this.cService.serviceRequestCommon('get', this.connectionsApi).subscribe((res: any) => {

      if (res && res.followingUsers && res.followingUsers.length) {

        this.followingUsers = [...res.followingUsers];

      } else {

        this.followingUsers = [];

      }
      if (res && res.followerUsers && res.followerUsers.length) {

        this.followersUsers = [...res.followerUsers];

      } else {

        this.followersUsers = [];

      }

      // this.followersUsers = [...res.followerUsers];
      this.radioValue = this.currentSelectedTab;
      if (this.radioValue === 'following') {

        this.users = this.followingUsers;

      } else {

        this.users = this.followersUsers;

      }
      this.users = res.topics;
      this.isLoaded = true;

    }, () => {

      this.isLoaded = true;
      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  checkFollowing(item) {

    let check = false;

    if (item && item.length) {

      const userId = localStorage.getItem('userId');

      item.forEach(element => {

        if (element.toString() === userId) {

          check = true;

        }

      });

    }
    if (check) {

      return true;

    } else {

      return false;

    }

  }

  followUser(index, targetTopicId, type) {

    let response;

    const userId = localStorage.getItem('userId');

    const queryStr = `/${targetTopicId}`;

    if (type === 'follow') {

      response = {
        topicName: index.topicName,
        updateUserId: userId,
        fields: [
          {
            fieldName: 'topicFollowers',
            fieldValues: [userId],
            fieldToBeRemoved: false
          }
        ]
      };

    }
    if (type === 'unfollow') {

      response = {
        topicName: index.topicName,
        updateUserId: userId,
        fields: [
          {
            fieldName: 'topicFollowers',
            fieldValues: [userId],
            fieldToBeRemoved: true
          }
        ]
      };

    }

    this.cService.serviceRequestCommon('put', environment.updateTopicAPIURL, queryStr, response).subscribe(data => {

      console.log('Follow Response', data);
      if (type === 'follow') {

        this.toastService.createMessage('success', 'You have followed the Topic');

      }
      if (type === 'unfollow') {

        this.toastService.createMessage('success', 'You have unfollowed the Topic');

      }
      this.getTopicsDetails();

      // this.isFollowing = false;

    }, () => {

      this.toastService.createMessage('error', 'Error saving data. Please try again');

    });

  }

}
