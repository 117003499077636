import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Group } from '@models/ncx/groups-discussions';
import { StoriesLanding } from '@models/ncx/stories-landing';
import { Stories } from '@models/ncx/story';
import { UserRoles } from '@models/types/user-roles';
import { IFunctionAbility, PERMISSIONS } from '@models/users';
import { PermissionsService } from '@services/profile/permissions.service';
import { UtilityService } from '@services/utility.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';
import { TransferInformationComponent } from 'src/app/shared/components/TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-toggle-section',
  templateUrl: './toggle-section.component.html',
  styleUrls: ['./toggle-section.component.scss']
})
export class ToggleSectionComponent implements OnInit, OnChanges {

  @Input() isCollapsed = true;

  @Input() isStoryLanding = false;

  @Input() isFilter = false;

  @Input() isDrawer = false;

  @Input() isStoryFollowing = false;

  @Input() isViewPost = false;

  @Input() isAdminOrOwner;

  @Input() storyDetails = null as Stories;

  @Input() postDetails;

  @Input() storyFollowingData = null;

  @Input() storyLandingDetails = null as StoriesLanding;

  @Input() groupDetails: Group;

  @Input() gdCollapsed;

  @Input() ddCollapsed;

  @Input() glCollapsed;

  @Input() groupId;

  @Input() discussionId;

  @Output() isFollowingingCollapsed = new EventEmitter();

  @Output() public isEdit = new EventEmitter();

  @Output() public isDelete = new EventEmitter();

  @Output() isStoriesLandingCollapsed = new EventEmitter();

  @Output() labelupdatedStatus = new EventEmitter();

  @Output() postupdatedStatus = new EventEmitter();

  @Output() creationDateUpdatedSelected = new EventEmitter();

  @Output() dateupdatedStatus = new EventEmitter();

  public statusType = '';

  @Output() closeViewStory: EventEmitter<any> = new EventEmitter<any>();

  @Output() filterApplied: EventEmitter<any> = new EventEmitter<any>();

  @Output() showMergeStory: EventEmitter<any> = new EventEmitter<any>();

  @Output() openinfoCenter: EventEmitter<any> = new EventEmitter<any>();

  @Output() gdCloseSider: EventEmitter<any> = new EventEmitter<any>();

  @Output() ddCloseSider: EventEmitter<any> = new EventEmitter<any>();

  @Output() glCloseSider: EventEmitter<any> = new EventEmitter<any>();

  @Output() gotoManageMembers: EventEmitter<any> = new EventEmitter<any>();

  @Output() movePostDiscussion: EventEmitter<any> = new EventEmitter<any>();

  @Output() openGroupVersions: EventEmitter<any> = new EventEmitter<any>();

  @Output() downloadDiscPDF: EventEmitter<any> = new EventEmitter<any>();

  @Output() exportMembersCSV: EventEmitter<any> = new EventEmitter<any>();

  @Output() editDiscussion: EventEmitter<any> = new EventEmitter<any>();

  @Output() filtersApplied: EventEmitter<any> = new EventEmitter<any>();

  @Output() public unlockthePost = new EventEmitter();

  @Output() discussionlocked: EventEmitter<any> = new EventEmitter<any>();

  public functionAbility = {} as IFunctionAbility;

  hideJobTitle = false;

  glradioValue = 'ALL GROUPS';

  @Input() viewProfile;

  public isVisible = false;

  radioValue = '';

  accessValue = '';

  addOnBeforeIcon = 'eye';

  isLoaded = true;

  deleteFlag = false;

  filterList: any = [];

  getFilterListURL: string;

  radioPostOptions: any = [];

  checkOptionsLabel: any = [];

  radioCreationDateOptions: any = [];

  radioPostTypeValue = 'All';

  radioLabelsValue = 'Important';

  radioCreationDateValue = 'Creation Date';

  postTypeSelected: any;

  labelStatus: any = [];

  dateTypeSelected: any;

  labelSelected: any;

  jsonUrl: string;

  originalJSONData: any;

  originalAPIData: any;

  profileList: any = [];

  userSettings;

  domainName;

  emailTo;

  emailGroupTo;

  viewStoryEmailTo;

  public userInfoDetails;

  loggedInUserId: number;

  discussionDetails: any;

  topics = '';

  getSlackUrl: any;

  isSlackBlockedSubscription = false;

  getApiURL: any;

  isAdmin: boolean = false;
  userRoles = UserRoles;
  public readonly Common = Common;

  constructor(
    private modal: NzModalService,
    private router: Router,
    private tI: TransferInformationComponent,
    private cService: CommonService,
    private toastService: ToastService,
    private uS: UtilityService,
    private permissions: PermissionsService
  ) {

    this.getFilterListURL = environment.getPostFiltersURL;
    this.jsonUrl = environment.getProfileFollowingJSONURL;

    // tslint:disable-next-line:radix
    this.loggedInUserId = parseInt(localStorage.getItem('userId'));
    this.getSlackUrl = environment.slackUrl;
    this.getApiURL = environment.getStoriesAPI;

  }

  ngOnInit() {

    if (this.isStoryLanding) {

      this.getFilter();

    }

    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });

    this.isAdmin = !!this.tI.userRole?.match(/admin/i);

    this.domainName = this.getDomainName();

    if (this.discussionId) {

      this.getDiscussionDetails();

    }

  }

  getDomainName() {

    return this.uS.getMailURL();

  }

  blockFromSlack() {

    if (this.storyDetails) {

      this.storyDetails.isSlackBlockedSubscription = !this.isSlackBlockedSubscription;
      const queryStr = `/update/${this.storyDetails.storyId}`;

      const payload = {
        storyUpdate: [
          {
            key: 'isSlackBlockedSubscription',
            value: !this.isSlackBlockedSubscription
          }
        ]
      };

      this.cService.serviceRequestCommon('put', this.getApiURL + queryStr, '', payload).subscribe((val: any) => {

        console.log(`SUCCESS: ${this.getApiURL}`, val);
        this.isLoaded = true;
        if (val.isStoryPublished) {

          this.toastService.createMessage('success', 'The Story has Been Successfully Updated');

        } else {

          this.toastService.createMessage('success', 'The Draft has Been Successfully Updated');

        }

      },
        (err) => {

          this.isLoaded = true;
          this.toastService.createMessage('error', err);

        }
      );

    }

  }

  getOrigin() {

    return window.location.origin;

  }

  ngOnChanges() {

    this.domainName = this.getDomainName();

    this.userInfoDetails = {
      userId: this.tI.userInfoDetails.userId,
      role: this.tI.userInfoDetails.role
    };

    if (this.storyFollowingData && this.isCollapsed) {

      this.getProfileMappingList();

    }

    this.isSlackBlockedSubscription = this.storyDetails?.isSlackBlockedSubscription;

    if (this.storyDetails && this.storyDetails.storyState) {

      if (this.storyDetails && this.storyDetails.topics) {

        this.topics = Object.keys(this.storyDetails.topics).length ? Object.values(this.storyDetails.topics).join(', ') : '';

      }
      this.radioValue = this.storyDetails.storyState;
      this.accessValue = this.storyDetails.storyAccess;
      const slug = this.storyDetails.storySlug;

      if (slug === null || slug === undefined) {

        const slugDetails = {
          who: '',
          what: '',
          where: '',
          when: ''
        };

        this.storyDetails.storySlug = slugDetails;

      }

    }
    if (!this.isStoryLanding) {

      if (this.storyDetails && this.storyDetails.storyState && this.domainName) {

        this.radioValue = this.storyDetails.storyState;
        this.accessValue = this.storyDetails.storyAccess;

        // tslint:disable-next-line:max-line-length
        this.viewStoryEmailTo = 'mailto:story+' + (this.storyDetails.storyId).toString().trim() + '@mail.' +
          this.domainName.toString().trim();

      }

    }

    if (this.groupDetails !== undefined) {

      if (this.groupDetails && this.groupDetails.groupId) {

        this.emailGroupTo = 'mailto:group+' + (this.groupDetails.groupId).toString().trim() + '@mail.' + this.domainName.toString().trim();

      }

    }
    if (this.storyLandingDetails && this.storyLandingDetails.storyId && this.domainName) {

      this.emailTo = 'mailto:story+' + (this.storyLandingDetails.storyId).toString().trim() + '@mail.' + this.domainName.toString().trim();

    }

  }

  warning(): void {

    this.deleteFlag = true;
    this.modal.warning({
      nzTitle: 'Do you want to delete this story? ',
      nzContent: 'The story and all associated posts will be removed.',
      nzClosable: true,
      nzMaskClosable: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {

        this.deleteFlag = false;
        this.isDelete.emit('true');

      },
      nzOnCancel: () => {

        this.deleteFlag = false;

      }
    });

  }

  showConfirm(): void {

    const queryStr = `/${this.discussionId}`;

    this.cService.serviceRequestCommon('get', environment.getDiscussionURL, queryStr).subscribe((response: any) => {

      if (response.locked && response.lockedByUser.userId !== this.loggedInUserId) {

        this.discussionDetails = response;
        this.discussionlocked.emit(response);

      } else {

        this.modal.confirm({
          nzTitle: 'Are you sure you want to delete this Discussion?',
          nzOkText: 'Delete',

          // nzOkType: 'danger',
          nzOkDanger: true,
          nzCancelText: 'Cancel',
          nzOnOk: () => {

            this.deleteFlag = false;
            this.isDelete.emit('true');

            // console.log('Click ok');

          },
          nzOnCancel: () => {

            // console.log('Closed');
            this.deleteFlag = false;

          }
        });

      }

    });

  }

  redirect(): void {

    this.isEdit.emit(true); // editEvent

  }

  redirectTo() {

    const queryStr = `/${this.discussionId}`;

    this.cService.serviceRequestCommon('get', environment.getDiscussionURL, queryStr).subscribe((response: any) => {

      if (response.locked && response.lockedByUser.userId !== this.loggedInUserId) {

        this.discussionDetails = response;
        this.discussionlocked.emit(response);

      } else {

        this.editDiscussion.emit(true);

      }

    });

  }

  exportMembers() {

    this.exportMembersCSV.emit(true);

  }

  close() {

    this.isStoriesLandingCollapsed.emit(true);
    this.tI.getStoriesSider(true);
    const statusType: any = this.statusType;

    switch (statusType) {

      case 'type':
        this.statusType = statusType;
        this.postupdatedStatus.emit(this.postTypeSelected);
        break;
      case 'labels':
        this.statusType = statusType;
        this.labelupdatedStatus.emit(this.postTypeSelected);
        break;
      case 'date':
        this.statusType = statusType;
        this.dateupdatedStatus.emit(this.postTypeSelected);
        break;

    }

  }

  getFilter() {

    this.cService.serviceRequestCommon('get', this.getFilterListURL).subscribe((res: any) => {

      this.filterList = res;
      this.radioPostOptions = this.filterList.postType;
      this.checkOptionsLabel = this.filterList.filterList;
      this.radioCreationDateOptions = this.filterList.orderDate;
      this.checkOptionsLabel.forEach(element => {

        if (element.checked) {

          this.changeLabelStatus(true, element, 'labels');

        }

      });

    }, () => {

      this.toastService.createMessage('error', 'Error while loading please try again');

    });

  }

  closeSider() {

    this.closeViewStory.emit(true);

  }

  groupDetailsCloseSider() {

    this.gdCloseSider.emit(true);

  }

  discussionDetailsCloseSider() {

    this.ddCloseSider.emit(true);

  }

  groupLandingCloseSider() {

    this.glCloseSider.emit(true);

  }

  clear() {

    this.radioPostOptions = [];
    this.checkOptionsLabel = [];
    this.radioCreationDateOptions = [];
    this.radioPostTypeValue = 'All';
    this.radioLabelsValue = 'Important';
    this.radioCreationDateValue = 'Creation Date';
    this.labelStatus = [];
    this.postTypeSelected = null;
    this.getFilter();
    const filterObj = {
      labels: '',
      postType: '',
      date: ''
    };

    this.filterApplied.emit(filterObj);

  }

  addFilters(event, data, type) {

    switch (type) {

      case 'type':
        this.postTypeSelected = (data && data.value) ? data.value : null;
        break;
      case 'labels':
        this.changeLabelStatus(event, data, type);
        break;
      case 'date':
        this.dateTypeSelected = (data && data.value) ? data.value : null;
        break;

    }
    const filterObj = {
      labels: this.labelSelected,
      postType: this.postTypeSelected,
      date: this.dateTypeSelected
    };

    this.filterApplied.emit(filterObj);

  }

  changePostStatus(data, type) {

    // console.log('changePostStatus 1: ', data);
    this.postTypeSelected = (data && data.value) ? data.value : null;
    this.statusType = type;

  }

  changeLabelStatus(event, data?, type?) {

    // console.log('changeLabelStatus 1 : ', event, data, type);
    const obj = {
      order: data
    };

    if (event === true) {

      // Pushing the object into array
      this.labelStatus.push(obj);

    } else {

      const removeIndex = this.labelStatus.findIndex(itm => itm.order === data);

      if (removeIndex !== -1) {

        this.labelStatus.splice(removeIndex, 1);

      }

    }
    this.statusType = type;
    if (!(this.labelStatus && this.labelStatus.length)) {

      this.labelSelected = null;

    } else {

      const temp = this.labelStatus.map(a => a.order.value);

      let labels = '';

      temp.forEach((element, index) => {

        labels += (index !== (temp.length - 1)) ? element + ',' : element;

      });
      this.labelSelected = labels;

    }

  }

  mergeStory() {

    this.showMergeStory.emit(true);

  }

  openInfocenterResearch() {

    this.openinfoCenter.emit(true);

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  showWorkInfo(value) {

    // console.log('ViewProfile', this.viewProfile);
    if (this.viewProfile) {

      if (value === 'JobTitle' && this.userSettings && !this.userSettings.showJobTitle) {

        this.hideJobTitle = true;
        return false;

      } else if (value === 'Division' && this.userSettings && !this.userSettings.showDivision) {

        return false;

      } else if ((value === 'Building' || value === 'City' || value === 'Zip' || value === 'Country'
        || value === 'Address' || value === 'State') && this.userSettings && !this.userSettings.showWorkLocation) {

        return false;

      }

    }
    return true;

  }

  showContactDetails(value) {

    if (this.viewProfile) {

      if (value === 'Work' && !this.userSettings.showWorkPhone) {

        return false;

      } else if (value === 'Mobile' && !this.userSettings.showMobilePhone) {

        return false;

      }

    }
    return true;

  }

  getProfileMappingList() {

    // const api1 = this.profileService.getSettingsDetails(this.jsonUrl, false);
    this.isLoaded = false;
    this.cService.serviceRequestCommon('get', this.jsonUrl)

      // tslint:disable-next-line:max-line-length
      // const api2 = this.profileService.getSettingsDetails(this.mockProfileUrl, false); // this.mockProfileUrl for local testing , for
      // this.getApiURL for API const api2 = this.storyFollowingData; forkJoin([api1])
      .subscribe((res) => {

        this.originalJSONData = JSON.parse(JSON.stringify(res));
        this.originalAPIData = this.storyFollowingData;
        this.userSettings = this.originalAPIData.userSettings;
        this.mapValues(res, this.storyFollowingData);

      },
        (error) => {

          this.toastService.createMessage('error', error);

        }).add(() => {

          this.isLoaded = true;

        });

  }

  mapValues(JsonData: any, apiData: any) {

    // this.validateForm = this.fb.group(apiData);
    if (!apiData) {

      return;

    }

    // test
    if (apiData.length === 0) {

      return;

    }
    const email = JsonData.data.email.map(val => {

      val.value = apiData.email[val.type];
      return val;

    });

    const phone = JsonData.data.phone.map(val => {

      val.value = apiData.phone[val.type];
      return val;

    });

    const workinfo = JsonData.data.workInfo.map(val => {

      val.value = apiData.workInfo[val.type];
      return val;

    });

    this.profileList.email = email;
    this.profileList.phone = phone;
    this.profileList.workinfo = workinfo;

  }

  toggle(followingData) {

    this.router.navigate(['ncx/profile/:' + followingData.userId]);

  }

  manageMembers() {

    this.gotoManageMembers.emit(true);

  }

  moveDiscussionPost() {

    const queryStr = `/${this.discussionId}`;

    this.cService.serviceRequestCommon('get', environment.getDiscussionURL, queryStr).subscribe((response: any) => {

      if (response.locked && response.lockedByUser.userId !== this.loggedInUserId) {

        this.discussionDetails = response;
        this.discussionlocked.emit(response);

      } else {

        this.movePostDiscussion.emit(true);

      }

    });

  }

  goToGroupVersions() {

    const queryStr = `/${this.discussionId}`;

    this.cService.serviceRequestCommon('get', environment.getDiscussionURL, queryStr).subscribe((response: any) => {

      if (response.locked && response.lockedByUser.userId !== this.loggedInUserId) {

        this.discussionDetails = response;
        this.discussionlocked.emit(response);

      } else {

        this.openGroupVersions.emit();

      }

    });

  }

  downloadDiscussionPDF() {

    this.downloadDiscPDF.emit(true);

  }

  unlockingPost() {

    this.unlockthePost.emit('true');

  }

  /*Group Landing Filters */
  sortByGroup(type) {

    const value = type;

    let label;

    switch (value) {

      case 'ALL GROUPS':
        label = 'All Groups';
        break;
      case 'MY GROUPS':
        label = 'My Groups';
        break;
      case 'FOLLOWING':
        label = 'Following';
        break;
      case 'deafault':
        label = '';
        break;

    }
    this.filtersApplied.emit({ value, label });

  }

  getDiscussionDetails() {

    const queryStr = `/${this.discussionId}`;

    this.cService.serviceRequestCommon('get', environment.getDiscussionURL, queryStr).subscribe((response) => {

      this.discussionDetails = response;

    });

  }

  get canEditStory(): boolean {

    return this.permissions.hasPermissionTo(PERMISSIONS.EDIT_STORY, { createUser: this.storyDetails?.createUser });

  }

  get canEditGroup(): boolean {

    return this.permissions.hasPermissionTo(PERMISSIONS.EDIT_GROUP, { createUser: this.groupDetails?.createUser });

  }

}
