<app-loader-component [isLoaded]="isLoaded"></app-loader-component>
<nz-layout [attr.data-component]="'ncx/drafts/drafts.component.html'">
   <nz-page-header
      class="site-page-header"
      nzBackIcon>
      <nz-page-header-title>
         <button
            *ngIf="isMobile"
            nz-button
            (click)="toggleSider()">
            <i
               nz-icon
               nzType="filter"
               nzTheme="outline"></i>
         </button>
         <nz-button-group *ngIf="!isMobile">
            <button
               class="headerButtons"
               nz-button>
               Draft Type
            </button>
            <button
               nz-button
               nz-dropdown
               [nzDropdownMenu]="menu1">
               {{ draftTypeName }}
               <i
                  nz-icon
                  nzType="down"></i>
            </button>
            <nz-dropdown-menu #menu1="nzDropdownMenu">
               <ul nz-menu>
                  <li
                     *ngFor="let index of draftType"
                     (click)="changeDraftType(index.label)"
                     nz-menu-item>
                     {{ index.label }}
                  </li>
               </ul>
            </nz-dropdown-menu>
         </nz-button-group>
         <nz-button-group *ngIf="!isMobile">
            <button
               class="headerButtons"
               nz-button>
               <i
                  nz-icon
                  nzType="sort-ascending"
                  nzTheme="outline"></i>
               Order
            </button>
            <button
               nz-button
               nz-dropdown
               [nzDropdownMenu]="menu">
               {{ orderedBy }}
               <i
                  nz-icon
                  nzType="down"></i>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
               <ul nz-menu>
                  <li
                     nz-menu-item
                     (click)="changeDraftOrder(index.label)"
                     *ngFor="let index of draftOrder">
                     {{ index.label }}
                  </li>
               </ul>
            </nz-dropdown-menu>
         </nz-button-group>
      </nz-page-header-title>
   </nz-page-header>

   <nz-layout
      class="draftsLayout"
      infiniteScroll
      [infiniteScrollDistance]="2"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false">
      <ng-container
         *ngIf="(!draftList || !draftList.length) && isLoaded"
         [ngTemplateOutlet]="noResults"></ng-container>

      <div
         class="draftsSection"
         *ngIf="draftList && draftList.length">
         <ng-container *ngIf="draftTypeSelected === 'posts' && draftList && draftList.length">
            <nz-card
               class="card"
               *ngFor="let draft of draftList">
               <div class="head">Post</div>
               <div class="draftTitle">
                  <a href="/ncx/view-post/:{{ draft?.storyPosts[0]?.postId }}">
                     {{ draft?.storyPosts[0]?.postTitle }}
                  </a>
               </div>
               <div>
                  <ng-container *ngIf="draft?.storyPosts[0]?.relatedStories.length; else angleLink">
                     <a
                        href="/ncx/view-story/:{{ draft?.storyId }}"
                        class="draftSubtitle">
                        {{ draft?.storyTitle }}
                     </a>
                  </ng-container>
                  <ng-template #angleLink>
                     <a
                        href="/ncx/landing-angle/:{{ draft?.storyId }}"
                        class="draftSubtitle">
                        {{ draft?.storyTitle }}
                     </a>
                  </ng-template>
               </div>
               <div class="footerFields">
                  <span class="dateTime">
                     {{ utcToLocal(draft?.storyPosts[0]?.updateDateTime, 'DATE') | date: 'M/d/yy' }}
                     {{ utcToLocal(draft?.storyPosts[0]?.updateDateTime, 'TIME') }}
                  </span>
                  <span class="deleteIcon">
                     <i
                        nz-icon
                        nzType="delete"
                        nzTheme="outline"
                        (click)="deleteDraftType(draft?.storyPosts[0], draftTypeName)"></i>
                  </span>
               </div>
            </nz-card>
         </ng-container>

         <ng-container *ngIf="draftTypeSelected === 'story' && draftList && draftList.length">
            <nz-card
               class="card"
               *ngFor="let draft of draftList">
               <div class="head">Story</div>
               <div class="draftTitle">
                  <a href="/ncx/view-story/:{{ draft.storyId }}">
                     {{ draft.storyTitle }}
                  </a>
               </div>
               <!-- <div class="draftSubtitle">
            {{draft.storyPlaintContent}}
          </div> -->
               <div class="footerFields">
                  <span class="dateTime">
                     {{ utcToLocal(draft.updateDateTime, 'DATE') | date: 'M/d/yy' }}
                     {{ utcToLocal(draft.updateDateTime, 'TIME') }}
                  </span>
                  <span class="deleteIcon">
                     <i
                        nz-icon
                        nzType="delete"
                        nzTheme="outline"
                        (click)="deleteDraftType(draft, draftTypeName)"></i>
                  </span>
               </div>
            </nz-card>
         </ng-container>

         <ng-container *ngIf="draftTypeSelected === 'discussion' && draftList && draftList.length">
            <nz-card
               class="card"
               *ngFor="let draft of draftList">
               <div class="head">Discussion</div>
               <div class="draftTitle">
                  <a
                     (click)="openDiscussion(draft)">
                     {{ draft?.groupDiscussions[0]?.discussionSubject }}
                  </a>
               </div>
               <div>
                  <a
                     href="/ncx/group-details/:{{ draft?.groupId }}"
                     class="draftSubtitle">
                     {{ draft?.groupName }}
                  </a>
               </div>
               <div class="footerFields">
                  <span class="dateTime">
                     {{ utcToLocal(draft?.groupDiscussions[0]?.updateDateTime, 'DATE') | date: 'M/d/yy' }}
                     {{ utcToLocal(draft?.groupDiscussions[0]?.updateDateTime, 'TIME') }}
                  </span>
                  <span class="deleteIcon">
                     <i
                        nz-icon
                        nzType="delete"
                        nzTheme="outline"
                        (click)="deleteDraftType(draft?.groupDiscussions[0], draftTypeName)"></i>
                  </span>
               </div>
            </nz-card>
         </ng-container>
      </div>

      <nz-drawer
         *ngIf="isMobile"
         [nzClosable]="false"
         [nzVisible]="!isCollapsed"
         [nzWrapClassName]="'no-padding'"
         nzPlacement="right"
         [nzWidth]="320"
         (nzOnClose)="closeSlider()">
         <app-drafts-filter
            *nzDrawerContent
            [isDrawer]="true"
            [draftType]="draftType"
            [orderedBy]="draftOrder"
            (closeFilter)="closeSlider()"
            (emitDraftTypeFilter)="changeDraftType($event)"
            (emitOrderFilter)="changeDraftOrder($event)"
            (clearFilter)="clearFilter($event)"></app-drafts-filter>
      </nz-drawer>
   </nz-layout>

   <!-- NO RESULTS -->
   <ng-template #noResults>
      <div class="notFoundCard">
         <nz-empty [nzNotFoundContent]="contentTpl">
            <ng-template #contentTpl>
               <span>No Results Found</span>
            </ng-template>
         </nz-empty>
      </div>
   </ng-template>
</nz-layout>
