import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Angle } from '@models/ncx/angle';
import { IFunctionAbility } from '@models/users';
import { CSSThemeService } from '@services/css-theme.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { environment } from 'src/environments/environment';
import { TransferInformationComponent } from '../TransferInformation/TransferInformation.component';

@Component({
  selector: 'app-linked-angles',
  templateUrl: './linked-angles.component.html',
  styleUrls: ['./linked-angles.component.scss']
})
export class LinkedAnglesComponent implements OnInit, OnChanges {

  value = '';

  angleValue = '';

  emptyAngles: Angle[] = [{ angleTitle: 'No Angles found' } as Angle];

  angleOptions: string[] = [];

  @Input() postRelatedAngles = [];

  @Input() angleUpdateFromAPI: EventEmitter<any[]>;

  @Output() addedAngles = new EventEmitter();

  @Input() highlightMandatory: boolean = true;

  listofAngles: Angle[] = [];

  relatedAngles = [];

  filteredAngleOptions: Angle[] = [];

  options: string[] = [];

  isLightMode: boolean = false;

  subject: Subject<string> = new Subject();

  public functionAbility = {} as IFunctionAbility;

  constructor(
    private cService: CommonService,
    private tI: TransferInformationComponent,
    private themeService: CSSThemeService
  ) {

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      this.getAngles(searchTextValue);

    });

  }

  ngOnInit() {

    this.functionAbility = { ...this.tI.userFunctionAbility } as IFunctionAbility;

  }

  ngAfterViewChecked() {

    this.isLightMode = this.themeService.currentTheme !== 'dark';

  }

  ngOnChanges(changes: SimpleChanges) {

    if ('postRelatedAngles' in changes) {

      const newValue = changes.postRelatedAngles.currentValue || [];

      this.postRelatedAngles = newValue;
      this.availableAngles();

    }

  }

  availableAngles() {

    if (this.postRelatedAngles && this.postRelatedAngles.length) {

      this.relatedAngles = JSON.parse(JSON.stringify(this.postRelatedAngles));
      this.relatedAngles = this.relatedAngles.map((angle) => {

        return {
          angleId: angle.angleId,
          angleTitle: (angle && angle.angleTitle) ? angle.angleTitle : '',
          angleIdTitle: '(' + angle.angleId + ') ' + angle.angleTitle,
          relatedStories: angle.relatedStories,
          angleAccess: angle.angleAccess
        };

      });

    } else {

      this.relatedAngles = [];

    }

  }

  resetOptions() {

    this.listofAngles = [];
    this.filteredAngleOptions = [];
    this.listofAngles = [];

  }

  getAngles(queryStr?) {

    let params;

    if (queryStr) {

      params = `?searchText=${queryStr}`;

    } else {

      this.filteredAngleOptions = [];
      return;

    }
    this.cService.serviceRequestCommon('get', environment.getAngleTitle, params).subscribe(
      (data: any) => {

        const apiAngles = data.map((angle) => {

          return {
            angleId: angle.angleId,
            angleTitle: angle.angleTitle,
            angleIdTitle: '(' + angle.angleId + ') ' + angle.angleTitle,
            relatedStories: angle.relatedStories,
            angleAccess: angle.angleAccess
          };

        });

        const angleMatchingTitles: Angle[] = JSON.parse(JSON.stringify(apiAngles));

        this.listofAngles = angleMatchingTitles.filter(angle =>
          this.relatedAngles.every(assignedAngle => angle.angleId !== assignedAngle.angleId));

        this.filteredAngleOptions = data.length ? this.listofAngles : this.emptyAngles;

      },
      (error: any) => {

        console.log(error);

      });

  }

  angleMatch(angleValue) {

    const obj = this.listofAngles.filter(angle => angle.angleId === angleValue)[0];

    if (!(obj === null || obj === undefined)) {

      this.relatedAngles.push(obj);
      this.mapAdditionalAnglesToPost();
      setTimeout(() => {

        this.angleValue = '';

      }, 100);

    }

  }

  searchAngle(value: string, event: any): void {

    setTimeout(() => {

      this.subject.next(event.target.value);

    }, 200);

  }

  mapAdditionalAnglesToPost() {

    const relatedAngles = this.relatedAngles.filter(({ angleId, angleTitle }, index, a) =>
      a.findIndex(e => angleId === e.angleId &&
        angleTitle === e.angleTitle) === index);

    this.addedAngles.emit({ postRelatedAngles: relatedAngles });
    this.resetOptions();

  }

  deleteRelatedAngles(angleId) {

    this.relatedAngles.forEach((element, itemindex) => {

      if (angleId === element.angleId) {

        this.relatedAngles.splice(itemindex, 1);

      }

    });
    this.addedAngles.emit({ postRelatedAngles: this.relatedAngles });
    this.resetOptions();

  }

}
