<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout
   class="view-story"
   [attr.data-component]="'ncx/view-story/view-story.component.html'">
   <!-- HEADER -->
   <app-standard-header
      [defaultBackAction]="true"
      title="Back">
      <ng-template header-right>
         <button
            (click)="toggleSider()"
            class="toggle-icon">
            <i
               nz-icon
               nzType="setting"
               nzTheme="outline"></i>
         </button>
      </ng-template>
   </app-standard-header>

   <nz-layout>
      <nz-content *ngIf="storyDetails">
         <div class="story-card-container">
            <div
               *ngIf="!storyDetails.isStoryPublished"
               class="draftMessage">
               <i
                  nz-icon
                  nzType="check-circle"
                  nzTheme="fill"></i>
               {{ draftMessage }}
            </div>

            <!-- STORY INFO -->
            <div class="story-card">
               <!-- STORY STATUS -->
               <app-story-status
                  *ngIf="storyDetails.storyState"
                  [status]="storyDetails.storyState"></app-story-status>

               <!-- STORY TOPICS -->
               <div
                  class="storyTopics"
                  *ngIf="topics">
                  Topics:
                  <span>{{ topics }}</span>
               </div>

               <!-- STORY TITLE -->
               <div
                  class="storyTitle"
                  *ngIf="storyDetails.storyTitle">
                  <a
                     href="/ncx/view-story/:{{ storyId }}"
                     target="_blank"
                     [innerHTML]="storyDetails.storyTitle | safeHtml"></a>
               </div>

               <!-- Story Description. Profile view on mouse over. If the description has the format @username then the profile will be shown -->
               <span
                  class="custom-popover"
                  id="triggerClick"
                  nzPopoverTrigger="click"
                  nz-popover
                  [nzPopoverContent]="profileCardTemplate"
                  [nzPopoverPlacement]="'topLeft'"></span>
               <ng-template
                  cdkConnectedOverlay
                  #profileCardTemplate>
                  <app-profile-overlay [profileData]="profileInDescription"></app-profile-overlay>
               </ng-template>

               <div
                  class="storyDescription fr-element fr-view"
                  #storyDetailsContent
                  id="storyContent">
                  <span [innerHtml]="storyDetails?.storyContent | safeHtml"></span>
               </div>

               <!-- STORY CREATED BY -->
               <div class="creationDetails">
                  <span class="creator">
                     Created By:
                     <button
                        *ngIf="storyDetails.createUser && storyDetails.createUser.firstName"
                        class="creators">
                        <span
                           nz-popover
                           [nzPopoverContent]="profileCardTemplate"
                           [nzPopoverPlacement]="'bottomLeft'">
                           {{ storyDetails.createUser.displayName.trim() }}
                        </span>

                        <ng-template #profileCardTemplate>
                           <app-profile-overlay [profileData]="storyDetails.createUser"></app-profile-overlay>
                        </ng-template>
                     </button>
                  </span>

                  <span
                     class="time"
                     *ngIf="storyDetails.createDateTime">
                     <i
                        nz-icon
                        nzType="clock-circle"
                        nzTheme="fill"></i>
                     {{ utcToLocal(storyDetails.createDateTime, 'TIME') }}
                  </span>

                  <span
                     class="date"
                     *ngIf="storyDetails.createDateTime">
                     <i
                        nz-icon
                        nzType="calendar"
                        nzTheme="fill"></i>
                     {{ utcToLocal(storyDetails.createDateTime, 'DATE') }}
                  </span>
               </div>

               <!-- STORY TAGS -->
               <div
                  class="storyTags"
                  *ngIf="storyDetails.storyContentTags">
                  <i
                     nz-icon
                     nzType="tags"
                     nzTheme="fill"></i>
                  Tags:
                  <span>{{ tags }}</span>
               </div>

               <!-- FOLLOWER COUNT -->
               <span class="follower">
                  <app-follow-story
                     type="text"
                     [storyId]="storyDetails.storyId"
                     [isFollowing]="storyDetails.isUserFollowing"
                     [followers]="storyDetails.storyFollowerCount"
                     [showFollowCount]="true"
                     [showFollowStatus]="false"
                     (updatedFollower)="updateFollower($event)"></app-follow-story>
               </span>
            </div>
         </div>
      </nz-content>

      <nz-sider
         *ngIf="!isMobile"
         [nzCollapsible]="true"
         [nzCollapsedWidth]="0"
         [nzWidth]="295"
         [nzCollapsed]="isCollapsed"
         [nzTrigger]="null">
         <app-toggle-section
            (isEdit)="isEdit($event)"
            [isDrawer]="true"
            [storyDetails]="storyDetails"
            (isDelete)="deleteStory($event)"
            (closeViewStory)="toggleSider()"
            (showMergeStory)="viewMergeStory()"
            (openinfoCenter)="openInfoCenter()"></app-toggle-section>
      </nz-sider>
   </nz-layout>
</nz-layout>

<nz-drawer
   *ngIf="isMobile"
   nzPlacement="right"
   [nzClosable]="false"
   [nzWrapClassName]="'no-padding'"
   [nzVisible]="!isCollapsed"
   [nzWidth]="320"
   (nzOnClose)="closeSlider()">
   <ng-container *nzDrawerContent>
      <app-toggle-section
         [isCollapsed]="isCollapsed"
         [isDrawer]="true"
         (isEdit)="isEdit($event)"
         [storyDetails]="storyDetails"
         (isDelete)="deleteStory($event)"
         (closeViewStory)="toggleSider()"
         (showMergeStory)="viewMergeStory()"
         (openinfoCenter)="openInfoCenter()"></app-toggle-section>
   </ng-container>
</nz-drawer>

<app-merge-stories
   [isMergeStoryVisible]="isMergeStoryVisible"
   [sourceStoryId]="storyId"
   (closeMergeStory)="closeMergeStory()"></app-merge-stories>

<app-infocenter-research
   [isinfocenterVisible]="isinfocenterVisible"
   [storyTitle]="storyTitle"
   [Id]="storyId"
   (closeinfoCenter)="closeInfoCenter()"></app-infocenter-research>
